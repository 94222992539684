import React, {useEffect, useState} from 'react';
import { CustomModal } from '../../../Components';
import Scrollbars from 'react-custom-scrollbars';
import { Button, Form } from 'react-bootstrap';


const FilterRefferels = ({
    allCheckedValues,
    setallCheckedValues,
    onClose,
    setSubTabsType
})=>{
    const AllActive =
    allCheckedValues.includes('builders') &&
    allCheckedValues.includes('sessions') &&
    allCheckedValues.includes('opportunities') &&
    allCheckedValues.includes('events') &&
    // allCheckedValues.includes('questions') &&
    allCheckedValues.includes('snapshots') &&
    allCheckedValues.includes('appointment') &&
    allCheckedValues.includes('group_chat');


    const doAllCheckedValues = e => {
        const {checked} = e.target;
        if (checked) {
          var aa = [
            'events',
            'sessions',
            'builders',
            // 'questions',
            'opportunities',
            'snapshots',
            'appointment',
            'group_chat',
          ];
          aa.forEach(tab => {
            setallCheckedValues(current => [...current, tab]);
          });
        } else {
          setallCheckedValues([]);
        }
      };


    const handleClick = e => {
        const {id, checked} = e.target;
        setallCheckedValues([...allCheckedValues, id]);
        if (!checked) {
          setallCheckedValues(allCheckedValues.filter(item => item !== id));
        }
      };


    return (
        <>
            <CustomModal  className={'sessioneventFilter'} style={{height: '100%'}} onClose={() => onClose()}
            paddingBottomZero={true}>
              <div className="serach-filter-wrapper">
                <div className="filter-headings">
                  <h2
                    className="text-center">
                    Filter By Category
                  </h2>
                  {/* <p>What type of Referals are you looking for?</p> */}
                </div>
                <Scrollbars autoHide>
                <div className="pb-2">
                  <div className="filter-category-list">
                    <ul>
                      <li className={AllActive ? 'active' : ''}>
                        <Form.Label>
                          All{' '}
                          <input
                            type="checkbox"
                            id="ALL"
                            name="ALL"
                            value="ALL"
                            onChange={doAllCheckedValues}
                            // onChange={handleSelectAll}
                            checked={
                              AllActive
                            }
                          />{' '}
                          <span />
                        </Form.Label>
                      </li>
                      <li
                        className={
                          allCheckedValues.includes('builders')
                            ? 'active'
                            : ''
                        }>
                        <Form.Label>
                          Contacts{' '}
                          <input
                            type="checkbox"
                            id="builders"
                            name="builders"
                            value="builders"
                            onChange={handleClick}
                            checked={allCheckedValues.includes(
                              'builders',
                            )}
                          />{' '}
                          <span />
                        </Form.Label>
                      </li>
                      <li
                        className={
                          allCheckedValues.includes('sessions')
                            ? 'active'
                            : ''
                        }>
                        <Form.Label>
                          Virtual{' '}
                          <input
                            type="checkbox"
                            id="sessions"
                            name="sessions"
                            value="sessions"
                            onChange={handleClick}
                            checked={allCheckedValues.includes(
                              'sessions',
                            )}
                          />{' '}
                          <span />
                        </Form.Label>
                      </li>
                      <li
                        className={
                          allCheckedValues.includes('events')
                            ? 'active'
                            : ''
                        }>
                        <Form.Label>
                          In-person{' '}
                          <input
                            type="checkbox"
                            id="events"
                            name="events"
                            value="events"
                            onChange={handleClick}
                            checked={allCheckedValues.includes(
                              'events',
                            )}
                          />{' '}
                          <span />
                        </Form.Label>
                      </li>
                      <li
                        className={
                          allCheckedValues.includes('opportunities')
                            ? 'active'
                            : ''
                        }>
                        <Form.Label>
                          Recruitment{' '}
                          <input
                            type="checkbox"
                            id="opportunities"
                            name="opportunities"
                            value="opportunities"
                            onChange={handleClick}
                            checked={allCheckedValues.includes(
                              'opportunities',
                            )}
                          />{' '}
                          <span />
                        </Form.Label>
                      </li>

                      {/* <li className={'active'}>
                      <Form.Label>
                        Questions{' '}
                        <input
                          type="checkbox"
                          id="questions"
                          name="questions"
                          value="questions"
                          onChange={handleClick}
                          checked={allCheckedValues.includes(
                            'questions',
                          )}
                        />{' '}
                        <span />
                      </Form.Label>
                    </li> */}
                      <li
                        className={
                          allCheckedValues.includes('snapshots')
                            ? 'active'
                            : ''
                        }>
                        <Form.Label>
                          Credentials{' '}
                          <input
                            type="checkbox"
                            id="snapshots"
                            name="snapshots"
                            value="snapshots"
                            onChange={handleClick}
                            checked={allCheckedValues.includes(
                              'snapshots',
                            )}
                          />{' '}
                          <span />
                        </Form.Label>
                      </li>

                      <li
                        className={
                          allCheckedValues.includes('appointment')
                            ? 'active'
                            : ''
                        }>
                        <Form.Label>
                          Scheduler{' '}
                          <input
                            type="checkbox"
                            id="appointment"
                            name="appointment"
                            value="appointment"
                            onChange={handleClick}
                            checked={allCheckedValues.includes(
                              'appointment',
                            )}
                          />{' '}
                          <span />
                        </Form.Label>
                      </li>
                      <li
                        className={
                          allCheckedValues.includes('group_chat')
                            ? 'active'
                            : ''
                        }>
                        <Form.Label>
                          Group Chat{' '}
                          <input
                            type="checkbox"
                            id="group_chat"
                            name="group_chat"
                            value="group_chat"
                            onChange={handleClick}
                            checked={allCheckedValues.includes(
                              'group_chat',
                            )}
                          />{' '}
                          <span />
                        </Form.Label>
                      </li>
                    </ul>
                  </div>
                </div>
                </Scrollbars>

                <div className="filter-btn-wrapper bg_transparent text-center bottom-0 pt-2">
                  <Button
                    disabled={allCheckedValues.length === 0}
                    className="btn-dark"
                    type="submit"
                    onClick={() => {
                    //   setShowSpinner(true);
                      if (allCheckedValues.length > 1) {
                        setSubTabsType('all');
                      } else {
                        if (allCheckedValues) {
                          setSubTabsType(allCheckedValues[0]);
                        }
                      }

                    //   setshowFilter(false);
                      onClose()

                    //   getReferrals(subTabType, '', arrowButton, 1);
                    }}>
                    Filter
                  </Button>
                </div>
              </div>
            </CustomModal>
          </>
    )
}

export default FilterRefferels
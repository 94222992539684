import React, {useEffect, useState} from 'react';
import CustomSlidingButtons from '../CustomSlidingButtons';
import {
  addTitleWithStatus,
  changeFileVariable,
  deleteProjectItem,
  deleteProjectItem2,
  editTitleWithStatus,
  fixName,
  generateRandomId,
  getNextDayDate,
  milestoneButtons,
  updateProjectStatus,
  updateStatus,
} from '../../Utils/commonUtils';
import Input from '../Input/Input';
import {Dropdown, Form} from 'react-bootstrap';
import MilestoneListing from './MilestoneListing';
import {errorMessage} from '../../Utils/ToastMessages';
import commonApi from '../../Services/CommonService';
import Upload from './Upload';
import ViewFile from '../UplioadFile/ViewFile';
import {format} from 'date-fns';

const CreateProjectMilestones = ({
  name,
  filterType,
  setFilterType,
  setFilterValue,
  filterValue,
  milestones,
  setMilestones,
  independentDeliverables,
  setIndependentDeliverables,
  independentTasks,
  setIndependentTasks,
  data,
  handleTeam,
  type,
  setType,
  deliverableType,
  setDeliverableType,
  manageInvoice,
  handleDescription,
  setUploadFilesListing,
  uploadFilesListing,
  showAssociateListing,
  enable_edit,
  handleDeleteFlow,
  handleTask,
  newTaskData,
}) => {
  // const [milestones, setMilestones] = useState([]);
  // const [independentDeliverables, setIndependentDeliverables] = useState([]);
  // const [independentTasks, setIndependentTasks] = useState([]); // For tasks not linked to milestones/deliverables
  const [taskList, setTaskList] = useState([]); // For tasks not linked to milestones/deliverables
  const [threeDots, setThreeDots] = useState(data ? false : true);
  const [showAttachments, setShowAttachments] = useState(false);
  const [showFileComponent, setShowFileComponent] = useState(false);
  const [documentInfo, setDocumentInfo] = useState([]);
  const [editProject, setEditProject] = useState(false);
  const [isEditing, setIsEditing] = useState({
    editing: false,
    item: {},
  });
  const date = new Date();
  console.log(
    enable_edit,
    'uploadFilesListinguploadFilesListing',
    showAssociateListing,
  );

  const [milestone_start_date, setmilestone_start_date] = useState('');
  const [milestone_end_date, setmilestone_end_date] = useState('');
  const [delivery_start_date, setdelivery_start_date] = useState('');
  const [delivery_end_date, setdelivery_end_date] = useState('');
  const [task_due_date, settask_due_date] = useState('');
  const [task_start_date, settask_start_date] = useState('');

  const [searchListing, setSearchListing] = useState('');
  const [isExpanded, setIsExpanded] = useState(true);
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    return date;
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    return date;
  });

  const handleClickButtons = e => {
    const {id, value} = e.target;
    setFilterValue(value);
  };
  console.log(
    filterValue,
    'filterTypefilterTypefilterTypefilterTypefilterType',
  );

  const handleChange = (e, type) => {
    if (type === 'search') {
      setSearchListing(e.target.value);
    } else {
      setTitle(e.target.value);
    }
  };

  console.log(milestones, '=====>>>>milestonesmilestones');
  console.log(type, '=====>>>>typetypetypetype');
  console.log(
    independentDeliverables,
    '=====>>>>independentDeliverablesindependentDeliverables',
  );
  console.log(independentTasks, '=====>>>>independentTasksindependentTasks');

  const handleListing = taskitem => {
    const trimmedTitle = title.trim();

    console.log(
      isEditing.item,
      'isEditing.item',
      independentDeliverables,
      'DDDDDDDDDDDDDDd',
      type,
    );

    if (trimmedTitle === '' && name !== 'Tasks') {
      errorMessage(`${name} title cannot be empty`);
      return;
    }

    if (name === 'Milestones') {
      if (isEditing?.editing) {
        // const updatedMilestones = milestones.map(milestone =>
        //   milestone.id === isEditing.item.id
        //     ? {...milestone,
        //       title: trimmedTitle,
        //     }
        //     : milestone,
        // );
        // setMilestones(updatedMilestones);

        editTitleWithStatus(
          trimmedTitle,
          name,
          type,
          deliverableType,
          setMilestones,
          setIndependentDeliverables,
          milestones,
          independentDeliverables,
          independentTasks,
          setIndependentTasks,
          isEditing,
        );
      } else {
        // const getMilestoneID = generateRandomId();
        // const newMilestone = {
        //   description: '',
        //   id: getMilestoneID,
        //   invoice_ids: '',
        //   milestone_end_date: getNextDayDate(),
        //   milestone_start_date: date,
        //   team_ids: '',
        //   title: trimmedTitle,
        //   milestone_status: 'pending'
        // };
        // setMilestones(prevMilestones => [...prevMilestones, newMilestone]);

        addTitleWithStatus(
          trimmedTitle,
          name,
          type,
          deliverableType,
          setMilestones,
          setIndependentDeliverables,
          milestones,
          independentDeliverables,
          independentTasks,
          setIndependentTasks,
        );
      }
    } else if (name === 'Deliverables') {
      if (isEditing?.editing) {
        // const updateDeliverable = independentDeliverables.map(deli =>
        //   deli.id === isEditing.item.id ? {...deli,
        //     title: trimmedTitle,
        //     milestone_id: type,
        //   } : deli,
        // );

        // const updateMilestone = milestones.map(mile =>
        //   mile.id === type ? {...mile,
        //     milestone_status: 'pending',
        //   } : mile,
        // );

        // const updateTasks = independentTasks.map(task =>
        //   task.deliverable_id === isEditing.item.id ? {...task,
        //     milestone_id: type,
        //   } : task,
        // );
        // setIndependentDeliverables(updateDeliverable);
        // setIndependentTasks(updateTasks);
        // setMilestones(updateMilestone);

        editTitleWithStatus(
          trimmedTitle,
          name,
          type,
          deliverableType,
          setMilestones,
          setIndependentDeliverables,
          milestones,
          independentDeliverables,
          independentTasks,
          setIndependentTasks,
          isEditing,
        );
      } else {

        // updateStatus(trimmedTitle, name, type, deliverableType, setMilestones, setIndependentDeliverables, milestones, independentDeliverables, independentTasks, setIndependentTasks)

        //   const deliverableId = generateRandomId();
        //   const newDeliverable = {
        //     description: '',
        //     id: deliverableId,
        //     invoice_ids: '',
        //     delivery_end_date: getNextDayDate(),
        //     delivery_start_date: date,
        //     team_ids: '',
        //     title: trimmedTitle,
        //     milestone_id: type,
        //     offering_ids: '',
        //     task_ids: '',
        //     deliverable_status: 'pending'
        //   };
        //   console.log(type, 'typetypetypetypetypetype');

        //   setIndependentDeliverables(prevDeliverable => [
        //     ...prevDeliverable,
        //     newDeliverable,
        //   ]);

        addTitleWithStatus(
          trimmedTitle,
          name,
          type,
          deliverableType,
          setMilestones,
          setIndependentDeliverables,
          milestones,
          independentDeliverables,
          independentTasks,
          setIndependentTasks,
        );
      }
    } else if (name === 'Tasks') {
      if (isEditing?.editing) {
        // const updateDeliverable = independentDeliverables.map(deli =>
        //   deli.id === deliverableType ? {...deli,
        //     deliverable_status: 'pending',
        //   } : deli,
        // );

        // const updateMilestone = milestones.map(mile =>
        //   mile.id === type ? {...mile,
        //     milestone_status: 'pending',
        //   } : mile,
        // );

        // const updateTasks = independentTasks.map(task =>
        //   task.id === isEditing.item.id ? {...task,
        //     title: trimmedTitle,
        //     milestone_id: type,
        //     deliverable_id: deliverableType,
        //   } : task,
        // );
        // setIndependentTasks(updateTasks);
        // setMilestones(updateMilestone);
        // setIndependentDeliverables(updateDeliverable);

        editTitleWithStatus(
          '',
          name,
          type,
          deliverableType,
          setMilestones,
          setIndependentDeliverables,
          milestones,
          independentDeliverables,
          independentTasks,
          setIndependentTasks,
          isEditing,
          taskitem,
        );
      } else {
        // const tasksId = generateRandomId();
        // const newTask = {
        //   description: '',
        //   id: tasksId,
        //   task_id: taskitem.id,
        //   task_due_date: getNextDayDate(),
        //   task_start_date: date,
        //   team_ids: '',
        //   title: taskitem.title,
        //   milestone_id: type,
        //   deliverable_id: deliverableType,
        //   task_status: 'pending'
        // };

        // setIndependentTasks(prevTasks => [...prevTasks, newTask]);

        addTitleWithStatus(
          '',
          name,
          type,
          deliverableType,
          setMilestones,
          setIndependentDeliverables,
          milestones,
          independentDeliverables,
          independentTasks,
          setIndependentTasks,
          taskitem,
        );
      }
      // if (isEditing?.editing) {
      //   if (type !== 'none' && deliverableType !== 'none') {
      //     const updatedMilestones = milestones.map(milestone =>
      //       milestone.milestone_id === type
      //         ? {
      //             ...milestone,
      //             deliverables: milestone.deliverables.map(deliverable =>
      //               deliverable.deliverable_id === deliverableType
      //                 ? {
      //                     ...deliverable,
      //                     tasks: deliverable.tasks.map(task =>
      //                       task.task_id === isEditing?.item.task_id
      //                         ? {...task, title: trimmedTitle}
      //                         : task,
      //                     ),
      //                   }
      //                 : deliverable,
      //             ),
      //           }
      //         : milestone,
      //     );
      //     setMilestones(updatedMilestones);
      //   } else if (type !== 'none') {
      //     const updatedMilestones = milestones.map(milestone =>
      //       milestone.milestone_id === type
      //         ? {
      //             ...milestone,
      //             tasks: milestone.tasks.map(task =>
      //               task.task_id === isEditing?.item.task_id
      //                 ? {...task, title: trimmedTitle}
      //                 : task,
      //             ),
      //           }
      //         : milestone,
      //     );
      //     setMilestones(updatedMilestones);
      //   } else if (deliverableType !== 'none') {
      //     const updatedDeliverables = independentDeliverables.map(deliverable =>
      //       deliverable.deliverable_id === deliverableType
      //         ? {
      //             ...deliverable,
      //             tasks: deliverable.tasks.map(task =>
      //               task.task_id === isEditing?.item.task_id
      //                 ? {...task, title: trimmedTitle}
      //                 : task,
      //             ),
      //           }
      //         : deliverable,
      //     );
      //     setIndependentDeliverables(updatedDeliverables);
      //   } else {
      //     const updatedTasks = independentTasks.map(task =>
      //       task.task_id === isEditing?.item.task_id
      //         ? {...task, title: trimmedTitle}
      //         : task,
      //     );
      //     setIndependentTasks(updatedTasks);
      //   }
      // } else {
      //   if (type !== 'none' && deliverableType !== 'none') {
      //     const updatedMilestones = milestones.map(milestone =>
      //       milestone.milestone_id === type
      //         ? {
      //             ...milestone,
      //             deliverables: milestone.deliverables.map(deliverable =>
      //               deliverable.deliverable_id === deliverableType
      //                 ? {
      //                     ...deliverable,
      //                     tasks: [...deliverable.tasks, newTask],
      //                   }
      //                 : deliverable,
      //             ),
      //           }
      //         : milestone,
      //     );
      //     setMilestones(updatedMilestones);
      //   } else if (type !== 'none') {
      //     const updatedMilestones = milestones.map(milestone =>
      //       milestone.milestone_id === type
      //         ? {
      //             ...milestone,
      //             tasks: [...milestone.tasks, newTask],
      //           }
      //         : milestone,
      //     );
      //     setMilestones(updatedMilestones);
      //   } else if (deliverableType !== 'none') {
      //     const updatedDeliverables = independentDeliverables.map(deliverable =>
      //       deliverable.deliverable_id === deliverableType
      //         ? {
      //             ...deliverable,
      //             tasks: [...deliverable.tasks, newTask],
      //           }
      //         : deliverable,
      //     );
      //     setIndependentDeliverables(updatedDeliverables);
      //   } else {
      //     setIndependentTasks([...independentTasks, newTask]);
      //   }
      // }
    }

    setIsEditing({
      editing: false,
      item: {},
    });
    setTitle('');
  };

  console.log(milestones, 'ppooooosddddddddddddddddd');
  console.log(independentDeliverables, '=======>independentDeliverables');
  console.log(independentTasks, '=======>independentTasks');

  useEffect(() => {
    setTitle('');
    setIsEditing({
      editing: false,
      item: {},
    });
  }, [name]);

  const change_status_of_project = (type, id) => {
    const payload = {
      type:
        type === 'milestones'
          ? 'milestone'
          : type === 'deliverables'
          ? 'deliverable'
          : 'task',
      type_id: id,
    };
    // const update = updateProjectStatus(id, type, independentTasks, setIndependentTasks, independentDeliverables, setIndependentDeliverables, milestones, setMilestones)
    //      console.log(update, 'updateupdate')
    //  return
    commonApi
      .change_status_of_project(payload)
      .then(res => {
        if (res.status === 200) {
          // if (!value || value === '') {
          //   setTaskList([]);
          // } else {
          //   setTaskList(res.all_actions);
          // }
          updateProjectStatus(
            id,
            type,
            independentTasks,
            setIndependentTasks,
            independentDeliverables,
            setIndependentDeliverables,
            milestones,
            setMilestones,
          );
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  console.log(isEditing, '=====>>>>independentTasksindependentTasks');

  const create_action = () => {
    const payload = {
      title: title,
      association_id: localStorage.getItem('id'),
      association_type: 'profile',
      priority: '',
      due_date: '',
      due_time: '',
      description: '',
      assigne: '',
      watchers: '',
      images: '',
      workspace_ids: localStorage.getItem('activeIds'),
      action_id: isEditing.editing ? isEditing?.item?.task_id : '',
    };
    // return
    commonApi
      .create_action(payload)
      .then(res => {
        if (res.status === 200) {
          handleListing(res.action);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (milestones?.length > 0) {
      setType(milestones[0].id.toString());
    } else {
      setType('');
    }
  }, [name]);
  useEffect(() => {
    if (independentDeliverables.length > 0) {
      const filteredDeliverables = independentDeliverables.filter(
        item => item.milestone_id.toString() === type,
      );
      setDeliverableType(
        filteredDeliverables.length > 0
          ? filteredDeliverables[0].id.toString()
          : '',
      );
    } else {
      setDeliverableType('');
    }
  }, [name, milestones, type]);

  useEffect(() => {
    if (newTaskData && Object.keys(newTaskData).length > 0) {
      const tasksId = generateRandomId();

      const newTask = {
        description: '',
        id: tasksId,
        task_id: newTaskData.id,
        task_due_date: getNextDayDate(),
        task_start_date: date,
        team_ids: '',
        title: newTaskData.title,
        milestone_id: type,
        deliverable_id: deliverableType,
      };

      setIndependentTasks(prevTasks => [...prevTasks, newTask]);
    }
  }, [newTaskData]);
  console.log(newTaskData, '====>>>>newTaskDatanewTaskData');

  // useEffect(() => {
  //   if (milestones?.length > 0) {

  //     const selectDeliverable = independentDeliverables?.filter((item)=> item.milestone_id === type)

  //     const firstDeliverables =
  //       type !== 'none'
  //         ? selectDeliverable
  //         : [];

  //     if (firstDeliverables.length > 0) {
  //       setDeliverableType(firstDeliverables[0].id);
  //     } else {
  //       setDeliverableType('none');
  //     }
  //   } else {
  //     setDeliverableType('none');
  //   }
  // }, [type, name]);

  console.log(milestones, 'milestonesmilestonesmilestonesmilestones');
  console.log(type, 'typetypetypetype');
  console.log(deliverableType, '=====>>>>>>>>deliverableType');
  console.log(
    independentDeliverables,
    'independentDeliverablesindependentDeliverablesindependentDeliverables',
  );
  console.log(
    independentTasks,
    'independentTasksindependentTasksindependentTasksindependentTasks',
  );
  const decideDeliverableListing =
    type !== ''
      ? milestones?.find(milestone => milestone.milestone_id === type)
          ?.deliverables || []
      : independentDeliverables || [];

  const decideTasksListing =
    type !== '' && deliverableType !== ''
      ? milestones
          ?.find(m => m.milestone_id === type)
          ?.deliverables?.find(d => d.deliverable_id === deliverableType)
          ?.tasks || []
      : type !== ''
      ? milestones?.find(m => m.milestone_id === type)?.tasks || []
      : deliverableType !== ''
      ? independentDeliverables?.find(d => d.deliverable_id === deliverableType)
          ?.tasks || []
      : independentTasks || [];

  console.log(
    decideDeliverableListing,
    'decideDeliverableListingdecideDeliverableListingdecideDeliverableListing',
  );

  console.log(
    deliverableType,
    'deliverableTypedeliverableTypedeliverableTypedeliverableType',
  );

  console.log(
    independentDeliverables,
    '========>independentDeliverablesindependentDeliverablesindependentDeliverables',
  );

  const selectedMilestone = milestones.find(
    item => item.id.toString() === type,
  );
  const deliverables =
    type !== ''
      ? selectedMilestone?.deliverables || []
      : independentDeliverables || [];

  const getDeliverableName = () => {
    if (!deliverableType) return 'No deliverable selected';

    const deliverable = independentDeliverables.find(
      d => d.id.toString() === deliverableType,
    );

    return deliverable ? fixName(deliverable.title) : 'No deliverable selected';
  };

  const getDeliverableDropdownListing = () => {
    const getDeliverableagainstMile = independentDeliverables.filter(
      d => d.milestone_id.toString() === type,
    );
    return getDeliverableagainstMile
      ? getDeliverableagainstMile
      : ['No deliverable selected'];
  };

  console.log(
    deliverableType,
    '==========>>>>>>>>>>>>deliverableTypedeliverableTypedeliverableType',
  );

  useEffect(() => {
    setSearchListing('');
  }, [name]);

  const handleFiles = (item, type) => {
    if (type === 'delete') {
      // setShowModal('confirmation_Model');
      setDocumentInfo(item);
    } else if (type === 'upload') {
      setUploadFilesListing([
        ...uploadFilesListing,
        {
          file_extension: item?.file_extension,
          file_title: item?.file_title,
          file_url: item?.file_url,
        },
      ]);
    } else if (type === 'open') {
      // setShowFileComponent(true);

      setDocumentInfo(changeFileVariable(item));
    }
  };

  const manageStartDate = (newValue, type, item) => {
    console.log(newValue, '======>>>>newValuenewValue');
    if (type === 'milestones') {
      const updateMilesotne = milestones.map(mile =>
        mile.id === item.id ? {...mile, milestone_start_date: newValue} : mile,
      );
      setMilestones(updateMilesotne);
    } else if (type === 'deliverables') {
      const updateDeliverables = independentDeliverables.map(deli =>
        deli.id === item.id ? {...deli, delivery_start_date: newValue} : deli,
      );
      setIndependentDeliverables(updateDeliverables);
    } else if (type === 'task') {
      const updateTask = independentTasks.map(task =>
        task.id === item.id ? {...task, task_start_date: newValue} : task,
      );
      setIndependentTasks(updateTask);
    }
  };
  const manageEndDate = (newValue, type, item) => {
    if (type === 'milestones') {
      const updateMilesotne = milestones.map(mile =>
        mile.id === item.id ? {...mile, milestone_end_date: newValue} : mile,
      );
      setMilestones(updateMilesotne);
    } else if (type === 'deliverables') {
      const updateDeliverables = independentDeliverables.map(deli =>
        deli.id === item.id ? {...deli, delivery_end_date: newValue} : deli,
      );
      setIndependentDeliverables(updateDeliverables);
    } else if (type === 'task') {
      const updateTask = independentTasks.map(task =>
        task.id === item.id ? {...task, task_due_date: newValue} : task,
      );
      setIndependentTasks(updateTask);
    }
  };
  // const manageInvoice = () => {

  // };

  const showDeliverableListing =
    type === ''
      ? independentDeliverables?.length > 0 && independentDeliverables
      : independentDeliverables?.length > 0 &&
        independentDeliverables?.filter(item => item.milestone_id === type);

  const filteredTasks =
    type === '' && deliverableType === ''
      ? independentTasks.length > 0 && independentTasks
      : independentTasks.length > 0 &&
        independentTasks?.filter(
          item =>
            item.milestone_id === type &&
            item.deliverable_id === deliverableType,
        );

  console.log(showDeliverableListing, '=====>>showDeliverableListing');
  console.log(filteredTasks, '=====>>showTasksListing');
  console.log(independentTasks, '=====>>independentTasks');
  console.log(milestones, type, 'milestones');
  console.log(independentDeliverables, 'independentDeliverables');

  return (
    <>
      <div className="flex justify-content-between p-1 input-plus-icon-holder">
        <h4>{name}</h4>
        {data && (
          <div className="search-tabs-icons">
            <span
              onClick={() => {
                setThreeDots(!threeDots);
              }}
              className={threeDots ? 'icon active as-link' : 'icon as-link'}>
              <i className="fas fa-ellipsis-vertical" />
            </span>
          </div>
        )}
      </div>

      {threeDots && (
        <>
          {(name === 'Deliverables' || name === 'Tasks') && (
            <div className="m-3">
              <div className="select-wrapper">
                <Dropdown
                  onSelect={(eventKey, event) => {
                    setType(eventKey);
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {type !== '' && milestones?.length > 0
                      ? fixName(
                          milestones?.find(item => item.id.toString() === type)
                            ?.title || '',
                        )
                      : 'No milestone selected'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {milestones && milestones.length > 0 ? (
                      <>
                        {milestones.map((item, index) => (
                          <Dropdown.Item
                            key={`st-${index}`}
                            eventKey={item.id.toString()}
                            active={item.id.toString() === type} // Ensure correct comparison
                          >
                            {item.title
                              ? fixName(item.title)
                              : 'No milestone selected'}
                          </Dropdown.Item>
                        ))}

                        <Dropdown.Item eventKey="" active={type === ''}>
                          No milestone selected
                        </Dropdown.Item>
                      </>
                    ) : (
                      <Dropdown.Item eventKey="" active={type === ''}>
                        No milestone selected
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )}
          {name === 'Tasks' && (
            <div className="m-3">
              <div className="select-wrapper">
                <Dropdown
                  onSelect={eventKey => setDeliverableType(eventKey)}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {getDeliverableName()}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {getDeliverableDropdownListing().length > 0 ? (
                      <>
                        {getDeliverableDropdownListing().map((item, index) => (
                          <Dropdown.Item
                            key={`st-${index}`}
                            eventKey={item.id.toString()}
                            active={item.id.toString() === deliverableType}>
                            {fixName(item.title)}
                          </Dropdown.Item>
                        ))}
                        <Dropdown.Item
                          eventKey=""
                          active={deliverableType === ''}>
                          No deliverable selected
                        </Dropdown.Item>
                      </>
                    ) : (
                      <Dropdown.Item eventKey="" active={type === ''}>
                        No deliverable selected
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )}
          {data && (
            <div
              className="category-btns-wrapper category-btns-wrapper-slider"
              style={{marginTop: '8px'}}>
              <CustomSlidingButtons
                items={milestoneButtons}
                activeButtons={filterValue}
                onItemClick={e => {
                  handleClickButtons(e);
                }}
                showItems={3}
                label={'name'}
                value={filterValue}
              />
            </div>
          )}
        </>
      )}
      {data && (
        <Input
          label=""
          name={'searchListing'}
          value={searchListing}
          onChange={e => handleChange(e, 'search')}
          placeholder={`Search for ${name}`}
          required={false}
          type={'text'}
        />
      )}
      {(enable_edit || showAssociateListing === '') && (
        <div
          className="billing-detail"
          style={{backgroundColor: 'rgb(237, 235, 229)'}}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <h1
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
              className="pt-2 pb-2 as-link">
              Add {name}
            </h1>
            {name === 'Tasks' && (
              <span
                onClick={() => handleTask()}
                className={'icon search-tabs-icons as-link'}
                style={{marginRight: '5px', color: '#52bd41'}}>
                Use full form
              </span>
            )}
            {/* <span
            className={'icon search-tabs-icons as-link'}
            style={{marginRight: '5px'}}>
            <i
              className="icon-plus"
              onClick={() => {
                handleListing();
              }}
            />
          </span> */}
          </div>
          <div
            className={`transition-all duration-300 ease-in-out overflow-hidden  mt-2 ${
              isExpanded ? 'max-h-screen' : 'max-h-0'
            }`}>
            <hr class="border-t border-gray-300 pb-3"></hr>
            <div className="roi-form-input">
              <Form.Control
                label=""
                name={'title'}
                value={title}
                onChange={e => setTitle(e.target.value)}
                placeholder={`${name} title`}
                required={false}
                type={'text'}
              />

              {/* {title && name === 'Tasks' && (
              <ul className="custom-suggestions invoices_suggestion">
                {taskList?.length > 0 ? (
                  taskList?.map(suggestion => (
                    <li
                      className="as-link"
                      key={'suggestion' + suggestion.task_id}
                      onClick={() => {
                        handleListing(suggestion);
                      }}>
                      {suggestion?.title}
                    </li>
                  ))
                ) : (
                  <li className="as-link">No tasks available.</li>
                )}
              </ul>
            )} */}

              <div className="flex justify-center items-center mt-3">
                <button
                  style={{backgroundColor: '#52bd41'}}
                  type="button"
                  onClick={() => {
                    if (name === 'Tasks') {
                      const trimmedTitle = title.trim();

                      if (trimmedTitle === '') {
                        errorMessage(`${name} title cannot be empty`);
                        return;
                      } else {
                        create_action();
                      }
                    } else {
                      handleListing();
                    }
                  }}
                  className="text-white px-4 py-2 rounded-md">
                  {isEditing?.editing ? 'Update' : 'Add'}
                </button>
              </div>
              {isEditing?.editing && (
                <div className="flex justify-center items-center mt-3">
                  <button
                    style={{backgroundColor: '#52bd41'}}
                    type="button"
                    className="text-white px-4 py-2 rounded-md"
                    onClick={() => {
                      setTitle('');
                      setIsEditing({
                        editing: false,
                        item: {},
                      });
                    }}>
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {name === 'Milestones' && milestones && milestones.length > 0 && (
        <MilestoneListing
          listing={milestones}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
          setIsEditing={setIsEditing}
          setTitle={setTitle}
          searchListing={searchListing}
          handleTeam={handleTeam}
          manageStartDate={manageStartDate}
          manageEndDate={manageEndDate}
          manageInvoice={manageInvoice}
          type={'milestones'}
          otherListing={
            independentDeliverables.length > 0 ? independentDeliverables : []
          }
          handleDescription={handleDescription}
          showAssociateListing={showAssociateListing}
          enable_edit={enable_edit}
          handleDelete={handleDeleteFlow}
          filterType={filterValue}
          handleStatus={change_status_of_project}
        />
      )}

      {name === 'Deliverables' &&
        showDeliverableListing &&
        showDeliverableListing.length > 0 && (
          <MilestoneListing
            listing={showDeliverableListing}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
            setIsEditing={setIsEditing}
            setTitle={setTitle}
            searchListing={searchListing}
            handleTeam={handleTeam}
            manageStartDate={manageStartDate}
            manageEndDate={manageEndDate}
            manageInvoice={manageInvoice}
            type={'deliverables'}
            otherListing={independentTasks.length > 0 ? independentTasks : []}
            handleDescription={handleDescription}
            enable_edit={enable_edit}
            handleDelete={handleDeleteFlow}
            showAssociateListing={showAssociateListing}
            filterType={filterValue}
            handleStatus={change_status_of_project}
          />
        )}

      {name === 'Tasks' && filteredTasks && filteredTasks.length > 0 && (
        <MilestoneListing
          listing={filteredTasks}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
          setIsEditing={setIsEditing}
          setTitle={setTitle}
          type="task"
          searchListing={searchListing}
          handleTeam={handleTeam}
          manageStartDate={manageStartDate}
          manageEndDate={manageEndDate}
          manageInvoice={manageInvoice}
          handleDescription={handleDescription}
          showAssociateListing={showAssociateListing}
          enable_edit={enable_edit}
          handleDelete={handleDeleteFlow}
          filterType={filterValue}
          handleStatus={change_status_of_project}
        />
      )}

      {name === 'Milestones' && (
        <div className="bg-white rounded-md shadow-md mb-2 mt-2">
          <div
            className="flex justify-between items-center p-3 cursor-pointer"
            onClick={() => setShowAttachments(!showAttachments)}>
            <h2 className="text-base font-semibold">Attachments</h2>
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ${
              showAttachments ? 'max-h-screen' : 'max-h-0'
            }`}>
            <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
            <div
              onClick={() => {
                setEditProject(true);
              }}
              className="p-4  pt-0 space-y-2 height-input">
              <Upload
                uploadFilesListing={uploadFilesListing}
                setUploadFilesListing={setUploadFilesListing}
                handleFiles={handleFiles}
                setShowFileComponent={setShowFileComponent}
                type={
                  enable_edit || showAssociateListing === ''
                    ? 'dynamic_attachment'
                    : ' '
                }
                hideCross={true}
              />
            </div>
          </div>
        </div>
      )}
      {/* 
      {showFileComponent && (
        <ViewFile
          selectedFile={documentInfo}
          onClose={() => {
            setShowFileComponent(false);
          }}
        />
      )} */}
    </>
  );
};

export default CreateProjectMilestones;

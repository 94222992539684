import React from 'react';
import {Button, Form} from 'react-bootstrap';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';

import {useState, useEffect, useRef} from 'react';
import CustomTabs from '../CustomTabs';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {RoiLogo, Templates} from '../../Constants/Images';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import {
  capitalizeFirst,
  deleteProjectItem2,
  filterStartingWithInteger,
  fixName,
  handleTeamArray,
  transformProjectData,
} from '../../Utils/commonUtils';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import CustomDropdown from '../CustomDropdown';
import CustomSlidingButtons from '../CustomSlidingButtons';
import Invite from '../SingleComponent/Invite';
import LoaderSpinner from '../Modals/LoaderSpinner';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import commonApi from '../../Services/CommonService';
import IconModal from '../SessionDetails/IconModal';
import NorecordFound from '../NoRecord/NorecordFound';
import Calender from '../FeedBack/Calender';
import Moment from 'moment';
import CreateProjectMilestones from './CreateProjectMilestones';
import InvoiceListingForProject from './InvoiceListingForProject';

const CreateProject = props => {
  const [tabType, setTabType] = useState(labels.details[selected_lang]);
  const [subTabType, setSubTabsType] = useState(
    labels.milestone[selected_lang],
  );
  // const [subTabType, setSubTabsType] = useState('execute');
  const [filterValue, setFilterValue] = useState('recent');
  const [filterType, setFilterType] = useState(['recent']);
  const [projectName, setProjectName] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [pocName, setPocName] = useState('');
  const [customersList, setCustomersList] = useState([]);
  const [pocList, setPocList] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [independentDeliverables, setIndependentDeliverables] = useState([]);
  const [independentTasks, setIndependentTasks] = useState([]); // For tasks not linked to milestones/deliverables
  const [uploadFilesListing, setUploadFilesListing] = useState([]);
  const [delMilestone, setDelMilestone] = useState([]);
  const [delDeliverable, setDelDeliverable] = useState([]);
  const [delTasks, setDelTasks] = useState([]);

  console.log(milestones, '====>>>>>milestonesmilestonesmilestonesddddddddddd');
  console.log(
    independentDeliverables,
    '====>>>>>independentDeliverablesindependentDeliverablesmilestones',
  );
  console.log(
    independentTasks,
    '====>>>>>independentTasksindependentTasksmilestones',
  );
  console.log(delMilestone, '====>>>>>delMilestonedelMilestone')

  const [nameError, setNameError] = useState(false);
  const [pocError, setPocError] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIdAndType, setDeleteIdAndType] = useState({});
  const [tagTitleList, setTagTitleList] = useState([]);
  const [projectTeamsListing, setProjectTeamsListing] = useState([]);
  const [teamBuildes, setTeamBuildes] = useState([]);
  const [deliverableTeam, setDeliverableTeam] = useState([]);
  const [taskTeam, settaskTeam] = useState([]);
  const [allBuilders, setAllBuilders] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [hitApiAddedUser, setHitApiAddedUser] = useState();
  const [contact, setContact] = useState([]);
  const [teamData, setTeamData] = useState({
    type: '',
    item: {},
  });
  const [invoiceData, setInvoiceData] = useState({
    type: '',
    item: {},
  });
  const [users, setUsers] = useState([]);
  console.log(invoiceData, '===>>>invoiceDatainvoiceData');

  const [validated, setValidated] = useState(false);
  const [dropdownId, setDropdownId] = useState('1');
  const [threeDots, setThreeDots] = useState(false);
  const [actionTypeValue, setActionTypeValue] = useState(['Initialized']);
  const [actionType, setActionType] = useState([1]);
  const [showComponent, setShowComponent] = useState('');
  const [showSecondaryComponent, setShowSecondaryComponent] = useState('');
  const [aliasRoles, setAliasRoles] = useState([]);
  const [pocAliase, setPocAliase] = useState([]);
  // Invite States
  const [builders, setBuilders] = useState([]);
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteTeamLoader, setTeamInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  // const [folowersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');
  const [listRoles, setListRoles] = useState([]);
  const [showSearchRoles, setShowSearchRoles] = useState([]);
  const [selectedAllAlias, setSelectedAllAlias] = useState([]);
  const [deletedType, setDeletedType] = useState('');
  const [showContactDetails, setShowContactDetails] = useState(true);
  const buttonRef = useRef(null);
  const [milestoneType, setMilestoneType] = useState('');
  const [deliverableType, setDeliverableType] = useState('');
  const enable_edit = (props.type === 'editProject') && localStorage.getItem('id') === props.projectListing?.created_by;
  console.log(
    enable_edit,
    '====>>>>enable_editenable_edit', props.type, 'rrrrrrrrrrrrrr',
    props.projectListing,
  );
  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [invoiceDate, setInvoiceDate] = useState(() => {
    const date = new Date();
    return date;
  });

  const [selectedInvoiveForProject, setSelectedInvoiveForProject] = useState(
    [],
  );
  const [selectedInvoiveForMilestone, setSelectedInvoiveForMilestone] =
    useState([]);
  const [selectedInvoiveForDeliverable, setSelectedInvoiveForDeliverable] =
    useState([]);

  const [originalTeams, setOriginalTeams] = useState([]);
  const [allTeams, setAllTeams] = useState([]);

  const [showInvoices, setShowInvoices] = useState([]);

  const handleClickD = e => {
    // e.preventDefault();
    if(enable_edit || (props.type === 'create_project')){
      setIsOpen(true);
    }
  };

  const handleCircle = () => {
    if (showComponent === 'teams') {
      showSecondaryComponent('CircleList');
    } else {
      setShowComponent('CircleList');
    }
  };

  const CircleUserListData = item => {
    if (showComponent === 'teams') {
      if (item.length > 0) {
        item.forEach((miniItem, index) => {
          if (!teamBuildes.includes(miniItem.id)) {
            setTeamBuildes(previous => [...previous, miniItem.id]);
            setAllBuilders(previous => [...previous, miniItem]);
            setOriginalDataOfMod(previous => [...previous, miniItem]);
          }
        });
      }
    } else {
      if (item.length > 0) {
        item.forEach((miniItem, index) => {
          if (!projectTeamsListing.includes(miniItem.id)) {
            setProjectTeamsListing(previous => [...previous, miniItem.id]);
            setAllBuilders(previous => [...previous, miniItem]);
            setOriginalDataOfMod(previous => [...previous, miniItem]);
          }
        });
      }
    }
  };

  const handleTeam = (item, type) => {
    setAllTeams([]);
    setOriginalTeams([]);
    setTeamData({
      item: item,
      type: type,
    });
    type === 'milestones'
      ? setTeamBuildes(item.team_ids ? item.team_ids.split(',') : [])
      : teamData.type === 'deliverables'
      ? setDeliverableTeam(item.team_ids ? item.team_ids.split(',') : [])
      : settaskTeam(item.team_ids ? item.team_ids.split(',') : []);

    setShowComponent('teams');
  };

  console.log(teamData, '=======>>>>MMMMMMMMMMMMMMMMMmmmm');

  const handleSelectUser = () => {
    if (teamData.type === 'milestones') {
      const updateMilesotne = milestones.map(mile =>
        mile.id === teamData.item.id
          ? {...mile, team_ids: teamBuildes.toString(',')}
          : mile,
      );
      setMilestones(updateMilesotne);
    } else if (teamData.type === 'deliverables') {
      const updateDeliverables = independentDeliverables.map(deli =>
        deli.id === teamData.item.id
          ? {...deli, team_ids: deliverableTeam.toString(',')}
          : deli,
      );
      setIndependentDeliverables(updateDeliverables);
    } else if (teamData.type === 'task') {
      const updateTask = independentTasks.map(task =>
        task.id === teamData.item.id
          ? {...task, team_ids: taskTeam.toString(',')}
          : task,
      );
      setIndependentTasks(updateTask);
    }
  };

  const manageInvoice = (type, item) => {
    setInvoiceData({
      item: item,
      type: type,
    });
    console.log(item, '====>>>>itemitemitem');
    if (type === 'milestones') {
      setSelectedInvoiveForMilestone(
        item?.invoice_ids !== '' ? item?.invoice_ids : [],
      );
    } else {
      setSelectedInvoiveForDeliverable(
        item?.invoice_ids !== '' ? item?.invoice_ids : [],
      );
    }
    setShowComponent('invoice');
  };

  const handleDescription = (value, type, item) => {
    if (type === 'milestones') {
      const updateMilesotne = milestones.map(mile =>
        mile.id === item.id ? {...mile, description: value} : mile,
      );
      setMilestones(updateMilesotne);
    } else if (type === 'deliverables') {
      const updateDeliverable = independentDeliverables.map(deli =>
        deli.id === item.id ? {...deli, description: value} : deli,
      );
      setIndependentDeliverables(updateDeliverable);
    } else {
      const updateTask = independentTasks.map(task =>
        task.id === item.id ? {...task, description: value} : task,
      );
      setIndependentTasks(updateTask);
    }
  };
  console.log(
    milestones,
    'selectedInvoiveForMilestoneselectedInvoiveForMilestone',
  );
  console.log(
    independentDeliverables,
    'independentDeliverablesindependentDeliverables',
  );

  const handleSelectInvoice = () => {
    if (invoiceData.type === 'milestones') {
      const updateMilesotne = milestones.map(mile =>
        mile.id === invoiceData.item.id
          ? {...mile, invoice_ids: selectedInvoiveForMilestone.toString(',')}
          : mile,
      );
      setMilestones(updateMilesotne);
    } else if (invoiceData.type === 'deliverables') {
      const updateDeliverables = independentDeliverables.map(deli =>
        deli.id === invoiceData.item.id
          ? {...deli, invoice_ids: selectedInvoiveForDeliverable.toString(',')}
          : deli,
      );
      setIndependentDeliverables(updateDeliverables);
    }
  };
  console.log(milestones, 'jhghdjsssssssssss');
  console.log(showComponent, '======>>>>teamsa');

  const handleChange = e => {
    if (e) {
      setInvoiceDate(e);
      setIsOpen(false);
    } else {
      setIsOpen(false);
    }
  };

  const dropdownValues = [
    {id: '1', title: 'Deliverables'},
    {id: '2', title: 'MileStones'},
    {id: '3', title: 'Tasks'},
    {id: '4', title: 'Uploads'},
  ];

  useEffect(() => {
    setCustomerName('');
    setPocName('');
    setShowSearchRoles('');
    get_user_both_invoices();
  }, [tabType]);

  const filterOption = [
    {id: 1, title: 'Initialized'},
    {id: 2, title: 'Phase Change'},
    {id: 3, title: 'Canceled'},
  ];

  const handle = e => {
    const form = e.currentTarget;

    if (
      form.checkValidity() === false ||
      customersList.length === 0 ||
      pocList.length === 0 ||
      projectTeamsListing.length === 0
    ) {
      e.preventDefault();
      e.stopPropagation();

      if (customersList.length == 0 && form.checkValidity()) {
        errorMessage('Please select one customer');
      } else if (pocList.length === 0 && form.checkValidity()) {
        errorMessage('Please select one poc');
      } else if (projectTeamsListing.length === 0 && form.checkValidity()) {
        errorMessage('Please select one team');
        setTabType(labels.team[selected_lang]);
      }

      setValidated(true);
    } else {
      e.preventDefault();

      CreateProject();
    }
  };

  const handleInputChange = event => {
    setCustomerName(event.target.value);
  };
  const customerExist = (customersList, itemId) => {
    return customersList.some(customer => customer.id === itemId);
  };

  const PocExists = (pocList, itemId) => {
    return pocList.some(customer => customer.id === itemId);
  };

  const handleCustomer = item => {
    // const exist = customerExist(customersList, item.id)
    // console.log(exist,'===>exist')
    if (!customerExist(customersList, item.id)) {
      setCustomersList(prev => [...prev, item]);
      setCustomerName('');
      setAliasRoles([]);
    } else {
      errorMessage('You have Already added this Customer');
    }
  };

  const handlePoc = item => {
    // const exist = customerExist(customersList, item.id)
    // console.log(exist,'===>exist')
    if (!PocExists(pocList, item.id)) {
      setPocList(prev => [...prev, item]);
      setPocName('');
      setPocAliase([]);
    } else {
      errorMessage('You have Already added this Poc');
    }
  };

  const removeName = (MiniItem, dataType) => {
    if (dataType === 'CUSTOMERS_DELETE') {
      setCustomersList(previous =>
        previous.filter(item => item.id !== MiniItem.id),
      );
    } else if (dataType === 'POC_NAME_DELETE') {
      setPocList(previous => previous.filter(item => item.id !== MiniItem.id));
    } else if (dataType === 'ROLES_DELETE') {
      setSelectedAllAlias(previous =>
        previous.filter(item => item.id !== MiniItem.id),
      );
    }
    setShowDeleteModel('');
  };

  const handleType = e => {
    const {id, value} = e.target;

    setActionType(id);
    setActionTypeValue([value]);
  };

  const get_customer_list = value => {
    const payload = {
      search_query: value,
    };

    commonApi
      .get_customers_for_project(payload)
      .then(res => {
        if (res.status === 200) {
          console.log(res, 'rrrrrrrrrr');

          setAliasRoles(res.data.filter(item => item.role_id));
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  console.log(aliasRoles, 'ooooooooo');

  const get_all_team_for_project = value => {
    const payload = {search_query: value};

    commonApi
      .get_all_team_for_project(payload)
      .then(res => {
        if (res.status === 200) {
          setPocAliase(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const renderProjeectButtons = team => (
    <>
      {!team ? (
        <p
          className="p-bold m-0 f_600_h"
          style={{cursor: 'pointer'}}
          onClick={() => {
            setShowModal(true);
          }}>
          Tag Icon
        </p>
      ) : (
        <img src={RoiLogo} alt="" style={{cursor: 'pointer'}} />
      )}
      {/* <img src={RoiLogo} alt="" /> */}
    </>
  );

  console.log(showModal, 'showModal');

  const get_company_users_role = value => {
    const payload = {search: value};
    commonApi
      .get_company_users_role(payload)
      .then(res => {
        if (res.status === 200) {
          setListRoles(res.data.filter(item => item.role_type_id !== '1'));
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleAddInArray = array => {
    const idExists = selectedAllAlias.some(alias => alias.id === array.id);

    if (idExists) {
      errorMessage('This role already exists.');
    } else {
      selectedAllAlias.push({
        id: array.id,
        role_alias: array.role_alias,
      });
      setShowSearchRoles('');
      setListRoles([]);
    }
  };

  const handleShowModel = item => {
    // setSelectedData(item);
    // setShowModel('show_delete_model');
  };

  const handleClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  // console.log(transformProjectData(props.projectListing), '====>>>>>projectListingprojectListingprojectListing')

  useEffect(() => {
    if (props.type === 'editProject') {
      if (props.projectListing) {
        const getProjectData = transformProjectData(props.projectListing);

        if (getProjectData?.milestones?.length > 0) {
          setMilestones(getProjectData?.milestones);
        } else {
          setMilestones([]);
        }

        if (getProjectData?.deliverables?.length > 0) {
          setIndependentDeliverables(getProjectData?.deliverables);
        } else {
          setIndependentDeliverables([]);
        }

        if (getProjectData?.tasks?.length > 0) {
          setIndependentTasks(getProjectData?.tasks);
        } else {
          setIndependentTasks([]);
        }
       
        if (getProjectData?.attachments?.length > 0) {
          const getAttachments = getProjectData?.attachments.map(item => ({
            file_extension: item?.file_extention,
            file_title: item?.file_name,
            file_url: item?.url,
          }));
          setUploadFilesListing(getAttachments);
        } else {
          setUploadFilesListing([]);
        }

        setProjectName(getProjectData?.name ? getProjectData?.name : '');
        setInvoiceDate(
          getProjectData?.due_date ? getProjectData?.due_date : '',
        );

        if (getProjectData?.team_ids) {
          console.log(
            getProjectData,
            'getProjectData?.team_detaigetProjectData?.team_detai',
          );
          setProjectTeamsListing(getProjectData?.team_ids.split(','));
          setAllBuilders(getProjectData?.team_detail);
          setOriginalDataOfMod(getProjectData?.team_detail);
        } else {
          setProjectTeamsListing([]);
        }

        if (getProjectData?.customer_details.length > 0) {
          setCustomersList(getProjectData?.customer_details);
        } else {
          setCustomersList([]);
        }

        if (getProjectData?.invoice_ids) {
          setSelectedInvoiveForProject(getProjectData?.invoice_ids.split(','));
        } else {
          setSelectedInvoiveForProject([]);
        }

        if (getProjectData?.poc_details?.length > 0) {
          setPocList(getProjectData?.poc_details);
        } else {
          setPocList([]);
        }

        if (getProjectData?.tag_titles?.length > 0) {
          setTagTitleList(getProjectData?.tag_titles);
        } else {
          setTagTitleList([]);
        }
      }
    }
  }, [props.type, props.projectListing]);

  console.log(delDeliverable, 'delDeliverabledelDeliverable')
  console.log(delMilestone, 'delMilestonedelMilestone')
  console.log(delTasks, 'delTasksdelTasksdelTasks')

  const CreateProject = () => {
    const teamIds = selectedAllAlias.map(alias => alias.id);
    const pocIds = pocList?.map(poc => poc.id);
    const customerIds = customersList.map(customer => customer.id);
    const attachmentFormat =
      uploadFilesListing?.length > 0 &&
      uploadFilesListing?.map(item => ({
        url: item.file_url,
        file_extention: item.file_extension,
        file_name: item.file_title,
      }));

      const getMileDeletedIds = delMilestone?.length > 0 && filterStartingWithInteger(delMilestone)
      const getDeliDeletedIds = delDeliverable?.length > 0 && filterStartingWithInteger(delDeliverable)
      const getTaskDeletedIds = delTasks?.length > 0 && filterStartingWithInteger(delTasks)
    console.log(getMileDeletedIds, 'getMileDeletedIds', getDeliDeletedIds, getTaskDeletedIds);

    const payload = {
      name: projectName,
      project_id: props.type === 'editProject' ? props.projectListing?.id : '',
      tag_titles: tagTitleList ? tagTitleList : [],
      // team_alias: '769',
      team_ids:
        projectTeamsListing?.length > 0
          ? projectTeamsListing.toString(',')
          : '',
      customer_ids: customerIds ? customerIds.toString() : '',
      poc_ids: pocIds ? pocIds.toString(',') : '',
      due_date: invoiceDate ? invoiceDate : '',
      invoice_ids:
        selectedInvoiveForProject?.length > 0
          ? selectedInvoiveForProject.toString(',')
          : '',
      milestones: milestones.length > 0 ? milestones : [],
      deliverables: independentDeliverables ? independentDeliverables : [],
      tasks: independentTasks ? independentTasks : [],
      offering_ids: [],
      attachments: (props.type === 'editProject' && attachmentFormat?.length > 0) ? attachmentFormat : [],
      deleted_milestone_ids: (props.type === 'editProject' && getMileDeletedIds?.length > 0) ? delMilestone : [],
      deleted_deliverable_ids: (props.type === 'editProject' && getDeliDeletedIds?.length > 0) ? getDeliDeletedIds : [],
      deleted_task_ids: (props.type === 'editProject' && getTaskDeletedIds?.length > 0) ? getTaskDeletedIds : [],
    };

    // const payload = {
    //   name: projectName,
    //   team_alias: teamIds.toString(),
    //   customer_ids: customerIds.toString(),
    //   poc_ids: pocIds.toString(),
    //   tag_titles: tagTitleList,
    // };
    // return


    console.log(payload, '========>>>>>payloadpayloadpayloaddddddd');
    // return
    commonApi
      .create_update_projects(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.success) {
            if (props.type === 'editProject') {
              props.handleProjectAddEdit(res.data, 'edit');
            } else {
              props.handleProjectAddEdit(res.data, 'add');
            }
            successMessage(res.message);
            props.onCloseApiHit();
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const get_user_both_invoices = value => {
    commonApi
      .get_user_both_invoices()
      .then(res => {
        if (res.status === 200) {
          setShowInvoices(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  console.log(
    allBuilders,
    originalDataOfMod,
    projectTeamsListing,
    'hhhhhhhhhhhhhhhheheeeeee',
  );

  const handleDeleteFlow = (type, id)=>{
    setShowDeleteModal(true)
    setDeleteIdAndType({
      type: type,
      id: id
    })
    // if(type === 'milestones'){
    // setDelMilestone(previous => [...previous, id])
    // }else if(type === 'task'){
    // setDelTasks(previous => [...previous, id])
    // }else{
    // setDelDeliverable(previous => [...previous, id])
    // }
  }

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}
        renderData={renderProjeectButtons}
        showBlackHeader={true}
        lastDivStyle={{
          borderRadius: '35px',
          background: '#F7F6F2',
          // marginBottom: '20px',
        }}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)' /*linear-gradient(to bottom, black, white)*/,
        }}>
        <div
          className="serach-filter-wrapper create-sessionEvents"
          style={{
            // paddingLeft: '23px',
            paddingTop: '20px',
          }}>
          <div className="create-ticket-form  primary_panel_content">
            <div className="messages-header mb-2 fixed-width-inputs black-head-form-pad-left">
              <div className="event-tabs tabs-style-2 mb-2">
                {' '}
                <ul>
                  <li className="f_700">
                  {(props.type === 'editProject' && enable_edit) ? 
                    'Update Project' : 
                    (props.type === 'editProject' && !enable_edit) ?  
                    'Detail Project' 
                    : 'Create Project'}
                  </li>
                </ul>
              </div>

              <div className="search-tabs-icons">
                <img
                  className="template-img"
                  src={Templates}
                  alt=""
                  onClick={() => {}}
                />
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center  w-100">
              <div
                className="create-ticket-tabs custom-create-ticket-tabs"
                style={{
                  width: 'auto',
                }}>
                <CustomTabs
                  darkmode
                  active={tabType}
                  onSelect={item => setTabType(item)}
                  tabs={[
                    labels.details[selected_lang],
                    labels.team[selected_lang],
                    // labels.TRIGGERS[selected_lang],
                  ]}
                />
              </div>
            </div>

            <div className="create-template refferal-list-wrapper">
              <Scrollbars autoHide>
                <div className="create-project-content fixed-width-inputs black-head-form-pad-left">
                  <Form noValidate validated={validated} onSubmit={handle}>
                    {tabType === labels.details[selected_lang] && (
                      <div style={{marginBottom: '72px'}}>
                        <div className="bg-white rounded-md shadow-md mb-2">
                          <div
                            className="flex justify-between items-center p-3 cursor-pointer"
                            onClick={() =>
                              setShowContactDetails(!showContactDetails)
                            }>
                            <h2 className="text-base font-semibold">
                              Project Detail
                            </h2>
                          </div>
                          <div
                            className={`overflow-hidden transition-all duration-300 ${
                              showContactDetails ? 'max-h-screen' : 'max-h-0'
                            }`}>
                            <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
                            <div className="p-4 pt-0 space-y-2 height-input">
                              {(enable_edit || props.type === 'create_project') ?
                              <>
                              <Form.Control
                                type="text"
                                value={projectName}
                                name="title"
                                pattern="^\S.*$"
                                placeholder={'Project name'}
                                onChange={e => {
                                  setProjectName(e.target.value);
                                }}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                Project name is required.
                              </Form.Control.Feedback>
                              </>
                              :
                              <>
                              <div className="d-flex justify-content-between input-plus-icon-holder">
                                  <Form.Label controlId="validationCustom01">
                                    Project Name
                                  </Form.Label>
                                
                                </div>
                              <div className="d-flex justify-content-between input-plus-icon-holder">
                                  <div className="customer_name flex-1">
                                  <div className="alias_name">
                                    {projectName}
                                    </div>
                                    </div>
                                    </div>
                                    </>
}
                              {(enable_edit || props.type === 'create_project') && (
                                <div
                                  style={{justifyContent: 'right'}}
                                  className="d-flex input-plus-icon-holder">
                                  <span
                                    className={'icon search-tabs-icons as-link'}
                                    style={{
                                      marginRight: '5px',
                                      marginBottom: '4px',
                                    }}>
                                    <i
                                      className="icon-plus"
                                      onClick={() => {
                                        setShowComponent('inviteuser');
                                      }}
                                    />
                                  </span>
                                </div>
                              )}
                              {(enable_edit || props.type === 'create_project') ? (
                                <Form.Control
                                  type="text"
                                  value={customerName}
                                  name="customerName"
                                  onChange={e => {
                                    setCustomerName(e.target.value);
                                    get_customer_list(e.target.value);
                                  }}
                                  placeholder={'Search for customer'}
                                />
                              ) : (
                                <div className="d-flex justify-content-between input-plus-icon-holder">
                                  <Form.Label controlId="validationCustom01">
                                    Customers Name
                                  </Form.Label>
                                </div>
                              )}

                              {customerName && (
                                <ul className="custom-suggestions invoices_suggestion">
                                  {aliasRoles?.length > 0 ? (
                                    aliasRoles?.map(suggestion => (
                                      <li
                                        className="as-link"
                                        key={'suggestion' + suggestion.id}
                                        onClick={() => {
                                          handleCustomer(suggestion);
                                        }}>
                                        {capitalizeFirst(
                                          suggestion.first_name +
                                            ' ' +
                                            suggestion.last_name,
                                        )}
                                      </li>
                                    ))
                                  ) : (
                                    <li className="as-link">
                                      No customer available.
                                    </li>
                                  )}
                                </ul>
                              )}

                              <div className="mb-3">
                                {customersList &&
                                  Object.entries(customersList)?.length > 0 &&
                                  customersList?.map((item, index) => (
                                    <div
                                      class="d-flex align-items-center phases_item mt-3"
                                      key={index}>
                                      <div className="customer_name flex-1">
                                        <div className="alias_name">
                                          {fixName(
                                            item.first_name,
                                            item.last_name,
                                          )}
                                        </div>
                                        {(enable_edit || props.type === 'create_project') && (
                                          <span
                                            class="as-link  cross-icon-field"
                                            onClick={() => {
                                              setDeletedType(
                                                'CUSTOMERS_DELETE',
                                              );
                                              setShowDeleteModel(item);
                                            }}>
                                            <i
                                              class="fas fa-times text-secondary"
                                              style={{fontSize: '1.5rem'}}></i>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              {(enable_edit || props.type === 'create_project') ? (
                                <Form.Control
                                  type="text"
                                  name="pocName"
                                  value={pocName}
                                  onChange={e => {
                                    setPocName(e.target.value);
                                    get_all_team_for_project(e.target.value);
                                  }}
                                  placeholder={'Search for POCs'}
                                />
                              )
                            :
                            <div className="d-flex justify-content-between input-plus-icon-holder">
                                  <Form.Label controlId="validationCustom01">
                                    POCs Name
                                  </Form.Label>
                                </div>
                                }

                              {pocName && (
                                <ul className="custom-suggestions invoices_suggestion">
                                  {pocAliase?.length > 0 ? (
                                    pocAliase?.map(suggestion => (
                                      <li
                                        className="as-link"
                                        key={'suggestion' + suggestion.id}
                                        onClick={() => {
                                          handlePoc(suggestion);
                                        }}>
                                        {capitalizeFirst(
                                          suggestion.first_name +
                                            ' ' +
                                            suggestion.last_name,
                                        )}
                                      </li>
                                    ))
                                  ) : (
                                    <li className="as-link">
                                      No pocs available.
                                    </li>
                                  )}
                                </ul>
                              )}

                              <div className="mb-3">
                                {pocList &&
                                  Object.entries(pocList)?.length > 0 &&
                                  pocList.map((item, index) => (
                                    <div
                                      class="d-flex align-items-center phases_item mt-3"
                                      key={index}>
                                      <div className="customer_name flex-1">
                                        <div className="alias_name">
                                          {fixName(
                                            item.first_name,
                                            item.last_name,
                                          )}
                                        </div>
                                        {(enable_edit || props.type === 'create_project') && (
                                          <span
                                            class="as-link  cross-icon-field"
                                            onClick={() => {
                                              setDeletedType('POC_NAME_DELETE');
                                              setShowDeleteModel(item);
                                            }}>
                                            <i
                                              class="fas fa-times text-secondary"
                                              style={{fontSize: '1.5rem'}}></i>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </div>

                              <div className="d-flex justify-content-between input-plus-icon-holder">
                                <p>Associate Invoice</p>
                                <span
                                  className="as-link"
                                  style={{color: '#52bd41'}}
                                  onClick={() => {
                                    if(enable_edit || props.type === 'create_project'){
                                      if(selectedInvoiveForProject?.length > 0){
                                        setShowComponent('invoice');
                                        setInvoiceData({
                                          type: '',
                                          item: '',
                                        });
                                      }else{
                                        errorMessage('No invoice available')
                                      }
                                  }
                                  }}>
                                  {selectedInvoiveForProject?.length > 0
                                    ? selectedInvoiveForProject.length
                                    : 'Find'}{' '}
                                  Invoice
                                </span>
                              </div>
                              <div className="d-flex justify-content-between input-plus-icon-holder">
                                <span>Due Date</span>

                                <div onClick={handleClickD}>
                                  {invoiceDate === '' ? (
                                    <span className="template-text">
                                      {' '}
                                      <i className="fa-solid fa-calendar-days" />
                                    </span>
                                  ) : (
                                    <span
                                      style={{color: '#52bd41'}}
                                      className="template-text as-link">
                                      {Moment(invoiceDate)
                                        .format('MMM DD, YYYY')
                                        .toString()}
                                    </span>
                                  )}
                                </div>

                                <div className="invoice_calender-wrapper">
                                  {isOpen && (
                                    <Calender
                                      minDate={new Date()}
                                      setSelectedDate={handleChange}
                                      selectedDate={
                                        invoiceDate ? new Date(invoiceDate) : ''
                                      }
                                      showDate={true}
                                      showTime={false}
                                      type="invoice"
                                      isOpenCalendar={isOpen}
                                      setIsOpenCalendar={setIsOpen}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-center  w-100">
                          <div
                            className="create-ticket-tabs custom-create-ticket-tabs"
                            style={{
                              width: 'auto',
                            }}>
                            <CustomTabs
                              darkmode
                              active={subTabType}
                              onSelect={item => setSubTabsType(item)}
                              tabs={[
                                labels.milestone[selected_lang],
                                labels.deliverable[selected_lang],
                                labels.task[selected_lang],
                              ]}
                            />
                          </div>
                        </div>

                        <CreateProjectMilestones
                          name={subTabType}
                          filterType={filterType}
                          setFilterType={setFilterType}
                          filterValue={filterValue}
                          setFilterValue={setFilterValue}
                          milestones={milestones}
                          setMilestones={setMilestones}
                          independentDeliverables={independentDeliverables}
                          setIndependentDeliverables={
                            setIndependentDeliverables
                          }
                          independentTasks={independentTasks}
                          setIndependentTasks={setIndependentTasks}
                          data={props.projectListing}
                          handleTeam={handleTeam}
                          type={milestoneType}
                          setType={setMilestoneType}
                          setDeliverableType={setDeliverableType}
                          deliverableType={deliverableType}
                          manageInvoice={manageInvoice}
                          handleDescription={handleDescription}
                          uploadFilesListing={uploadFilesListing}
                          setUploadFilesListing={setUploadFilesListing}
                          showAssociateListing={(props.type === 'editProject') ? props.type : ''}
                          enable_edit={enable_edit ? enable_edit : false}
                          handleDeleteFlow={handleDeleteFlow}
                          handleTask={props.handleTask}
                          newTaskData={props.newTaskData ? props.newTaskData : ''}
                        />

                        {/* <div className="fieldset">
                          <Form.Label controlid="validationCustom01">
                            Project Name
                          </Form.Label>
                          <Form.Control
                            name="title"
                            value={projectName}
                            pattern="^\S.*$"
                            required
                            placeholder="Add Project name"
                            onChange={e => {
                              setProjectName(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Project name is required.
                          </Form.Control.Feedback>
                        </div>

                        <div className="d-flex">
                          <div
                            className="fieldset flex-1"
                            style={{margin: '0px'}}>
                            <div className="d-flex justify-content-between input-plus-icon-holder">
                              <Form.Label controlid="validationCustom01">
                                Customer
                              </Form.Label>
                              <span
                                className={'icon search-tabs-icons as-link'}
                                style={{marginRight: '5px',marginBottom:'4px'}}>
                                <i
                                  className="icon-plus"
                                  onClick={() => {
                                   setShowComponent('inviteuser')
                                  }}
                                />
                              </span>
                            </div>

                            <div className="input-plus-icon-holder">
                              <Form.Control
                                name="phase"
                                value={customerName}
                                placeholder="Customer Name"
                                onChange={e => {
                                  setCustomerName(e.target.value);
                                  get_customer_list(e.target.value);
                                }}
                              />
                            </div>
                            <Form.Control.Feedback type="invalid">
                              Customer name is required.
                            </Form.Control.Feedback>
                          </div>
                        </div>

                        {customerName && (
                          <ul className="custom-suggestions invoices_suggestion">
                            {aliasRoles?.length > 0 ? (
                             aliasRoles?.map(suggestion => (
                              <li
                                className="as-link"
                                key={'suggestion' + suggestion.id}
                                onClick={() => {
                                  handleCustomer(suggestion);
                                }}>
                                {capitalizeFirst(
                                  suggestion.first_name +
                                    ' ' +
                                    suggestion.last_name,
                                )}
                              </li>
                            )))
                            :
                              <li className="as-link">
                                No customer available.
                              </li>
                              }
                          </ul>
                        )}

                        <div className="mb-3">
                          {customersList && Object.entries(customersList)?.length > 0 &&
                            customersList?.map((item, index) => (
                              <div
                                class="d-flex align-items-center phases_item mt-3"
                                key={index}>
                                <div className="customer_name flex-1">
                                  <div className="alias_name">
                                    {fixName(item.first_name, item.last_name)}
                                  </div>

                                  <span
                                    class="as-link  cross-icon-field"
                                    onClick={() => {
                                      setDeletedType('CUSTOMERS_DELETE');
                                      setShowDeleteModel(item);
                                    }}>
                                    <i
                                      class="fas fa-times text-secondary"
                                      style={{fontSize: '1.5rem'}}></i>
                                  </span>
                                </div>
                              </div>
                            ))}
                        </div>

                        <div className="d-flex">
                          <div
                            className="fieldset flex-1"
                            style={{margin: '0px'}}>
                            <div className="d-flex justify-content-between input-plus-icon-holder">
                              <Form.Label controlid="validationCustom01">
                                POC Name
                              </Form.Label>
                            </div>

                            <div className="input-plus-icon-holder">
                              <Form.Control
                                name="phase"
                                value={pocName}
                                placeholder="Enter Name"
                                onChange={e => {
                                  setPocName(e.target.value);
                                  get_all_team_for_project(e.target.value);
                                }}
                              />
                            </div>
                            <Form.Control.Feedback type="invalid">
                              Poc name is required.
                            </Form.Control.Feedback>
                          </div>
                        </div>

                        {pocName && (
                          <ul className="custom-suggestions invoices_suggestion">
                            {pocAliase?.length > 0 ? (
                            pocAliase?.map(suggestion => (
                              <li
                                className="as-link"
                                key={'suggestion' + suggestion.id}
                                onClick={() => {
                                  handlePoc(suggestion);
                                }}>
                                {capitalizeFirst(
                                  suggestion.first_name +
                                    ' ' +
                                    suggestion.last_name,
                                )}
                              </li>
                            )))
                            :
                            <li className="as-link">No pocs available.</li>
                          }
                          </ul>
                        )}

                        <div className="mb-3">
                          {pocList && Object.entries(pocList)?.length > 0 &&
                            pocList.map((item, index) => (
                              <div
                                class="d-flex align-items-center phases_item mt-3"
                                key={index}>
                                <div className="customer_name flex-1">
                                  <div className="alias_name">
                                    {fixName(item.first_name, item.last_name)}
                                  </div>

                                  <span
                                    class="as-link  cross-icon-field"
                                    onClick={() => {
                                      setDeletedType('POC_NAME_DELETE');
                                      setShowDeleteModel(item);
                                    }}>
                                    <i
                                      class="fas fa-times text-secondary"
                                      style={{fontSize: '1.5rem'}}></i>
                                  </span>
                                </div>
                              </div>
                            ))}
                        </div> */}

                        <Button
                          className="btn-dark"
                          type="submit"
                          style={{display: 'none'}}
                          ref={buttonRef}></Button>
                      </div>
                    )}

                    {tabType === labels.team[selected_lang] && (
                      <>
                        {/* <div className="invite-page-search">
                          <div className="search-form mt-4">
                            <Form.Label
                              controlid="validationCustom01"
                              style={{
                                fontSize: '15px',
                                color: '#000000',
                                fontWeight: 500,
                              }}>
                              Roles
                            </Form.Label>
                            <Form.Group className="search-input">
                              <Form.Control
                                type="text"
                                value={showSearchRoles}
                                onChange={e => {
                                  setShowSearchRoles(e.target.value);
                                  get_company_users_role(e.target.value);
                                }}
                                placeholder={'Search role'}
                              />
                            </Form.Group>
                          </div>
                        </div>

                        {showSearchRoles && (
                          <ul className="custom-suggestions invoices_suggestion">
                            {listRoles?.length > 0 ? (
                              listRoles.map(suggestion => (
                                <li
                                  className="as-link"
                                  key={'suggestion' + suggestion.id}
                                  onClick={() => {
                                    handleAddInArray(suggestion);
                                  }}>
                                  {capitalizeFirst(suggestion.role_alias)}
                                </li>
                              ))
                            ) : (
                              <li className="as-link">No roles available</li>
                            )}
                          </ul>
                        )}
                        {selectedAllAlias &&
                        Object.entries(selectedAllAlias)?.length > 0 ? (
                          selectedAllAlias?.map((item, index) => (
                            <div
                              class="d-flex align-items-center gap-2 mt-3"
                              key={index}>
                              <div className="project_alias_role">
                                <div className="alias_name">
                                  {capitalizeFirst(item.role_alias)}
                                </div>
                              </div>

                     
                              <span
                                class="as-link  cross-icon-field"
                                onClick={() => {
                                  setDeletedType('ROLES_DELETE');
                                  setShowDeleteModel(item);
                                }}>
                                <i
                                  class="fas fa-times text-secondary"
                                  style={{fontSize: '1.5rem'}}></i>
                              </span>
                            </div>
                          ))
                        ) : (
                          <></>
                        )} */}
                        {inviteTeamLoader && (
                          <div className="invite_loader_hieght">
                            <LoaderSpinner />
                          </div>
                        )}
                        <div
                          className=" fixed-width-inputs black-head-form-pad-left fix-width-forms"
                          style={{paddingBottom: '40px'}}>
                          <Invite
                            type={'refer'}
                            // hide={true}
                            // component={'invoice'}

                            moderator={projectTeamsListing}
                            setModerator={setProjectTeamsListing}
                            selectedModData={allBuilders}
                            setSelectedModData={setAllBuilders}
                            originalDataOfMod={originalDataOfMod}
                            setOriginalDataOfMod={setOriginalDataOfMod}
                            onClose={() => {
                              if (projectTeamsListing.length > 0) {
                                setUsers(projectTeamsListing);
                              }
                            }}
                            onClick={() => {
                              setShowComponent('inviteTeamUser');
                              setTimeout(() => {
                                if (contact.length === 0 && inviteQry !== '') {
                                  setInviteFollowers(followersList);
                                  setInviteQry('');
                                }
                              }, 1000);
                            }}
                            contact={contact}
                            setContact={setContact}
                            forApiHit={showComponent}
                            handleCircle={handleCircle}
                            setShowComponent={setShowComponent}
                            hitApiAddedUser={hitApiAddedUser}
                            setOriginalFolowers={setFollowers}
                            showLoader={inviteTeamLoader}
                            setshowLoader={setTeamInviteLoader}
                            followers={inviteFollowers}
                            setFollowers={setInviteFollowers}
                            setFollowersList={setFollowersList}
                            followersList={followersList}
                            setSearchQry={setInviteQry}
                            searchQry={inviteQry}
                          />
                        </div>
                      </>
                    )}
                    {tabType === labels.TRIGGERS[selected_lang] && (
                      <>
                        <div className="no-results-wrapper no-appointments my-5">
                          <div className="no-results-content">
                            <h4 className="noRecord-desc">Under Development</h4>
                          </div>
                        </div>
                        {/* <CustomDropdown
                          setDropdownId={setDropdownId}
                          dropdownId={dropdownId}
                          DropDownValues={dropdownValues}
                          itemName={['title']}
                          className={'mb-3'}
                        />

                        <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mb-4">
                          <CustomSlidingButtons
                            items={filterOption}
                            activeButtons={actionType}
                            onItemClick={e => {
                              handleType(e);
                            }}
                            showItems={3}
                            value={actionTypeValue}
                          />
                        </div>
                        <div className="messages-header mb-2 fix-width-forms">
                          <div className="event-tabs tabs-style-2 mb-0">
                            <ul>
                              <li>
                                <Button
                                  className={
                                    subTabType === 'execute' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    if (subTabType !== 'execute') {
                                      setSubTabsType('execute');
                                    }
                                  }}>
                                  {labels.EXECUTE[selected_lang]}
                                </Button>
                              </li>

                              <li>
                                <Button
                                  className={
                                    subTabType === 'update' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    if (subTabType !== 'update') {
                                      setSubTabsType('update');
                                    }
                                  }}>
                                  {labels.UPDATE[selected_lang]}
                                </Button>
                              </li>

                              <li>
                                <Button
                                  className={
                                    subTabType === 'notify' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    if (subTabType !== 'notify') {
                                      setSubTabsType('notify');
                                    }
                                  }}>
                                  {labels.NOTIFY[selected_lang]}
                                </Button>
                              </li>
                            </ul>
                          </div>

                          <div className="search-tabs-icons">
                            <img
                              className="template-img as-link"
                              src={Templates}
                              alt=""
                              onClick={() => {}}
                            />
                            <span
                              onClick={() => {
                                setThreeDots(!threeDots);
                              }}
                              className={` mt-0  ${
                                threeDots ? 'icon active' : 'icon'
                              }`}>
                              <i className="fas fa-ellipsis-vertical" />
                            </span>
                          </div>
                        </div>
                        {subTabType === 'execute' && <></>}

                        {subTabType === 'update' && <></>}

                        {subTabType === 'notify' && <></>} */}
                      </>
                    )}
                  </Form>

                  <Button
                    style={{display: 'none', marginRight: '0px'}}
                    ref={buttonRef}
                    className="btn-dark m-0"
                    onClick={() => {
                      handleClick();
                    }}>
                    {(props.type === 'editProject') ? 'Update Project' : 'Create Project'}
                  </Button>
                </div>
              </Scrollbars>
            </div>
          </div>
          {tabType === labels.details[selected_lang] && ((props.type === 'editProject' && enable_edit) || (props.type === 'create_project' && !enable_edit)) && (
            <div className="filter-btn-wrapper fix-width-forms mt-2">
              <div
                className="invite-btn text-center bg_transparent pb-2 pt-2"
                style={{bottom: 0}}>
                <Button
                  style={{marginRight: '0px'}}
                  className="btn-dark mb-0 mr-0"
                  type="submit"
                  onClick={() => {
                    buttonRef.current.click();
                  }}>
                  {/* {props.editAction ? 'Update Task' : } */}
                  {(props.type === 'editProject') ? 'Update Project' : 'Create Project'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </SlidingPanel>

      {showDeleteModel && (
        <ConfirmationModel
          closeModel={() => {
            setShowDeleteModel('');
          }}
          discription={`Are you sure you want to delete this 
            ${
              deletedType === 'CUSTOMERS_DELETE'
                ? 'customer'
                : deletedType === 'POC_NAME_DELETE'
                ? 'poc'
                : 'roles'
            } `}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            removeName(showDeleteModel, deletedType);
          }}
        />
      )}

      {showDeleteModal && (
        <ConfirmationModel
          closeModel={() => {
            setShowDeleteModal(false);
          }}
          discription={`Are you sure you want to delete this 
            ${
              deleteIdAndType?.type === 'milestones'
                ? 'Milestone'
                : deleteIdAndType?.type === 'deliverables'
                ? 'Deliverable'
                : 'Task'
            } `}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            deleteProjectItem2(
              deleteIdAndType?.type,
              deleteIdAndType?.id,
              setMilestones,
              setIndependentTasks,
              setIndependentDeliverables,
              milestones,
              independentDeliverables,
              independentTasks,
              setDelMilestone,
              setDelDeliverable,
              setDelTasks
            );
            setShowDeleteModal(false);
          }}
        />
      )}

      {showModal && (
        <IconModal
          closeModel={() => {
            setShowModal(false);
          }}
          show={showModal}
          tagData={tagTitleList}
          setTagData={setTagTitleList}
        />
      )}

      {showComponent === 'inviteuser' && (
        <SendInvitation
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

      {showComponent === 'CircleList' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}

      {showSecondaryComponent === 'CircleList' && (
        <CircleListBuilders
          onClose={() => {
            setShowSecondaryComponent('');
          }}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}

      {showComponent === 'inviteTeamUser' && (
        <SendInvitation
          setContact={setContact}
          setHitApiAddedUser={setHitApiAddedUser}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

      {showComponent === 'invoice' && (
        <InvoiceListingForProject
          onClose={() => {
            setShowComponent('');
            handleSelectInvoice();
          }}
          selectedInvoice={
            invoiceData.type === 'milestones'
              ? selectedInvoiveForMilestone
              : invoiceData.type === 'deliverables'
              ? selectedInvoiveForDeliverable
              : selectedInvoiveForProject
          }
          setSelectedInvoice={
            invoiceData.type === 'milestones'
              ? setSelectedInvoiveForMilestone
              : invoiceData.type === 'deliverables'
              ? setSelectedInvoiveForDeliverable
              : setSelectedInvoiveForProject
          }
          templatesList={showInvoices}
        />
      )}

      {showComponent === 'teams' && (
        <SlidingPanel
          marginTop={false}
          renderData={() => renderProjeectButtons('team')}
          showBlackHeader={true}
          headerPadding={'24px 20px'}
          lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
          firstDivStyle={{
            padding: 0,
            background:
              'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
          }}
          hidePanel={() => {
            if (teamData.type === 'milestones') {
              if (users.length !== builders.length) {
                setTeamBuildes(users);
              }
            } else if (teamData.type === 'deliverables') {
              if (users.length !== builders.length) {
                setDeliverableTeam(users);
              }
            } else if (teamData.type === 'task') {
              if (users.length !== builders.length) {
                settaskTeam(users);
              }
            }
            if (inviteQry !== '') {
              setInviteFollowers(followersList);
              setInviteQry('');
            }
            setFollowers([]);
            setShowComponent('');
          }}>
          <div className="primary_panel_content mt-3">
            <div className="refferal-list-wrapper ">
              {inviteLoader && (
                <div className="invite_loader_hieght">
                  <LoaderSpinner />
                </div>
              )}
              <Scrollbars
                renderTrackHorizontal={props => (
                  <div {...props} style={{display: 'none'}} />
                )}
                // Hide the vertical scrollbar
                renderTrackVertical={props => (
                  <div {...props} style={{display: 'none'}} />
                )}>
                <div
                  className=" fixed-width-inputs black-head-form-pad-left fix-width-forms"
                  style={{paddingBottom: '40px'}}>
                  <Invite
                    type={'refer'}
                    // hide={true}
                    component={'project'}
                    moderator={
                      teamData.type === 'milestones'
                        ? teamBuildes
                        : teamData.type === 'deliverables'
                        ? deliverableTeam
                        : taskTeam
                    }
                    setModerator={
                      teamData.type === 'milestones'
                        ? setTeamBuildes
                        : teamData.type === 'deliverables'
                        ? setDeliverableTeam
                        : settaskTeam
                    }
                    selectedModData={allTeams}
                    setSelectedModData={setAllTeams}
                    originalDataOfMod={originalTeams}
                    setOriginalDataOfMod={setOriginalTeams}
                    onClose={() => {
                      if (teamData.type === 'milestones') {
                        if (teamBuildes.length > 0) {
                          setUsers(teamBuildes);
                        }
                      } else if (teamData.type === 'deliverables') {
                        if (deliverableTeam.length > 0) {
                          setUsers(deliverableTeam);
                        }
                      } else {
                        if (taskTeam.length > 0) {
                          setUsers(taskTeam);
                        }
                      }
                    }}
                    onClick={() => {
                      setShowComponent('inviteTeamUser');
                      setTimeout(() => {
                        if (contact.length === 0 && inviteQry !== '') {
                          setInviteFollowers(followersList);
                          setInviteQry('');
                        }
                      }, 1000);
                    }}
                    contact={contact}
                    setContact={setContact}
                    forApiHit={showComponent}
                    handleCircle={handleCircle}
                    setShowComponent={setShowComponent}
                    hitApiAddedUser={hitApiAddedUser}
                    setOriginalFolowers={setFollowers}
                    showLoader={inviteLoader}
                    setshowLoader={setInviteLoader}
                    followers={inviteFollowers}
                    setFollowers={setInviteFollowers}
                    setFollowersList={setFollowersList}
                    followersList={followersList}
                    setSearchQry={setInviteQry}
                    searchQry={inviteQry}
                  />
                </div>
              </Scrollbars>
              {inviteFollowers.length > 0 && (
                <div className="filter-btn-wrapper pt-2 fixed-width-inputs black-head-form-pad-left fix-width-forms bg_transparent bottom-0">
                  <div className="invite-btn text-center">
                    <Button
                      className="btn-dark"
                      type="button"
                      onClick={() => {
                        if (teamData.type === 'milestones') {
                          if (teamBuildes.length > 0) {
                            setUsers(teamBuildes);
                          }
                        } else if (teamData.type === 'deliverables') {
                          if (deliverableTeam.length > 0) {
                            setUsers(deliverableTeam);
                          }
                        } else {
                          if (taskTeam.length > 0) {
                            setUsers(taskTeam);
                          }
                        }
                        setFollowers([]);
                        setShowComponent('');
                        handleSelectUser();

                        // comparisonModalRef.current.checkStockAvailabilty();
                      }}>
                      Select
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </SlidingPanel>
      )}
    </>
  );
};

export default CreateProject;

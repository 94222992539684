import React, {useState, useEffect, useRef} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import {errorMessage} from '../../Utils/ToastMessages';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import CropImage from '../Cropper/CropImage';
import CustomModal from '../CustomModal';
import commonApi from '../../Services/CommonService';
import {successMessage} from '../../Utils/ToastMessages';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import CreateTag from '../Tags/CreateTag';
import {RoiLogo} from '../../Constants/Images';
import NorecordFound from '../NoRecord/NorecordFound';
import {NoTagsFound} from '../../Utils/NoRecordConstant';
import LoaderSpinner from '../Modals/LoaderSpinner';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import {checkPermissionsTags} from '../../Utils/commonUtils';
import {fixName} from '../../Utils/commonUtils';
import {getItem} from '../../Utils/LocalStorage';

const ProfileTags = props => {
  const [showComponent, setShowComponent] = useState('');
  const [deletedId, setDeletedId] = useState('');

  const [tagsList, setTagsList] = useState([]);
  const [showLoader, setshowLoader] = useState(true);
  const [showTag, setShowTag] = useState(false);

  const [tagItem, setTagItem] = useState({});
  const [userPermesions, setUserPermesions] = useState({});


  console.log(userPermesions, 'userPermesionsuserPermesionsuserPermesions')
  useEffect(() => {
    get_tags_permission();
    get_company_tag();
  }, []);

  const get_company_tag = () => {
    commonApi
      .get_company_tag()
      .then(res => {
        if (res.status === 200) {
         
            setTagsList(res.tags);
          setshowLoader(false);
          
          
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const get_tags_permission = () => {
    commonApi
      .get_tags_permission()
      .then(res => {
        if (res.status === 200) {
          setUserPermesions(res);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const deleteFromList = (array, item) => {
    const index = array.findIndex(tag => tag.id === item.id);
    if (index !== -1) {
      props.userTagList.splice(index, 1);
    }
  };

  console.log(tagsList, 'tagsListtagsListtagsListtagsList')
  const handleDelete = item => {
    const paylaod = {
      tag_id: item.id,
    };

    commonApi
      .delete_company_tag(paylaod)
      .then(res => {
        if (res.status === 200 && res.success) {
          setShowComponent('');
          successMessage(res.message);
          setTagsList(previous =>
            previous.filter(miniitem => miniitem.id !== item.id),
          );
          deleteFromList(props.userTagList, item);
          setShowComponent('')
          setTagItem({});
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const updateAssignDetails = (id, userId) => {
    setTagsList(prevTagsList => {
      return prevTagsList.map(item => {
        if (item.id === id) {
          // Check if the user ID exists in the assign_details array
          const userIndex = item.assign_details.indexOf(userId);
          if (userIndex > -1) {
            // User ID exists, remove it
            item.assign_details.splice(userIndex, 1);
          } else {
            // User ID does not exist, add it
            item.assign_details.push(userId);
          }
        }
        return item;
      });
    });
  };
  const assign_company_tags = item => {
    const paylaod = {
      company_tag_id: item.id,
      user_id: props.user_id,
    };

    commonApi
      .assign_company_tags(paylaod)
      .then(res => {
        if (res.status === 200 && res.success) {
          updateAssignDetails(item.id, props.user_id);
          successMessage(res.message);
          updateUserTagList(props.userTagList, item);
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const updateUserTagList = (userTagList, item) => {
    // Check if the id exists in the array
    const index = userTagList.findIndex(tag => tag.id === item.id);

    if (index === -1) {
      // id not found, push a new object to the array (example object added)
      userTagList.push({
        id: item.id,
        company_id: item.company_id,
        title: item.title,
        icon: item.icon,
        description: item.description,
      });
    } else {
      // id found, remove it from the array
      userTagList.splice(index, 1);
    }

    return userTagList;
  };

  console.log(props.permession, 'props.permession');

  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
        {props.permession && tagsList.length > 0 ? (
          <i
            className="icon-plus as-link"
            onClick={() => {
              setShowComponent('create_tag');
              // props.handleCommand('', 'create_tag');
            }}
          />
        ) :  
          <img src={RoiLogo} alt="" />
      //   :
      //  <div style={{height:'22px'}}></div>
          
        }
      </>
    );
  };
  const renderProfileTagsButtonsAddContact = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
        {userPermesions && tagsList.length > 0 ? (
          <i
            className="icon-plus as-link"
            onClick={() => {
              setShowComponent('create_tag');
              // props.handleCommand('', 'create_tag');
            }}
          />
        ) :  
          <img src={RoiLogo} alt="" />
      //   :
      //  <div style={{height:'22px'}}></div>
          
        }
      </>
    );
  };

  const editTagHandle = newItem => {
    const index = props.userTagList.findIndex(tag => tag.id === newItem.id);

    if (index !== -1) {
      // Remove the item from the props.userTagList
      props.userTagList.splice(index, 1);

      // Insert the new item at the same index
      props.userTagList.splice(index, 0, newItem);
    }
  };
  console.log(props.userTagList, 'props.userTagList.props.userTagList.')

  const handleTagClick = (item) => {
    // Check if the tag already exists in the list
    const isTagExists = props.tagTitleList.some((tag) => tag.id === item.id);
  
    // Toggle logic: If exists, remove it. If not, add it.
    const updatedTags = isTagExists 
      ? props.tagTitleList.filter((tag) => tag.id !== item.id) // Remove if exists
      : [...props.tagTitleList, item]; // Add if not exists
  
    props.setTagTitleList(updatedTags); // Update only the parent state
    setShowTag(!showTag); // Toggle UI state (if needed)
  };
  
  

  // useEffect(()=>{
  //   if(props.type){
  //     if(tagsList.length > 0){
  //   props.setTagTitleList(tagsList?.length > 0 ? tagsList : [])
  //     }
  //   }
  // },[tagsList])

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}
        renderData={()=>props.type ? renderProfileTagsButtonsAddContact() : renderProfileTagsButtons()}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}>
        <div className="serach-filter-wrapper">
          {showLoader && <LoaderSpinner className={'curved-spinner-bg'} />}

          <div className="d-flex justify-content-between input-plus-icon-holder fix-width-forms mb-3 mt-3 ml-5">
            {tagsList.length > 0 && <h2>Available Tags</h2>}
          </div>

          {tagsList.length > 0 && getItem('id') !== props.user_id ? (
            <div className="tags_heading mb-4">
              Please tap on tags to select/unselect for{' '}
              {fixName(
                props?.userInfo?.data?.first_name,
                props?.userInfo?.data?.last_name,
              )}
            </div>
          ) : (tagsList.length > 0 &&
            <div className="tags_heading mb-4">
              Please tap on tags to select/unselect.
            </div>
          )}

          <Scrollbars autoHide>
            <div className="flex flex-wrap profile-tags-card-holder">
              {tagsList.length > 0 &&
                tagsList.map(
                  (item, index) =>
                    checkPermissionsTags(
                      item.role_titles,
                      userPermesions?.alias,
                      'read',
                      userPermesions?.tag_permission?.show_tag,
                    ) === true && (
                      <div
                        key={index}
                        className="fix-width-forms"
                        style={{
                          paddingRight: '0px',
                        }}>
                        <div
                          className="tag_card"
                          style={{
                            borderColor:
                              (item.assign_details.includes(props.user_id) || (props.tagTitleList && props.tagTitleList?.length > 0 && props.tagTitleList.some(tag => tag.id === item.id))) &&
                              '#00bf4d',
                          }}>
                          <div
                            className={`d-flex align-items-center gap-2 ${
                              props.permession ? 'as-link' : (props.type === 'adContact') ? 'as-link' :''
                            }`}
                            onClick={() => {
                              if (props.permession) {
                                assign_company_tags(item);
                              }else{
                                if(props.type === 'adContact'){
                                  handleTagClick(item)
                                }
                              }
                            }}>
                            {item.icon !== '' && (
                              <div className="tag_icon">
                                <img src={item.icon} alt="Tag Icon" />
                              </div>
                            )}
                            <div className="multi-line-ellipsis">
                              {item.title}
                            </div>
                          </div>
                          {checkPermissionsTags(
                            item.role_titles,
                            userPermesions?.alias,
                            'update',
                            userPermesions?.tag_permission?.create_tag,
                          ) === true && (
                            <div className="d-flex justify-content-between align-items-center gap-2">
                              <span
                                className="as-link"
                                onClick={() => {
                                  setTagItem(item);
                                  setShowComponent('create_tag');
                                }}>
                                <EditPencilSvg fill={'#52bd41'} />
                              </span>

                              {/* <span
                                className="poc_delete as-link"
                                onClick={() => {
                                  setDeletedId(item);
                                  setShowComponent('show_delete_model');
                                }}>
                                <i className="fa-solid fa-trash-can" />
                              </span> */}
                            </div>
                          )}
                        </div>
                      </div>
                    ),
                )}
            </div>
            {tagsList.length === 0 && (
              <div style={{paddingTop: '20px'}}>
                <NorecordFound
                  noRecordData={NoTagsFound}
                  buttonPermesion={props.permession}
                  ButtonAction={() => {
                    setShowComponent('create_tag');
                  }}
                />
              </div>
            )}
          </Scrollbars>
        </div>
      </SlidingPanel>

      {showComponent === 'create_tag' && (
        <CreateTag
          onClose={() => {
            setTagItem({});
            setShowComponent('');
          }}
          editTag={tagItem}
          setTagsList={setTagsList}
          tagsList={tagsList}
          editTagHandle={editTagHandle}
          userPermesions={userPermesions}
          handleDelete={handleDelete}
          type={props.type ? props.type : ''}
        />
      )}
      {showComponent === 'show_delete_model' && deletedId && (
        <ConfirmationModel
          closeModel={() => {
            setShowComponent('');
          }}
          discription={'Are you sure you want to delete this tag?'}
          buttonOne={'Yes'}
          buttonTwo={'No'}
          ButtonOneAction={() => {
            handleDelete(deletedId);
          }}
        />
      )}
    </>
  );
};

export default ProfileTags;

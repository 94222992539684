import React, {useState} from 'react';
import {
  BlackProfileNotes,
  EditPencilYellow,
  ProfileNotes,
  project_management,
  ShareablesBlackIcon,
} from '../../Constants/Images';
import {checkPermissionsOfCreate, iconButtons} from '../../Utils/commonUtils';
import {useSelector} from 'react-redux';

const FloatingMenu = props => {
  const menuPermesions = useSelector(state => state.menuPermesionList);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const containerStyle = {
    position: 'sticky',
    bottom: '15px',
    right: props.type !== 'own_profile' && '40px',
    left: props.type === 'own_profile' && '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: isOpen ? '16px' : '0px',
   
  };

  const boxStyle = {
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    transition: 'transform 0.3s ease, opacity 0.3s ease',
    transform: isOpen ? 'scale(1)' : 'scale(0.9)',
    opacity: isOpen ? 1 : 0,
    pointerEvents: isOpen ? 'all' : 'none',
    display: isOpen ? 'flex' : 'none',
  };

  const iconStyle = {
    width: '24px',
    height: '24px',
    color: 'black',
  };

  const buttonStyle = {
    backgroundColor: isOpen ? '#5ABD42' : 'white',
    width: '47px',
    height: '47px',
    borderRadius: '50%',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    // transform: isOpen ? 'rotate(45deg)' : 'rotate(0deg)',
  };

  const newData = checkPermissionsOfCreate(
    iconButtons,
    menuPermesions,
    props?.profile?.pipeline_permission,
  );

  console.log(newData, 'newData');

  return (
    <div style={containerStyle}>
      {/* Menu Box */}
      <div style={boxStyle}>
        {/* Calendar Icon */}
        {/* {props.profile.data.exist_in_build !== 'no' &&
          props.profile.schedules && (
           
          )} */}

        {newData?.length > 0 &&
          newData.map(item => {
            return (
              <div className="flaoting_menu_items" key={item.key}>
                <span
                  onClick={() => props.handle(item.key)}
                  style={item.style}
                  className="as-link floating_menu_icons">
                  {item.icon}
                </span>
              </div>
            );
          })}
      </div>

      {/* Toggle Button */}
      <button style={buttonStyle} onClick={toggleMenu}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          style={{
            width: '22px',
            height: '22px',
            color: isOpen ? 'white' : 'black',
          }}>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M12 4v16m8-8H4"
          />
        </svg>
      </button>
    </div>
  );
};

export default FloatingMenu;

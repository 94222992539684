import React, {useState} from 'react';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import Calender from '../FeedBack/Calender';
import moment from 'moment';
import {filterProjectData, getMaxDateFromToday, getNextDayDate, isFirstCharDigit} from '../../Utils/commonUtils';
import {Form} from 'react-bootstrap';
import Input from '../Input/Input';
import {errorMessage} from '../../Utils/ToastMessages';

const MilestoneListing = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  listing,
  setIsEditing,
  setTitle,
  type,
  searchListing,
  handleTeam,
  manageStartDate,
  manageEndDate,
  manageInvoice,
  otherListing,
  handleDescription,
  showAssociateListing,
  enable_edit,
  handleDelete,
  filterType,
  handleStatus
}) => {
  const [showDetail, setShowDetail] = useState(false);
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [closeCalendar, setCloseCalendar] = useState(false);
  const formatDate = date => moment(date).format('YYYY-MM-DD');

  const today = new Date();
  console.log(isOpen, '=====>>>>>otherListingotherListingotherListing');

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  const decesionStartDate = item => {
    console.log(item, 'itemitemitemitem');
    if (item.delivery_start_date) {
      return new Date(item.delivery_start_date);
    }

    if (item.milestone_start_date) {
      return new Date(item.milestone_start_date);
    }

    if (item.task_start_date) {
      return new Date(item.task_start_date);
    }
  };
  const decesionEndDate = item => {
    if (item.task_due_date) {
      return new Date(item.task_due_date);
    }
    if (item.delivery_end_date) {
      return new Date(item.delivery_end_date);
    }
    if (item.milestone_end_date) {
      return new Date(item.milestone_end_date);
    }
  };

  const showStatus = item => {
    if (type === 'milestones') {
      if(item?.milestone_status === 'completed'){
        return 'Mark as Complete'
      }else{
      return 'Mark as Pending'
      }
    }

    if (type === 'deliverables') {
      if(item?.delivery_status === 'completed'){
        return 'Mark as Complete'
      }else{
      return 'Mark as Pending'
      }
    }

    if (type === 'task') {
      if(item?.task_status === 'completed'){
        return 'Mark as Complete'
      }else{
      return 'Mark as Pending'
      }
    }
   
  };

  const filterData =filterProjectData(listing,filterType,type)
  console.log(filterData, '===>>>filterDatafilterData')
  console.log(listing, '===>>>otherListingotherListingotherListingotherListingotherListing')

   const decesionStatus = item => {
    if (item?.delivery_status) {
      return item.delivery_status;
    }
    if (item?.milestone_status) {
      return item.milestone_status;
    }
    if (item?.task_status) {
      return item.task_status;
    }
  };

  return (
    <>
      {filterData?.length > 0 &&
        filterData
          .filter(item =>
            item?.title?.toLowerCase()?.includes(searchListing?.toLowerCase()),
          )
          .map((item, index) => {
            const isActive = index === activeIndex;
            const matchedItems =  otherListing && otherListing.length > 0 && otherListing?.filter(
              other =>
                other.deliverable_id
                  ? other.deliverable_id === item.id &&
                    other.title.toLowerCase().includes(search.toLowerCase()) // Match task_id if exists
                  : other.milestone_id === item.id &&
                    other.title.toLowerCase().includes(search.toLowerCase()), // Otherwise, match milestone_id
            );

            const showSearch = type !== 'task' && otherListing && otherListing.length > 0 && otherListing?.filter((otherItem)=> otherItem.deliverable_id ? otherItem.deliverable_id === item.id 
          : otherItem?.milestone_id === item.id)
          // const  showSearch =[]
          // const  matchedItems =[]
          console.log(showSearch, 'pppppppppppppppppppppp')
            return (
              <div key={index} className="billing-detail mt-2">
                <div
                  className="pt-2 pb-2 as-link"
                  style={{display: 'flex', justifyContent: 'space-between'}}>
                  <h1
                    onClick={() => {
                      setShowDetail(!showDetail);
                      setActiveIndex(isActive ? null : index);
                    }}
                    className="mb-0">
                    {item.title}
                  </h1>
                  {(enable_edit || showAssociateListing === '') &&
                  <>
                  <div style={{display:'flex', alignItems: 'center'}}>
                 
                  <p
                    onClick={() => {
                      setTitle(item.title);
                      setIsEditing({
                        editing: true,
                        item: item,
                      });
                    }}
                    className="as-link cross-icon-field"
                    style={{padding: '2px', marginRight: '8px'}}>
                    <EditPencilSvg fill={'rgb(82, 189, 65)'} />
                  </p> 
                  <span
                    className=" as-link"
                    onClick={() => {
                      handleDelete(type, item.id)
                    }}>
                    <i style={{color: 'rgb(82, 189, 65)'}} className="fa-solid fa-trash-can" />
                  </span>
                  </div>
                  </>
          }
                </div>
                <div
                  className={`transition-all duration-300 ease-in-out overflow-hidden  mt-2 ${
                    isActive ? 'max-h-100' : 'max-h-0'
                  }`}>
                  <hr class="border-t border-gray-300 pb-3"></hr>

                  {/* {item?.tasks?.length > 0 &&
                    item.tasks
                      .filter(task =>
                        task.title.toLowerCase().includes(search.toLowerCase()),
                      )
                      .map(tasks => (
                        <>
                          <div className="roi-form-input">
                            <div className="d-flex justify-content-between input-plus-icon-holder">
                              <p>{tasks.title}</p>
                              <span>Due Date</span>
                            </div>
                          </div>
                          <hr class="border-t border-gray-300 pb-3"></hr>
                        </>
                      ))} */}

                  <Form.Group className="fieldset mt-2">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Enter Description"
                      value={item?.description ? item?.description : ''}
                      onChange={e => {
                        if(enable_edit || showAssociateListing === ''){
                        handleDescription(e.target.value, type, item);
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Feedback is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                  {type !== 'task' && (
                    <div className="roi-form-input">
                      <div className="d-flex justify-content-between input-plus-icon-holder">
                        <p>Associate Invoice</p>
                        <span
                          onClick={() => {
                            if(enable_edit || showAssociateListing === ''){
                              if(item?.invoice_ids){
                            manageInvoice(type, item);
                              }else{
                                errorMessage('No invoice available')
                              }
                            }
                          }}
                          className="as-link"
                          style={{color: '#52bd41'}}>
                          {item?.invoice_ids?.trim()
                            ? item?.invoice_ids.split(',').length
                            : 'Find'}{' '}
                          Invoice
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="roi-form-input">
                    <div className="d-flex justify-content-between input-plus-icon-holder">
                      <p>Start Date</p>
                      <div onClick={() => {
                        if(enable_edit || showAssociateListing === ''){
                        setIsOpen(true)
                      }}}>
                        {decesionStartDate(item) === '' ? (
                          <span className="template-text">
                            {' '}
                            <i className="fa-solid fa-calendar-days" />
                          </span>
                        ) : (
                          <span
                            style={{color: '#52bd41'}}
                            className="template-text as-link">
                            {moment(decesionStartDate(item))
                              .format('MMM DD, YYYY')
                              .toString()}
                          </span>
                        )}
                      </div>
                      <div className="invoice_calender-wrapper">
                        {isActive && isOpen && (
                          <Calender
                            maxDate={getMaxDateFromToday}
                            minDate={moment(today).format('YYYY-MM-DD')}
                            setSelectedDate={e => {
                              if (!e) {
                                errorMessage('Please select a valid date');
                                return;
                              }
                              const formattedDate = formatDate(e);
                              const isValidRange = moment(e).isBefore(
                                formatDate(decesionEndDate(item)),
                              );

                              console.log(
                                isValidRange,
                                'validRange',
                                e,
                                formatDate(decesionEndDate(item)),
                              );

                              if (isValidRange) {
                                manageStartDate(formattedDate, type, item); // Use formatted date
                                setIsOpen(false);
                              } else {
                                errorMessage('Please select a valid range');
                              }
                            }}
                            selectedDate={
                              decesionStartDate(item)
                                ? decesionStartDate(item)
                                : ''
                            }
                            type={'invoice'}
                            showDate={true}
                            showTime={false}
                            isOpenCalendar={isOpen}
                            setIsOpenCalendar={setIsOpen}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="roi-form-input">
                    <div className="d-flex justify-content-between input-plus-icon-holder">
                      <p>{type === 'task' ? 'Due Date' : 'End Date'}</p>
                      <div onClick={() => {
                        if(enable_edit || showAssociateListing === ''){
                        setOpenEndDate(true)
                        }
                      }}>
                        {decesionEndDate(item) === '' ? (
                          <span className="template-text">
                            {' '}
                            <i className="fa-solid fa-calendar-days" />
                          </span>
                        ) : (
                          <span
                            style={{color: '#52bd41'}}
                            className="template-text as-link">
                            {moment(decesionEndDate(item))
                              .format('MMM DD, YYYY')
                              .toString()}
                          </span>
                        )}
                      </div>{' '}
                      <div className="invoice_calender-wrapper">
                        {isActive && openEndDate && (
                          <Calender
                            minDate={moment(getNextDayDate).format(
                              'YYYY-MM-DD',
                            )}
                            setSelectedDate={e => {
                              if (!e) {
                                errorMessage('Please select a valid date');
                                return;
                              }
                              const formattedDate = formatDate(e);
                              const isValidRange = moment(e).isAfter(
                                formatDate(decesionStartDate(item)),
                              );

                              if (isValidRange) {
                                manageEndDate(formattedDate, type, item); // Use formatted date
                                setOpenEndDate(false);
                              } else {
                                errorMessage('Please select a valid range');
                              }
                            }}
                            selectedDate={
                              decesionEndDate(item) ? decesionEndDate(item) : ''
                            }
                            type={'invoice'}
                            showDate={true}
                            showTime={false}
                            isOpenCalendar={openEndDate}
                            setIsOpenCalendar={setOpenEndDate}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="roi-form-input">
                    <div className="d-flex justify-content-between input-plus-icon-holder">
                      <p>Team</p>
                      <span
                        className="as-link"
                        onClick={() => {
                          if(enable_edit || showAssociateListing === ''){
                          handleTeam(item, type)
                          }
                        }}
                        style={{color: '#52bd41'}}>
                        {item?.team_ids?.trim()
                          ? item.team_ids.split(',').length
                          : 'Select'}{' '}
                        Members
                      </span>
                    </div>
                  </div>
                  {(decesionStatus(item) && isFirstCharDigit(item.id)) &&
                  <>
                  <div className="roi-form-input">
                    <div className="d-flex justify-content-between input-plus-icon-holder">
                      <p>Status</p>
                      <span
                       
                       
                        >
                       
                        {decesionStatus(item)}
                      </span>
                    </div>
                  </div>
                  <div className="roi-form-input">
                    <div className="d-flex justify-content-between input-plus-icon-holder">
                      <p></p>
                      <span
                        className="as-link"
                        style={{color: '#52bd41'}}
                        onClick={() => {
                          if((enable_edit || showAssociateListing === '') && decesionStatus(item) === 'pending'){
                         handleStatus(type, item.id)
                          }
                        }}
                        >
                       
                       {showStatus(item)}
                      </span>
                    </div>
                  </div>
                  </>
                            }

                  {((showAssociateListing !== '') && showSearch.length > 0) && (
                    <div className="roi-form-input">
                      <h1 className="mb-0">
                        {type === 'milestones'
                          ? 'Deliverables'
                          : type === 'deliverables' && 'Tasks'}
                      </h1>
                    </div>
                  )}

                  {showSearch.length > 0 && (type === 'milestones' || type === 'deliverables') &&
                    (showAssociateListing !== '') && (
                      <Input
                        label=""
                        name={'search'}
                        value={search}
                        onChange={e => handleSearch(e)}
                        placeholder={
                          type === 'milestones'
                            ? 'Search for Deliverables'
                            : 'Search for Tasks'
                        }
                        required={false}
                        type={'text'}
                      />
                    )}
                  {(type === 'milestones' || type === 'deliverables') &&
                    (showAssociateListing !== '') &&
                    matchedItems.length > 0 && (
                    matchedItems.map((item, index) => (
                      <>
                        <div key={index} className="roi-form-input">
                          <div className="d-flex justify-content-between input-plus-icon-holder">
                            <p>{item.title}</p>
                            <span
                              style={{
                                display: 'inline-block',
                                width: '8px',
                                height: '8px',
                                backgroundColor: 'rgb(82, 189, 65)',
                                borderRadius: '50%',
                              }}></span>
                          </div>
                        </div>
                        <div className="roi-form-input">
                          <div className="d-flex justify-content-between input-plus-icon-holder">
                            <p>Assignee</p>
                            <span>Due Date</span>
                          </div>
                        </div>
                        <hr class="border-t border-gray-300 pb-3"></hr>
                      </>
                    )))
                //   :
                //   type !== 'task' &&
                //   <div className="flex justify-center items-center mt-3">
                //   <span
                //     class="cross-icon-field">
                //     {type === 'milestones' ? 'No deliverables found' : 'No tasks found'}
                //   </span>
                // </div>
                  
                }
                </div>
              </div>
            );
          })}
    </>
  );
};

export default MilestoneListing;

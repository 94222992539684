import React, {useRef} from 'react';
import {useState, useEffect} from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';
import CustomModal from '../CustomModal';
import ComparisonItem from './ComparisonItem';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import {errorMessage} from '../../Utils/ToastMessages';
import {successMessage} from '../../Utils/ToastMessages';
import commonApi from '../../Services/CommonService';
import {useImperativeHandle} from 'react';
import {
  checkCatalogIds,
  filterOutLevels,
  validateStandard,
  checkAvailablitiy,
  processCatalogs,
  validatePaymentEstimates,
  validateEstimatesCatalogsDiscount,
  getMaxDateFromToday,
  capitalizeFirst,
  formatValueWithTwoDecimals,
  checkProductQuantity,
  extractFirstLevelAndFrequency,
  calculateItemSubtotal,
  addProductQuantity,
  checkSubscriptionType,
  calculateTotal,
} from '../../Utils/commonUtils';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import {fixName} from '../../Utils/commonUtils';
import Calender from '../FeedBack/Calender';
import CustomTabs from '../CustomTabs';
import Upload from '../Project/Upload';
import NorecordFound from '../NoRecord/NorecordFound';
import {NoOfferingsFound} from '../../Utils/NoRecordConstant';
import InvoiceProduct from './InvoiceProduct';
const InvoiceEstimate = props => {
  const {
    invoiceAddOnData,
    setInvoiceAddOnData,
    setCatalogAddOnId,
    invoiceDate,
    setInvoiceDate,
    setDueDate,
    dueDate,
    comparisonList,
    setComparisonList,
  } = props;

  const [comparisonName, setComparisonName] = useState('');
  const [standardName, setStandardName] = useState('');
  const [type, setType] = useState(
    props.type === 'comparison' ? 'comparison' : 'standard',
  );
  // const [comparisonList, setComparisonList] = useState(props.comparisonList ? props.comparisonList : []);
  const [standardList, setStandardList] = useState([]);
  const [moreOptions, setMoreOptions] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [comparisonTitle, setComparisonTitle] = useState('');
  const [subType, setSubType] = useState('comparison');
  const [originalcomparisonDraft, setOriginalComparisonDraft] = useState([]);
  const [comparisonDraft, setComparisonDraft] = useState([]);
  const [originalstandardDraft, setOriginalStandardDraft] = useState([]);
  const [standardDraft, setStandardDraft] = useState([]);
  const [redraw, setRedraw] = useState(false);
  const [showErrorFromApi, setShowErrorFromApi] = useState([]);
  const [outOfStockItems, setOutOfStockItems] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [isExpandedBilling, setIsExpandedBilling] = useState(true);
  const [isExpandedNOtes, setIsExpandedNOtes] = useState(true);
  const [isExpandedMoreOption, setIsExpandedMoreOption] = useState(true);
  const [isExpandedAttachment, setIsExpandedAttachment] = useState(true);
  const [isExpandedInvoice, setIsExpandedInvoice] = useState(true);
  const [expandOfferings, setExpandOfferings] = useState(true);
  const [showSearchProduct, setShowSearchProduct] = useState('');
  const [showSearchOfferings, setShowSearchOfferings] = useState('');
  const [productDetail, setProductDetail] = useState([]);
  const [offeringsDetail, setOfferingsDetail] = useState([]);
  const [produtAvailability, setProductAvailabilty] = useState([]);
  const [expensesList, setExpensesList] = useState([]);
  const [builders, setBuilders] = useState([]);

  const [primaryUser, setPrimaryUser] = useState('');
  // const [deleteCatalog, setDeleteCatalog] = useState({});

  const offeringButtonRef = useRef(null);
  const dropdownRef = useRef(null);

  const get_catalogs_for_invoices = (value, type) => {
    const payload = {search_query: value, type: 'web'};
    commonApi
      .get_catalogs_for_invoices(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.catalog_data.length === 0) {
            setNoResults(true);
          } else {
            setNoResults(false);
          }
          // if (!value || value === '' ) {
          //   setProductDetail([]);
          // } else {

          if (value === '') {
            setNoResults(false);
            if (type === 'offerings') {
              setOfferingsDetail([]);
            } else {
              setProductDetail([]);
            }
          } else {
            if (type === 'offerings') {
              setOfferingsDetail(res.catalog_data);
            } else {
              setProductDetail(res.catalog_data);
            }
          }

          // }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const objectValues = (item, type) => {
    console.log(item, 'itemitemitemitemitemitemitem');
    let outOfStockCount = 0;

    const processItem = miniItem => {
      let checkQuantity = checkProductQuantity(produtAvailability, miniItem);

      if (checkQuantity) {
        if (type === 'offerings') {
          props.setSelectedOfferings(prev => [
            ...prev,
            {
              title: miniItem.title ? miniItem.title : '',
              catalog_id: miniItem.id ? miniItem.id : '',
              taxes_fee: miniItem.taxes_fee
                ? formatValueWithTwoDecimals(miniItem.taxes_fee)
                : '0.00',
              discount: miniItem.discount
                ? formatValueWithTwoDecimals(miniItem.discount)
                : '0.00',
              shipping_handling: miniItem.shipping_handling
                ? formatValueWithTwoDecimals(miniItem.shipping_handling)
                : '0.00',
              late_fee: miniItem.late_fee
                ? formatValueWithTwoDecimals(miniItem.late_fee)
                : '0.00',
              cost: miniItem.cost ? miniItem.cost : '0.00',
              duration: miniItem.duration ? miniItem.duration : '',
              grace_period: miniItem.grace_period ? miniItem.grace_period : '',
              catalog_type: miniItem.catalog_type ? miniItem.catalog_type : '',
              // subscription_month: miniItem.catalog_type === 'membership' ? extractFirstLevelAndFrequency(miniItem)?.subscription : miniItem.subscription
              //   ? miniItem.subscription
              //   : '',
              subscription_month: miniItem.subscription,
              total: calculateItemSubtotal(miniItem),
              payment_milestones:
                miniItem.catalog_type === 'membership'
                  ? extractFirstLevelAndFrequency(miniItem)?.frequency
                      ?.payment_milestones || []
                  : miniItem.payment_milestones &&
                    miniItem.payment_milestones.length > 0
                  ? miniItem.payment_milestones
                  : [],
              payment_type:
                miniItem.catalog_type === 'membership'
                  ? extractFirstLevelAndFrequency(miniItem)?.frequency
                      ?.payment_type || []
                  : miniItem.payment_type && miniItem.payment_type.length > 0
                  ? miniItem.payment_type
                  : [],
              // payment_milestone_id:
              //   miniItem.payment_milestones &&
              //   miniItem.payment_milestones.length > 0
              //     ? [miniItem.payment_milestones[0].id]
              //     : [],
              ...(miniItem.catalog_type === 'membership' &&
              miniItem.membership_levels.length > 0
                ? {
                    level: extractFirstLevelAndFrequency(miniItem),
                    membership_levels:
                      miniItem.catalog_type === 'membership' &&
                      miniItem.membership_levels.length > 0
                        ? miniItem.membership_levels
                        : [],
                  }
                : {}),
              ...(miniItem.catalog_type === 'product' && {
                out_of_stock: false,
              }),
              ...(miniItem.catalog_type === 'product' && {
                left_item: item.left_item,
              }),
              ...(miniItem.catalog_type === 'product' && {
                quantity: item.quantity,
              }),
              ...(miniItem.catalog_type === 'product' && {
                total_used: item.total_used,
              }),
            },
          ]);
        } else {
          props.setSelectedAddOnProduct(prev => [
            ...prev,
            {
              title: miniItem.title ? miniItem.title : '',
              catalog_id: miniItem.id ? miniItem.id : '',
              taxes_fee: miniItem.taxes_fee
                ? formatValueWithTwoDecimals(miniItem.taxes_fee)
                : '0.00',
              discount: miniItem.discount
                ? formatValueWithTwoDecimals(miniItem.discount)
                : '0.00',
              shipping_handling: miniItem.shipping_handling
                ? formatValueWithTwoDecimals(miniItem.shipping_handling)
                : '0.00',
              late_fee: miniItem.late_fee
                ? formatValueWithTwoDecimals(miniItem.late_fee)
                : '0.00',
              cost: miniItem.cost ? miniItem.cost : '0.00',
              duration: miniItem.duration ? miniItem.duration : '',
              grace_period: miniItem.grace_period ? miniItem.grace_period : '',
              catalog_type: miniItem.catalog_type ? miniItem.catalog_type : '',
              // subscription_month: miniItem.catalog_type === 'membership' ? extractFirstLevelAndFrequency(miniItem)?.subscription : miniItem.subscription
              //   ? miniItem.subscription
              //   : '',
              subscription_month: miniItem.subscription,
              total: calculateItemSubtotal(miniItem),
              payment_milestones:
                miniItem.catalog_type === 'membership'
                  ? extractFirstLevelAndFrequency(miniItem)?.frequency
                      ?.payment_milestones || []
                  : miniItem.payment_milestones &&
                    miniItem.payment_milestones.length > 0
                  ? miniItem.payment_milestones
                  : [],
              payment_type:
                miniItem.catalog_type === 'membership'
                  ? extractFirstLevelAndFrequency(miniItem)?.frequency
                      ?.payment_type || []
                  : miniItem.payment_type && miniItem.payment_type.length > 0
                  ? miniItem.payment_type
                  : [],
              // payment_milestone_id:
              //   miniItem.payment_milestones &&
              //   miniItem.payment_milestones.length > 0
              //     ? [miniItem.payment_milestones[0].id]
              //     : [],
              ...(miniItem.catalog_type === 'membership' &&
              miniItem.membership_levels.length > 0
                ? {
                    level: extractFirstLevelAndFrequency(miniItem),
                    membership_levels:
                      miniItem.catalog_type === 'membership' &&
                      miniItem.membership_levels.length > 0
                        ? miniItem.membership_levels
                        : [],
                  }
                : {}),
              ...(miniItem.catalog_type === 'product' && {
                out_of_stock: false,
              }),
              ...(miniItem.catalog_type === 'product' && {
                left_item: item.left_item,
              }),
              ...(miniItem.catalog_type === 'product' && {
                quantity: item.quantity,
              }),
              ...(miniItem.catalog_type === 'product' && {
                total_used: item.total_used,
              }),
            },
          ]);
        }
        setCatalogAddOnId(prev => [...prev, miniItem.id]);
        if (miniItem.catalog_type === 'product') {
          const productsQuantity = addProductQuantity(
            miniItem.id,
            produtAvailability,
          );
          setProductAvailabilty(productsQuantity);
        }
        setShowSearchProduct('');
      } else {
        outOfStockCount++;
      }

      setInvoiceAddOnData(prevData => ({
        ...prevData,
        subscripton: Number(prevData.subscripton) + Number(miniItem.cost),
        subtotal: Number(prevData.subtotal) + Number(miniItem.cost),
        total: Number(prevData.total) + Number(miniItem.cost),
      }));
    };

    if (item.catalog_details && item.catalog_details.length > 0) {
      item.catalog_details.forEach(miniItem => {
        processItem(miniItem);
      });
    } else {
      let checkQuantity = checkProductQuantity(produtAvailability, item);
      if (checkQuantity) {
        if (type === 'offerings') {
          props.setSelectedOfferings(prev => [
            ...prev,
            {
              title: item.title ? item.title : '',
              catalog_id: item.id ? item.id : '',
              taxes_fee: item.taxes_fee
                ? formatValueWithTwoDecimals(item.taxes_fee)
                : '0.00',
              discount: item.discount
                ? formatValueWithTwoDecimals(item.discount)
                : '0.00',
              shipping_handling: item.shipping_handling
                ? formatValueWithTwoDecimals(item.shipping_handling)
                : '0.00',
              late_fee: item.late_fee
                ? formatValueWithTwoDecimals(item.late_fee)
                : '0.00',
              cost: item.cost ? item.cost : '0.00',
              duration: item.duration ? item.duration : '',
              grace_period: item.grace_period ? item.grace_period : '',
              catalog_type: item.catalog_type ? item.catalog_type : '',
              subscription_month: item.subscription ? item.subscription : '',
              total: calculateItemSubtotal(item),
              payment_milestones:
                item.payment_milestones && item.payment_milestones.length > 0
                  ? item.payment_milestones
                  : [],
              payment_type:
                item.payment_type && item.payment_type.length > 0
                  ? item.payment_type
                  : [],
              // payment_milestone_id:
              //   item.payment_milestones && item.payment_milestones.length > 0
              //     ? [item.payment_milestones[0].id]
              //     : '',
              membership_levels:
                item.catalog_type === 'membership' &&
                item.membership_levels.length > 0
                  ? item.membership_levels
                  : [],

              ...(item.catalog_type === 'product' && {
                out_of_stock: false,
              }),
              ...(item.catalog_type === 'product' && {
                left_item: item.left_item,
              }),
              ...(item.catalog_type === 'product' && {
                quantity: item.quantity,
              }),
              ...(item.catalog_type === 'product' && {
                total_used: item.total_used,
              }),
            },
          ]);
        } else {
          props.setSelectedAddOnProduct(prev => [
            ...prev,
            {
              title: item.title ? item.title : '',
              catalog_id: item.id ? item.id : '',
              taxes_fee: item.taxes_fee
                ? formatValueWithTwoDecimals(item.taxes_fee)
                : '0.00',
              discount: item.discount
                ? formatValueWithTwoDecimals(item.discount)
                : '0.00',
              shipping_handling: item.shipping_handling
                ? formatValueWithTwoDecimals(item.shipping_handling)
                : '0.00',
              late_fee: item.late_fee
                ? formatValueWithTwoDecimals(item.late_fee)
                : '0.00',
              cost: item.cost ? item.cost : '0.00',
              duration: item.duration ? item.duration : '',
              grace_period: item.grace_period ? item.grace_period : '',
              catalog_type: item.catalog_type ? item.catalog_type : '',
              subscription_month: item.subscription ? item.subscription : '',
              total: calculateItemSubtotal(item),
              payment_milestones:
                item.payment_milestones && item.payment_milestones.length > 0
                  ? item.payment_milestones
                  : [],
              payment_type:
                item.payment_type && item.payment_type.length > 0
                  ? item.payment_type
                  : [],
              // payment_milestone_id:
              //   item.payment_milestones && item.payment_milestones.length > 0
              //     ? [item.payment_milestones[0].id]
              //     : '',
              membership_levels:
                item.catalog_type === 'membership' &&
                item.membership_levels.length > 0
                  ? item.membership_levels
                  : [],

              ...(item.catalog_type === 'product' && {
                out_of_stock: false,
              }),
              ...(item.catalog_type === 'product' && {
                left_item: item.left_item,
              }),
              ...(item.catalog_type === 'product' && {
                quantity: item.quantity,
              }),
              ...(item.catalog_type === 'product' && {
                total_used: item.total_used,
              }),
            },
          ]);
        }
        setCatalogAddOnId(prev => [...prev, item.id]);

        if (item.catalog_type === 'product') {
          const productsQuantity = addProductQuantity(
            item.id,
            produtAvailability,
          );
          setProductAvailabilty(productsQuantity);
        }
        setShowSearchProduct('');
        setShowSearchOfferings('');
        get_catalogs_for_invoices('');
      } else {
        outOfStockCount++;
      }
      // setInvoiceAddOnData({
      //   ...invoiceAddOnData,
      //   subscripton: Number(invoiceAddOnData?.subscripton) + Number(item.cost),
      //   subtotal: Number(invoiceAddOnData?.subtotal) + Number(item.cost),
      //   total: Number(invoiceAddOnData.total) + Number(item.cost),
      // });
    }

    if (outOfStockCount > 1) {
      errorMessage(
        `${outOfStockCount} items ${labels.OUT_OF_STOCK[selected_lang]}`,
      );
    }
    if (outOfStockCount === 1) {
      errorMessage(`${labels.OUT_OF_STOCK[selected_lang]}`);
    }
  };

  const handleDataChange = newData => {
    props.setSelectedAddOnProduct(newData);
  };

  useEffect(() => {
    // props.setSelectedAddOnProduct([])
    // props.setCatalogAddOnId([])
  }, [type]);

  const showOutOfStockError = (item, itemIndex) => {
    const newData = [...outOfStockItems];

    let itemToUpdate = outOfStockItems.find(obj => obj.id == item.catalog_id);

    if (itemToUpdate) {
      let allowedIndexs = itemToUpdate.allowed ? itemToUpdate.allowed : [];
      let errorIndexs = itemToUpdate.not_allowed
        ? itemToUpdate.not_allowed
        : [];
      const quantity = builders.length > 0 ? builders.length : 1;
      // const quantity = 1;
      itemToUpdate.total_used = Number(itemToUpdate.total_used) + quantity;
      itemToUpdate.item_left = Number(itemToUpdate.item_left) - quantity;

      if (Number(itemToUpdate.item_left) >= 0) {
        const index = outOfStockItems.findIndex(
          obj => obj.id == item.catalog_id,
        );
        newData[index] = itemToUpdate;

        allowedIndexs.push(itemIndex);
        itemToUpdate.allowed = allowedIndexs;

        setOutOfStockItems(newData);
      } else if (
        !allowedIndexs.includes(itemIndex) &&
        !errorIndexs.includes(itemIndex)
      ) {
        const index = outOfStockItems.findIndex(
          obj => obj.id == item.catalog_id,
        );
        newData[index] = itemToUpdate;

        errorIndexs.push(itemIndex);
        itemToUpdate.not_allowed = errorIndexs;

        setOutOfStockItems(newData);
      }

      return itemToUpdate;
    } else {
      return {not_allowed: []};
    }
  };

  const InitialComparison = [
    {
      invoice_type: 'estimate',
      sub_type: 'comparison',
      group_name: 'Good',
      add_on: '',
    },
    {
      invoice_type: 'estimate',
      sub_type: 'comparison',
      group_name: 'Better',
      add_on: '',
    },
    {
      invoice_type: 'estimate',
      sub_type: 'comparison',
      group_name: 'Best',
      add_on: '',
    },
  ];

  // const [invoiceDate, setInvoiceDate] = useState(() => {
  //   const date = new Date();

  //   // date.setHours(12, 0, 0, 0);
  //   return date;
  // });
  // const [dueDate, setDueDate] = useState(() => {
  //   const nextDay = new Date();
  //   nextDay.setDate(nextDay.getDate() + 1);
  //   // nextDay.setHours(12, 0, 0, 0);
  //   return nextDay;
  // });
  const [note, setNote] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const [endIsOpen, setEndIsOpen] = useState(false);

  const dropdownMenu = [
    {id: '1', name: 'Comparison'},
    {
      id: '2',
      name: 'Standard',
    },
  ];
  const TogleButtons = ['Comparison', 'Standard'];

  useImperativeHandle(props.titleRef, () => ({
    addComparison(title) {
      setComparisonList(prev => [
        ...prev,
        {
          invoice_type: 'estimate',
          sub_type: props.subType,
          group_name: title,

          add_on: '',
        },
      ]);

      props.setComparisonTitle('');
      props.setShowComponent('');
    },

    updateComparsion(title, item, index) {
      setComparisonList(prevList => {
        const newList = [...prevList];

        newList[index] = {
          ...newList[index],
          group_name: title,
        };

        return newList;
      });

      props.setComparisonTitle('');
      props.setShowComponent('');
    },

    deleteComparison(item, index) {
      setComparisonList(prevList => {
        const newList = prevList.filter((_, i) => i !== index);

        return newList;
      });

      props.setComparisonTitle('');
      props.setShowComponent('');
    },

    getDraft() {
      getEstimatesDraft('comparison');
    },

    discardDraft() {
      discardEstimateDraft('comparison');
    },

    checkStockAvailabilty() {
      if (outOfStockItems.length > 0) {
        const checkProductAvailablitiy = checkAvailablitiy(
          'estimate',
          [],
          props.comparsionUsers,
          comparisonList,
        );
        setOutOfStockItems(
          checkProductAvailablitiy.length > 0 ? checkProductAvailablitiy : [],
        );
      }
    },
    //   DeleteCatalogItem(catalog) {

    //     console.log(catalog,'catalog')

    //     setComparisonList(prevInvoices => {
    //       return prevInvoices.map((invoice, index) => {
    //           if (index === catalog.comparsionItemIndex) {
    //               console.log("Original catalog_ids:", invoice.catalog_ids);
    //               const catalogIdsArray = invoice.catalog_ids.split(',').filter(id => id !== catalog.item.id);
    //               console.log("Filtered catalogIdsArray:", catalogIdsArray);
    //               const newCatalogIds = catalogIdsArray.join(',');
    //               console.log("New catalog_ids:", newCatalogIds);
    //               return {
    //                   ...invoice,
    //                   catalog_ids: newCatalogIds
    //               };
    //           }
    //           return invoice;
    //       });
    //   });

    // setRedraw(!redraw)

    //     props.setShowComponent('');
    //   },
  }));
  // const addComparison = title => {

  // };

  useImperativeHandle(props.templateRef, () => ({
    addTemplateItem(item) {
      console.log(item, 'comparison template item');
      if (props.subType === 'comparison') {
        setComparisonDraft(item.invoices);
        handleComparsionDraft(item.invoices);
        addOnAdd(item.add_on_details)
      } else {
        setStandardDraft(item.invoices);
        handleStandardDraft(item.invoices);
        addOnAdd(item.add_on_details)
      }
    },
  }));

  useImperativeHandle(props.standardRef, () => ({
    addStandard(title, addOn) {
      setStandardList(prev => [
        ...prev,
        {
          invoice_type: 'estimate',
          sub_type: props.subType,
          group_name: title,

          add_on: addOn,
        },
      ]);

      props.setComparisonTitle('');
      props.setShowComponent('');
    },

    updateStandard(title, item, addOn, index) {
      console.log(title, 'invoicetitle');
      console.log(item, 'updtaeItem');

      setStandardList(prevList => {
        const newList = [...prevList];

        newList[index] = {
          ...newList[index],
          group_name: title,
          add_on: addOn,
        };

        return newList;
      });

      props.setComparisonTitle('');
      props.setShowComponent('');
    },

    deleteStandard(item, index) {
      setStandardList(prevList => {
        const newList = prevList.filter((_, i) => i !== index);

        return newList;
      });

      props.setComparisonTitle('');
      props.setShowComponent('');
    },

    getDraft() {
      getEstimatesDraft('standard');
    },

    discardDraft() {
      discardEstimateDraft('standard');
    },
    checkStockAvailabilty() {
      if (outOfStockItems.length > 0) {
        const checkProductAvailablitiy = checkAvailablitiy(
          'estimate',
          [],
          props.standardUsers,
          standardList,
        );
        setOutOfStockItems(
          checkProductAvailablitiy.length > 0 ? checkProductAvailablitiy : [],
        );
      }
    },
  }));

  console.log(comparisonList, 'jhhhhhhhhhhhhhhhhhhhhhhh');

  // const addStandard = title => {
  //   setStandardList(prev => [
  //     ...prev,
  //     {
  //       invoice_type: 'estimate',
  //       sub_type: subType,
  //       group_name: title,

  //       add_on: 'OR',
  //     },
  //   ]);

  //   setComparisonTitle('');
  // };

  console.log(
    props.selectedOfferings,
    '============>>>>>selectedOfferingsselectedOfferingsselectedOfferings',
  );

  useEffect(() => {
    if (props.subType === 'comparison') {
      setType('comparison');
      getEstimatesDraft('comparison');
    } else {
      handleReset();
      setType('standard');
      getEstimatesDraft('standard');
    
    }
  }, [props.subType]);

  useEffect(() => {
    // getComparisonTemplates()

    if (
      props.estimateTemplateItem &&
      Object.keys(props.estimateTemplateItem).length > 0
    ) {
      if (props.type === 'comparison') {
        // setOriginalComparisonDraft(res.draft_invoice);
        setComparisonDraft(props.estimateTemplateItem.invoices);
        handleComparsionDraft(props.estimateTemplateItem.invoices);
        addOnAdd(props.estimateTemplateItem.add_on_details)
      } else {
        // setOriginalStandardDraft(res.draft_invoice);
        setStandardDraft(props.estimateTemplateItem.invoices);
        handleStandardDraft(props.estimateTemplateItem.invoices);
        addOnAdd(props.estimateTemplateItem.add_on_details)
      }
    }

    // getEstimatesDraft('comparison');

    getEstimatesDraft('comparison');

    // setTimeout(() => {
    //   getEstimatesDraft('comparison');
    // }, 3000);
  }, []);

  const getEstimatesDraft = type => {
    const payload = {invoice_type: 'estimate', sub_type: props.subType};
    commonApi
      .check_invoice_draft(payload)
      .then(res => {
        if (res.status === 200) {
          if (
            res.draft_invoice.length > 0 
            // &&
            // props.estimateTemplateItem &&
            // props.type
          ) {
            if (type === 'comparison') {
              setOriginalComparisonDraft(res.draft_invoice);
              setComparisonDraft(res.draft_invoice);
              handleComparsionDraft(res.draft_invoice);
              addOnAdd(res.add_on_details)
            } else {
              setOriginalStandardDraft(res.draft_invoice);
              setStandardDraft(res.draft_invoice);
              handleStandardDraft(res.draft_invoice);
              addOnAdd(res.add_on_details)
            }
          } else {
        
            if (
              type === 'comparison' 
              &&
             comparisonList.length === 0
            ) {
              setComparisonList(InitialComparison);
            }
          }

          setTimeout(() => {
            props.setShowLoader(false);
          }, 300);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleStandardDraft = draftItem => {
    if (draftItem && Object.keys(draftItem).length > 0) {
      setStandardName(draftItem[0]?.invoice_title);

      const mappedArray = draftItem.map(item => ({
        invoice_type: item.invoice_type,
        sub_type: item.sub_type,
        group_name: item.group_name,
        add_on: item.add_on,
        catalog_ids: item.catalog_ids,
        catalog_details: item.catalog_details,
        customer_note: item.customer_note,
        name: '',
        subscripton: Number(item.sub_total),
        subtotal: Number(item.sub_total),
        tax: Number(item.taxes_fee),
        discount: Number(item.discount),
        shipping: Number(item.shipping_handling),
        total: Number(item.total),
        payment_Schedule: '',
      }));
      // console.log(mappedArray, 'mappedArray');
      setStandardList(mappedArray);

      setRedraw(!redraw);
      // setInvoiceDate(new Date(draftItem[0]?.invoice_date));
      // setDueDate(new Date(draftItem[0]?.due_date));

     
      props.setTeamBuilders(
        draftItem[0]?.team_ids
          ? draftItem[0]?.team_ids.split(',')
          : [],
      );
     
      props.setStandardUsers(
        draftItem[0]?.primary_user_id
          ? draftItem[0]?.primary_user_id.split(',')
          : [],
      );

      props.setStandUsers(
        draftItem[0]?.draft_user_ids
          ? draftItem[0]?.draft_user_ids.split(',')
          : [],
      );
    } else {
      setStandardList([]);
      setStandardName('');

      // const date = new Date();

      // date.setHours(12, 0, 0, 0);

      // setInvoiceDate(date);

      // const nextDay = new Date();
      // nextDay.setDate(nextDay.getDate() + 1);
      // nextDay.setHours(12, 0, 0, 0);
      // setDueDate(nextDay);
      props.setStandardUsers([]);
      props.setStandUsers([]);
    }
  };

  const handleComparsionDraft = draftItem => {
    if (draftItem && Object.keys(draftItem).length > 0) {
      setComparisonName(draftItem[0]?.invoice_title);

      const mappedArray = draftItem.map(item => ({
        invoice_type: item.invoice_type,
        sub_type: item.sub_type,
        group_name: item.group_name,
        add_on: item.add_on,
        catalog_ids: item.catalog_ids,
        catalog_details: item.catalog_details,
        customer_note: item.customer_note,
        name: '',
        subscripton: Number(item.sub_total).toFixed(2),
        subtotal: Number(item.sub_total),
        tax: Number(item.taxes_fee).toFixed(2),
        discount: Number(item.discount).toFixed(2),
        shipping: Number(item.shipping_handling).toFixed(2),
        total: Number(item.total),
        payment_Schedule: '',
      }));
      // console.log(mappedArray, 'mappedArray');
      setComparisonList(mappedArray);
      setRedraw(!redraw);

      // setInvoiceDate(new Date(draftItem[0]?.invoice_date));
      // setDueDate(new Date(draftItem[0]?.due_date));
      props.setComparsionUsers(
        draftItem[0]?.primary_user_id
          ? draftItem[0]?.primary_user_id.split(',')
          : [],
      );
      props.setTeamBuilders(
        draftItem[0]?.team_ids
          ? draftItem[0]?.team_ids.split(',')
          : [],
      );
      props.setCompUsers(
        draftItem[0]?.draft_user_ids
          ? draftItem[0]?.draft_user_ids.split(',')
          : [],
      );
    } else {
      setComparisonName('');
      setComparisonList(InitialComparison);

      props.setComparsionUsers([]);
      props.setCompUsers([]);
    }

    const date = new Date();

    // date.setHours(12, 0, 0, 0);

    setInvoiceDate(date);

    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    // nextDay.setHours(12, 0, 0, 0);
    setDueDate(nextDay);
  };

  useEffect(() => {}, [comparisonDraft, comparisonList]);

  const handleChange = e => {
    if (endIsOpen === true) {
      setDueDate(e);
      setEndIsOpen(false);
    } else if (e) {
      setInvoiceDate(e);
      setIsOpen(false);
    } else {
      setIsOpen(false);
    }
  };

  const handleClickD = e => {
    e.preventDefault();
    setIsOpen(true);
    setEndIsOpen(false);
  };
  const handleClickE = e => {
    e.preventDefault();
    setEndIsOpen(true);
    setIsOpen(false);
  };

  const handleOnClickOutside = () => {
    setIsOpen(false);
    setEndIsOpen(false);
  };

  const handleComparisonSubmit = type => {
    const currentDate = new Date();
    const dueDateCheck = new Date(dueDate);
    const invoiceDateCheck = new Date(invoiceDate);

    let checkDate =
      // (currentDate <= invoiceDateCheck && currentDate <= dueDateCheck) ||
      invoiceDateCheck <= dueDateCheck;

    if (comparisonName !== '') {
      if (
        (comparisonList.length > 1 && checkCatalogIds(comparisonList)) ||
        type === 'template' ||
        type === 'draft'
      ) {
        if (dueDate !== '' && invoiceDate !== '' && checkDate) {
          if (
            props.comparsionUsers.length > 0 ||
            type === 'template' ||
            type === 'draft'
          ) {
            sendEstimate(type);
          } else {
            errorMessage('Please select Users');
          }
        } else {
          errorMessage(
            !checkDate && dueDate !== ''
              ? 'Please select valid date'
              : 'Please select Date',
          );
        }
      } else {
        errorMessage(
          comparisonList.length === 1
            ? 'Please Add One More Comparison'
            : 'Please Add Catalogs',
        );
      }
    } else {
      errorMessage(comparisonName === '' ? 'Please add Title' : '');
    }
  };

  const handleStandardSubmit = type => {
    const currentDate = new Date();
    const dueDateCheck = new Date(dueDate);
    const invoiceDateCheck = new Date(invoiceDate);

    let checkDate =
      // (currentDate <= invoiceDateCheck && currentDate <= dueDateCheck) ||
      invoiceDateCheck <= dueDateCheck;

    // const checkIds = checkCatalogIds(standardList);

    // const validateAddOn = validateStandard(standardList);

    if (standardName !== '') {
      if (
        props.selectedOfferings.length > 0 ||
        type === 'template' ||
        type === 'draft'
      ) {
        if (dueDate !== '' && invoiceDate !== '' && checkDate) {
          if (type === 'template' || type === 'draft') {
            if (props.subType === 'standard') {
              if (
                props.standardUsers.length > 0 ||
                type === 'template' ||
                type === 'draft'
              ) {
                sendEstimate(type);
              } else {
                errorMessage('Please select Users');
              }
            } else {
              sendEstimate(type);
            }
          } else {
            sendEstimate(type);
          }
        } else {
          errorMessage(
            !checkDate && dueDate !== ''
              ? 'Please select valid date'
              : 'Please select Date',
          );
        }
      } else {
        errorMessage('Please Add Catalogs');
      }
    } else {
      errorMessage(standardName === '' ? 'Please add Title' : '');
    }
  };

  const sendEstimate = async type => {
    // console.log(comparisonList, 'comparisonList payload');

    let checkProductAvailablitiy = [];
    if (props.subType === 'comparison') {
      checkProductAvailablitiy = checkAvailablitiy(
        'estimate',
        [],
        props.comparsionUsers,
        comparisonList,
      );
    } else {
      checkProductAvailablitiy = checkAvailablitiy(
        'estimate',
        [],
        props.standardUsers,
        standardList,
      );
    }

    console.log(
      checkProductAvailablitiy,
      'checkProductAvailablitiycheckProductAvailablitiy',
    );
    if (checkProductAvailablitiy.length > 0) {
      setOutOfStockItems(checkProductAvailablitiy);
      if (
        checkProductAvailablitiy.length > 0 && props.subType === 'comparison'
          ? props.comparsionUsers.length > 1
          : props.standardUsers.length > 1
      ) {
        errorMessage(`${labels.OUT_OF_STOCK_FOR_USERS[selected_lang]}`);
      }
      return;
    }

    let updatedArray = [];

    if (props.subType === 'comparison') {
      updatedArray = comparisonList.map(
        ({shipping, tax, subtotal, catalog_details, ...item}) => ({
          ...item,
          // discount: item.discount ? String(item.discount) : '0.00',
          // shipping_handling: shipping ? String(shipping) : '0.00',
          catalog_details: processCatalogs(catalog_details),
          total: item.total ? String(item.total) : '0.00',
          // sub_total: subtotal ? String(subtotal) : '0.00',
          // taxes_fee: tax ? String(tax) : '0.00',
          is_draft: type === 'draft' ? true : false,
          is_template: type === 'template' ? true : false,

          invoice_title: comparisonName,
          invoice_date: invoiceDate
            ? Moment(invoiceDate).format('YYYY-MM-DD HH:mm:ss').toString()
            : '',
          due_date: dueDate
            ? Moment(dueDate).format('YYYY-MM-DD HH:mm:ss').toString()
            : '',
        }),
      );
    } else {
      updatedArray = [
        {
          catalog_details: props.selectedOfferings,
          total: calculateTotal(props.selectedOfferings),
          // sub_total: subtotal ? String(subtotal) : '0.00',
          // taxes_fee: tax ? String(tax) : '0.00',

          invoice_type: 'standard',
          customer_note:'',
          sub_type: 'comparison',
          group_name: '',
          is_draft: type === 'draft' ? true : false,
          is_template: type === 'template' ? true : false,
          invoice_title: standardName,
          invoice_date: invoiceDate
            ? Moment(invoiceDate).format('YYYY-MM-DD').toString()
            : '',
          due_date: dueDate
            ? Moment(dueDate).format('YYYY-MM-DD').toString()
            : '',
        },
      ];
      // discount: item.discount ? String(item.discount) : '0.00',
      // shipping_handling: shipping ? String(shipping) : '0.00',
    }

    const validateMilestone = validatePaymentEstimates(updatedArray);

    const validateDisount = validateEstimatesCatalogsDiscount(updatedArray);

    console.log(
      validateMilestone,
      '------->validateMilestonevalidateMilestone',
    );
    if (!validateMilestone) {
      errorMessage('Sum of payment milestones must be equal to cost');
      return;
    }
    if (validateDisount) {
      errorMessage(labels.discount_Error[selected_lang]);
      return;
    }

    var Payload = {
      inserted_data: updatedArray,
      user_id: [],

      primary_user_id:
        props.subType === 'comparison'
          ? props.comparsionUsers.toString()
          : props.standardUsers.toString(),
      team_ids: props.builders.toString(','),
      add_on_details: props.selectedAddOnProduct,
    };

    console.log(Payload, 'Payload');

    commonApi
      .send_invoice_estimate(Payload)
      .then(res => {
        if (res.success && !res.error) {
          successMessage(res.message);

          if (props.type && type === 'draft') {
            props.getDraft();
          }
          if (type !== 'template') {
            props.onClose();
          }

          if (type === 'template') {
            if (props.subType === 'comparison') {
              props.getEstimateTemplates('comparison');
            } else {
              props.getEstimateTemplates('standard');
            }
          }
        }

        if (res.error && !res.user_issue) {
          // errorMessage(`${labels.QUANTITY_NOT_AVAILABLE[selected_lang]}`);
          if (res.not_available.length > 0) {
            setOutOfStockItems(res.not_available);
          }

          // setShowErrorFromApi(res.not_available)
        }

        if (res.error && res.user_issue) {
          if (res.not_available.length > 0) {
            setOutOfStockItems(res.not_available);
          }
          errorMessage(`${labels.OUT_OF_STOCK_FOR_USERS[selected_lang]}`);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleResetDraft = type => {
    if (type === 'comparison') {
      setComparisonDraft(originalcomparisonDraft);
      handleComparsionDraft(originalcomparisonDraft);
    } else {
      setStandardDraft(originalstandardDraft);
      handleStandardDraft(originalstandardDraft);
    }
  };

  const addOnAdd =(item)=>{
    props.setSelectedAddOnProduct(item)
  }

  const handleUpdateList = array => {
    // setComparisonList(array)
  };

  const discardEstimateDraft = type => {
    var payload = {
      inserted_data: [
        {
          invoice_type: 'estimate',
          sub_type: type,
          discard_draft: true,
        },
      ],
    };
    commonApi
      .send_invoice_estimate(payload)
      .then(res => {
        successMessage(res.message);

        if (type === 'comparison') {
          setComparisonName('');
          setComparisonList(InitialComparison);

          props.setComparsionUsers([]);
          props.setCompUsers([]);
          setComparisonDraft([]);
        }
        if (type === 'standard') {
          setStandardList([]);
          setStandardName('');
          props.setStandardUsers([]);
          props.setStandUsers([]);
          setStandardDraft([]);
        }

        setRedraw(!redraw);

        props.setShowComponent('');
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleReset = () => {
    setOutOfStockItems([]);
    props.setUserSearchQry('');
    props.setUserDetail([]);
    setNoResults(false);
  };

  const get_users_for_invoices = value => {
    const payload = {search_query: value};
    commonApi
      .excluded_followers_list(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.data.length === 0) {
            setNoResults(true);
          } else {
            setNoResults(false);
          }
          if (!value || value === '') {
            props.setUserDetail([]);
          } else {
            props.setUserDetail(res.data);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const capitalizeWords = str =>
    str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  return (
    <>
      <div className="estimate_tab">
        {/* {comparisonList.length > 0 && ( */}
        <>
          {type == 'comparison' && (
            <div className="d-flex justify-content-between estimate_contact">
              <div>
                {Object.keys(comparisonDraft).length > 0 && !props.type && (
                  <div
                    className="template-text fit_content  text-custom-13 as-link"
                    onClick={() => {
                      if (Object.keys(comparisonDraft).length > 0) {
                        props.setShowComponent('discardComparisonDraft');
                      }
                    }}>
                    Discard Draft
                  </div>
                )}
              </div>

              {(props.comparsionUsers.length > 0 ||
                comparisonList.length > 0) && (
                <div
                  className="template-text pb-2 as-link fit_content"
                  onClick={() => {
                    props.setAddUser('comparisonUsers');
                  }}>
                  {props.comparsionUsers && props.comparsionUsers.length > 0
                    ? props.comparsionUsers.length > 1
                      ? `${props.comparsionUsers.length} Contacts Selected`
                      : '1 Contact Selected'
                    : 'Select Contact'}
                </div>
              )}
            </div>
          )}

          {type === 'standard' && (
            <div className="d-flex justify-content-between estimate_contact">
              <div>
                {Object.keys(standardDraft).length > 0 && !props.type && (
                  <div
                    className="template-text fit_content  text-custom-13 as-link"
                    onClick={() => {
                      if (Object.keys(standardDraft).length > 0) {
                        props.setShowComponent('discardStandardDraft');
                      }
                    }}>
                    Discard Draft
                  </div>
                )}
              </div>
              {(standardList.length > 0 || props.standardUsers.length > 0) && (
                <div
                  className="template-text pb-2 as-link fit_content  text-custom-13"
                  onClick={() => {
                    props.setAddUser('standardUsers');
                  }}>
                  {props.standardUsers && props.standardUsers.length > 0
                    ? props.standardUsers.length > 1
                      ? `${props.standardUsers.length} Contacts Selected`
                      : '1 Contact Selected'
                    : 'Select Contact'}
                </div>
              )}
            </div>
          )}
        </>
        {/* )} */}

        {type == 'comparison' && (
          <>
            <div className="roi-form-input">
              <Form.Control
                type="text"
                value={comparisonName}
                pattern="^\S.*$"
                required
                placeholder="Title"
                onChange={e => {
                  setComparisonName(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Title is required.
              </Form.Control.Feedback>
            </div>

            <div className="billing-detail mb-3">
              <h1
                className="pt-2 pb-2 as-link"
                onClick={() => {
                  setIsExpandedBilling(!isExpandedBilling);
                }}>
                Billing Details
              </h1>

              <div
                className={`transition-all duration-300 ease-in-out overflow-hidden ${
                  isExpandedBilling ? 'max-h-screen' : 'max-h-0'
                }`}>
                <hr class="border-t border-gray-300 pb-3"></hr>
                <div className="roi-form-input">
                  <Form.Control
                    type="text"
                    // value={invoiceService}
                    pattern="^\S.*$"
                    // required
                    placeholder="Search for contact"
                    value={props.userSearchQry}
                    onChange={e => {
                      get_users_for_invoices(e.target.value);
                      props.setUserSearchQry(e.target.value);
                    }}
                  />
                  {props.userSearchQry && props.userDetail.length > 0 ? (
                    <ul
                      className="custom-suggestions invoices_suggestion"
                      ref={props.dropdownRef}>
                      {props.userDetail.map(suggestion => (
                        <li
                          className="as-link"
                          key={'suggestion' + suggestion.id}
                          onClick={() => {
                            // setPrimaryUser(suggestion.id);
                            props.handleComparisonUsers(suggestion);
                          }}>
                          {fixName(suggestion.first_name, suggestion.last_name)}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    noResults &&
                    props.userSearchQry && (
                      <div className="text-center mt-3">No user found.</div>
                    )
                  )}
                </div>

                <div
                  className="template-text as-link"
                  style={{
                    marginBottom: '10px',
                  }}>
                  Add CC
                </div>

                <div className="template-flex payment-schedule">
                  <span>Issue Date</span>

                  <div className="as-link" onClick={handleClickD}>
                    {invoiceDate === '' ? (
                      <span className="template-text">
                        {' '}
                        <i className="fa-solid fa-calendar-days" />
                      </span>
                    ) : (
                      <span className="template-text">
                        {Moment(invoiceDate).format('MMM DD, YYYY').toString()}
                      </span>
                    )}
                  </div>

                  <div className="invoice_calender-wrapper">
                    {isOpen && (
                      <Calender
                        minDate={new Date()}
                        maxDate={dueDate && new Date(dueDate)}
                        setSelectedDate={handleChange}
                        selectedDate={invoiceDate ? new Date(invoiceDate) : ''}
                        showDate={true}
                        showTime={true}
                        type="invoice"
                        // calendarRef={calendarRef}
                        isOpenCalendar={isOpen}
                        setIsOpenCalendar={setIsOpen}
                      />
                    )}
                  </div>
                </div>

                <div className="template-flex payment-schedule">
                  <span>Expiration Date</span>

                  <div className="as-link" onClick={handleClickE}>
                    {dueDate === '' ? (
                      <span className="template-text">
                        {' '}
                        <i className="fa-solid fa-calendar-days" />
                      </span>
                    ) : (
                      <span className="template-text">
                        {Moment(dueDate).format('MMM DD, YYYY').toString()}
                      </span>
                    )}
                  </div>

                  <div className="invoice_calender-wrapper">
                    {endIsOpen && (
                      <Calender
                        minDate={new Date()}
                        maxDate={getMaxDateFromToday}
                        setSelectedDate={handleChange}
                        selectedDate={dueDate ? new Date(dueDate) : ''}
                        showDate={true}
                        showTime={true}
                        type="invoice"
                        // calendarRef={calendarRef}
                        isOpenCalendar={endIsOpen}
                        setIsOpenCalendar={setEndIsOpen}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {type == 'standard' && (
          <>
            <div className="roi-form-input">
              <Form.Control
                type="text"
                value={standardName}
                pattern="^\S.*$"
                required
                placeholder="Title"
                onChange={e => {
                  setStandardName(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Title is required.
              </Form.Control.Feedback>
            </div>
            <div className="billing-detail mb-3">
              <h1
                className="pt-2 pb-2 as-link"
                onClick={() => {
                  setIsExpandedBilling(!isExpandedBilling);
                }}>
                Billing Details
              </h1>

              <div
                className={`transition-all duration-300 ease-in-out overflow-hidden ${
                  isExpandedBilling ? 'max-h-screen' : 'max-h-0'
                }`}>
                <hr class="border-t border-gray-300 pb-3"></hr>
                <div className="roi-form-input">
                  <Form.Control
                    type="text"
                    // value={invoiceService}
                    pattern="^\S.*$"
                    // required
                    placeholder="Search for contact"
                    value={props.userSearchQry}
                    onChange={e => {
                      get_users_for_invoices(e.target.value);
                      props.setUserSearchQry(e.target.value);
                    }}
                  />
                  {props.userSearchQry && props.userDetail.length > 0 ? (
                    <ul
                      className="custom-suggestions invoices_suggestion"
                      ref={props.dropdownRef}>
                      {props.userDetail.map(suggestion => (
                        <li
                          className="as-link"
                          key={'suggestion' + suggestion.id}
                          onClick={() => {
                            props.handleStandardUsers(suggestion);
                          }}>
                          {fixName(suggestion.first_name, suggestion.last_name)}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    noResults &&
                    props.userSearchQry && (
                      <div className="text-center mt-3">No user found.</div>
                    )
                  )}
                </div>

                <div
                  className="template-text as-link"
                  style={{
                    marginBottom: '10px',
                  }}>
                  Add CC
                </div>

                <div className="template-flex payment-schedule">
                  <span>Issue Date</span>

                  <div className="as-link" onClick={handleClickD}>
                    {invoiceDate === '' ? (
                      <span className="template-text">
                        {' '}
                        <i className="fa-solid fa-calendar-days" />
                      </span>
                    ) : (
                      <span className="template-text">
                        {Moment(invoiceDate).format('MMM DD, YYYY').toString()}
                      </span>
                    )}
                  </div>

                  <div className="invoice_calender-wrapper">
                    {isOpen && (
                      <Calender
                        minDate={new Date()}
                        maxDate={dueDate && new Date(dueDate)}
                        setSelectedDate={handleChange}
                        selectedDate={invoiceDate ? new Date(invoiceDate) : ''}
                        showDate={true}
                        showTime={true}
                        type="invoice"
                        // calendarRef={calendarRef}
                        isOpenCalendar={isOpen}
                        setIsOpenCalendar={setIsOpen}
                      />
                    )}
                  </div>
                </div>

                <div className="template-flex payment-schedule">
                  <span>Expiration Date</span>

                  <div className="as-link" onClick={handleClickE}>
                    {dueDate === '' ? (
                      <span className="template-text">
                        {' '}
                        <i className="fa-solid fa-calendar-days" />
                      </span>
                    ) : (
                      <span className="template-text">
                        {Moment(dueDate).format('MMM DD, YYYY').toString()}
                      </span>
                    )}
                  </div>

                  <div className="invoice_calender-wrapper">
                    {endIsOpen && (
                      <Calender
                        minDate={new Date()}
                        maxDate={getMaxDateFromToday}
                        setSelectedDate={handleChange}
                        selectedDate={dueDate ? new Date(dueDate) : ''}
                        showDate={true}
                        showTime={true}
                        type="invoice"
                        // calendarRef={calendarRef}
                        isOpenCalendar={endIsOpen}
                        setIsOpenCalendar={setEndIsOpen}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* <div className="create-ticket-tabs mt-0">
          <CustomTabs
            darkmode
            tabs={TogleButtons}
            active={capitalizeWords(type)}
           
            onSelect={item => {
              console.log(item, 'itemitemitem');
              if (type === 'comparison') {
                setType(item.toLowerCase());
                // handleResetDraft('standard');
              } else {
                handleReset();
                setType(item.toLowerCase());
                // handleResetDraft('comparison');
              }
              // handleReset();
              // setType(item);
              // handleResetDraft('comparison');
            }}
          />
        </div> */}
        {props.showButtons()}

        {type !== 'standard' && Object.entries(comparisonList).length > 0
          ? comparisonList.map((item, index) => (
              <ComparisonItem
                key={`comaparison-${index}`}
                item={item}
                redraw={redraw}
                ItemIndex={index}
                comparisonList={comparisonList}
                setComparisonList={setComparisonList}
                comparisonDraft={comparisonDraft}
                selectedItems={
                  props.showListing ?
                  (comparisonDraft.length > 0 &&
                  comparisonDraft[index]?.catalog_details &&
                  comparisonDraft[index].catalog_details.length > 0
                    ? comparisonDraft[index].catalog_details
                    : [])
                    : (comparisonList[index]?.catalog_details?.length > 0 ?
                    comparisonList[index]?.catalog_details : [])
                }
                handleUpdateList={handleUpdateList}
                setItemToUpdate={props.setComparisonItem}
                setShowComponent={props.setShowComponent}
                handleUpdateItem={props.handleUpdateItem}
                deleteCatalogItem={props.deleteCatalogItem}
                setDeleteCatalogItem={props.setCatalogAddOnId}
                checkPermesionOfCreate={props.checkPermesionOfCreate}
                outOfStockItems={outOfStockItems}
                setOutOfStockItems={setOutOfStockItems}
                builders={props.comparsionUsers}
                expensesList={props.expensesList}
              />
            ))
          : type !== 'standard' && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{height: '20vh'}}>
                <div>
                  <Button
                    className="new-btn btn-green "
                    onClick={() => {
                      props.setShowComponent('comparisonModal');
                    }}>
                    Add Comparison
                  </Button>
                </div>
              </div>
            )}
        {type === 'standard' &&
          Object.entries(standardList).length > 0 &&
          standardList.map((item, index) => (
            <ComparisonItem
              type={'standard'}
              item={item}
              redraw={redraw}
              ItemIndex={index}
              comparisonList={standardList}
              setComparisonList={setStandardList}
              comparisonDraft={standardDraft}
              selectedItems={
                standardDraft.length > 0 &&
                standardDraft[index]?.catalog_details &&
                standardDraft[index].catalog_details.length > 0
                  ? standardDraft[index].catalog_details
                  : []
              }
              handleUpdateItem={props.handleUpdateItem}
              setShowComponent={props.setShowComponent}
              deleteCatalogItem={props.deleteCatalogItem}
              setDeleteCatalogItem={props.setDeleteCatalogItem}
              outOfStockItems={outOfStockItems}
              setOutOfStockItems={setOutOfStockItems}
              builders={props.standardUsers}
              checkPermesionOfCreate={props.checkPermesionOfCreate}
              expensesList={props.expensesList}
            />
          ))}

        {type === 'standard' && standardList.length > 0 && (
          <>
            <button
              style={{
                color: '#68b245',
              }}
              className="as-link d-block mb-3 "
              onClick={() => {
                props.setShowComponent('standardModal');
              }}>
              Add Add-on Option
            </button>

            {/* <div
                className="more-option template-text as-link fit_content mb-3"
                onClick={() => {
                  setMoreOptions(!moreOptions);
                }}>
                More Options
              </div> */}
          </>
        )}

        <div style={{display: 'flex', justifyContent: 'center'}}>
          {type !== 'standard' && (
            <button
              style={{
                border: '1px solid #C8C8C8',
                padding: '13px',
                marginRight: '30px',
                fontWeight: 'bold',
                width: '100%',
              }}
              className="as-link d-block mb-3 "
              onClick={() => {
                props.setShowComponent('comparisonModal');
              }}>
              Add Another Comparison
            </button>
          )}
        </div>
        {type === 'standard' && (
          <div
            className="billing-detail"
            style={{backgroundColor: '#EDEBE5', marginBottom: '8px'}}>
            <h1
              className="pt-2 pb-2 as-link"
              onClick={() => {
                setExpandOfferings(!expandOfferings);
              }}>
              Offerings
            </h1>
            <div
              className={`transition-all duration-300 ease-in-out overflow-hidden  mt-2 ${
                expandOfferings ? 'max-h-100' : 'max-h-0'
              }`}>
              <hr class="border-t border-gray-300 pb-3"></hr>
              <div className="roi-form-input">
                <Form.Control
                  type="text"
                  // value={invoiceService}
                  pattern="^\S.*$"
                  // required
                  placeholder="Add product or service"
                  value={showSearchOfferings}
                  // ref={inputRef}
                  onChange={e => {
                    setShowSearchOfferings(e.target.value);
                    get_catalogs_for_invoices(e.target.value, 'offerings');
                  }}
                />
                {showSearchOfferings && (
                  <ul
                    className="custom-suggestions invoices_suggestion"
                    ref={dropdownRef}>
                    {props.checkPermesionOfCreate && (
                      <li
                        className="as-link"
                        key={'Add-offerings'}
                        onClick={() => {
                          props.setShowComponent('createCatalog');
                        }}>
                        Add offerings
                      </li>
                    )}
                    {offeringsDetail.length > 0 &&
                      offeringsDetail.map((suggestion, index) => (
                        <li
                          className="as-link"
                          key={index + '-' + suggestion.id}
                          onClick={() => {
                            objectValues(suggestion, 'offerings');
                          }}>
                          {capitalizeFirst(suggestion.title)}
                        </li>
                      ))}
                  </ul>
                )}
              </div>

              {showSearchOfferings && noResults && (
                <NorecordFound
                  noRecordData={NoOfferingsFound}
                  buttonPermesion={props.checkPermesionOfCreate}
                  ButtonAction={() => {
                    props.setShowComponent('createCatalog');
                  }}
                  btnRef={offeringButtonRef}
                />
              )}
              {props.selectedOfferings.length > 0 &&
                props.selectedOfferings.map((item, index) => (
                  <div
                    style={{
                      padding: '12px',
                      borderRadius: '5px',
                      marginBottom: '8px',
                    }}>
                    <InvoiceProduct
                      type={'offerings'}
                      item={item}
                      ItemIndex={index}
                      setSelectedProduct={props.setSelectedOfferings}
                      handleDataChange={handleDataChange}
                      selectedProduct={props.selectedOfferings}
                      setShowComponent={props.setShowComponent}
                      setDeleteCatalog={props.setDeleteEstimateOfferings}
                      showErrorFromApi={showErrorFromApi}
                      showOutOfStockError={showOutOfStockError}
                      outOfStockItems={outOfStockItems}
                      expensesList={expensesList}
                    />
                  </div>
                ))}
              {props.selectedOfferings.length > 0 && (
                <>
                  <div className="template-flex-total">
                    <span className="template-text">Total</span>

                    <span>${calculateTotal(props.selectedOfferings)}</span>
                  </div>

                  <div className="template-flex payment-schedule">
                    <span>Payment Schedule</span>

                    <span className="template-text">
                      {props.selectedOfferings.length > 0 &&
                      checkSubscriptionType(props.selectedOfferings) ===
                        'monthly'
                        ? 'Recurring Monthly'
                        : checkSubscriptionType(props.selectedOfferings)}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        <div className="billing-detail" style={{backgroundColor: '#EDEBE5'}}>
          <h1
            className="pt-2 pb-2 as-link"
            onClick={() => {
              setIsExpandedInvoice(!isExpandedInvoice);
            }}>
            Add-ons
          </h1>
          <div
            className={`transition-all duration-300 ease-in-out overflow-hidden  mt-2 ${
              isExpandedInvoice ? 'max-h-100' : 'max-h-0'
            }`}>
            <hr class="border-t border-gray-300 pb-3"></hr>
            <div className="roi-form-input">
              <Form.Control
                type="text"
                // value={invoiceService}
                pattern="^\S.*$"
                // required
                placeholder="Add product or service"
                value={showSearchProduct}
                // ref={inputRef}
                onChange={e => {
                  setShowSearchProduct(e.target.value);
                  get_catalogs_for_invoices(e.target.value, 'add-ons');
                }}
              />
              {showSearchProduct && (
                <ul
                  className="custom-suggestions invoices_suggestion"
                  ref={dropdownRef}>
                  {props.checkPermesionOfCreate && (
                    <li
                      className="as-link"
                      key={'Add-offerings'}
                      onClick={() => {
                        props.setShowComponent('createCatalog');
                      }}>
                      Add offerings
                    </li>
                  )}
                  {productDetail.length > 0 &&
                    productDetail.map((suggestion, index) => (
                      <li
                        className="as-link"
                        key={index + '-' + suggestion.id}
                        onClick={() => {
                          objectValues(suggestion, 'add_ons');
                        }}>
                        {capitalizeFirst(suggestion.title)}
                      </li>
                    ))}
                </ul>
              )}
            </div>

            {showSearchProduct && noResults && (
              <NorecordFound
                noRecordData={NoOfferingsFound}
                buttonPermesion={props.checkPermesionOfCreate}
                ButtonAction={() => {
                  props.setShowComponent('createCatalog');
                }}
                btnRef={offeringButtonRef}
              />
            )}
            {props.selectedAddOnProduct.length > 0 &&
              props.selectedAddOnProduct.map((item, index) => (
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: '12px',
                    borderRadius: '5px',
                    marginBottom: '8px',
                  }}>
                  <InvoiceProduct
                    type={'add_on'}
                    item={item}
                    ItemIndex={index}
                    setSelectedProduct={props.setSelectedAddOnProduct}
                    handleDataChange={handleDataChange}
                    selectedProduct={props.selectedAddOnProduct}
                    setShowComponent={props.setShowComponent}
                    setDeleteCatalog={props.setDeleteAddOnCatalog}
                    showErrorFromApi={showErrorFromApi}
                    showOutOfStockError={showOutOfStockError}
                    outOfStockItems={outOfStockItems}
                    expensesList={expensesList}
                  />
                </div>
              ))}
            {props.selectedAddOnProduct.length > 0 && (
              <>
                <div
                  style={{backgroundColor: 'transparent'}}
                  className="template-flex-total">
                  <span className="template-text">Total</span>

                  <span>${calculateTotal(props.selectedAddOnProduct)}</span>
                </div>

                <div className="template-flex payment-schedule">
                  <span>Payment Schedule</span>

                  <span className="template-text">
                    {props.selectedAddOnProduct.length > 0 &&
                    checkSubscriptionType(props.selectedAddOnProduct) ===
                      'monthly'
                      ? 'Recurring Monthly'
                      : checkSubscriptionType(props.selectedAddOnProduct)}
                  </span>
                </div>
              </>
            )}
          </div>

          {/* {selectedAddOnProduct.length > 0 &&
                                    !moreOptions && (
                                      <div
                                        className="more-option template-text as-link fit_content"
                                        onClick={() => {
                                          setMoreOptions(!moreOptions);
                                        }}>
                                        More Options
                                      </div>
                                    )} */}

          {/* {selectedAddOnProduct.length > 0 &&
                                    moreOptions && (
                                      <div
                                        className="more-option template-text as-link mb-3"
                                        onClick={() => {
                                          setMoreOptions(!moreOptions);
                                        }}>
                                        Less Options
                                      </div>
                                    )} */}
        </div>

        {/* { type !== 'Standard' &&  comparisonList.length > 0 && (
          <>
            <button
              style={{
                color: '#68b245',
              }}
              className="as-link d-block mb-3 "
              onClick={() => {
                props.setShowComponent('comparisonModal');
                // addComparison(comparisonTitle);
              }}>
              Add Comparison
            </button>
          </>
        )} */}

        <div className="billing-detail mt-3">
          <h1
            className="pt-2 pb-2 as-link"
            onClick={() => {
              setIsExpandedNOtes(!isExpandedNOtes);
            }}>
            Notes
          </h1>

          <div
            className={`transition-all duration-300 ease-in-out overflow-hidden ${
              isExpandedNOtes ? 'max-h-screen' : 'max-h-0'
            }`}>
            <>
              <Form.Group className="fieldset mt-2">
                <Form.Control
                  as="textarea"
                  required
                  rows={4}
                  // pattern="^\S.*$"
                  placeholder="Note to Customer"
                  value={note}
                  onChange={e => {
                    setNote(e.target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Feedback is required.
                </Form.Control.Feedback>
              </Form.Group>
            </>
          </div>
        </div>
        <div className="billing-detail mt-3">
          <h1
            className="pt-2 pb-2 as-link"
            onClick={() => {
              setIsExpandedMoreOption(!isExpandedMoreOption);
            }}>
            More Option
          </h1>

          <div
            className={`transition-all duration-300 ease-in-out overflow-hidden ${
              isExpandedMoreOption ? 'max-h-screen' : 'max-h-0'
            }`}>
            <>
              <Form.Group className="fieldset mt-2">
                <Form.Control
                  as="textarea"
                  required
                  rows={4}
                  // pattern="^\S.*$"
                  placeholder="Terms & conditions"
                  // value={note}
                  onChange={e => {
                    // setNote(e.target.value);
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Terms & conditions is required.
                </Form.Control.Feedback>
              </Form.Group>

              <div className="roi-form-input">
                <Form.Label controlid="validationCustom01">
                  <h1>Reference</h1>
                </Form.Label>
                <Form.Control
                  type="text"
                  pattern="^\S.*$"
                  placeholder="Enter reference"
                />
              </div>
              <div className="d-flex align-items-center justify-content-between mt-2">
                <h1>Allow tip</h1>
                <div>
                  <Form.Check type="switch" id="custom-switchy" />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-2">
                <h1>Allow partial payment</h1>
                <div>
                  <Form.Check type="switch" id="custom-switchy" />
                </div>
              </div>
            </>
          </div>
        </div>
        <div className="billing-detail mt-3 mb-3">
          <h1
            className="pt-2 pb-2 as-link"
            onClick={() => {
              setIsExpandedAttachment(!isExpandedAttachment);
            }}>
            Add attachment
          </h1>

          <div
            className={`transition-all duration-300 ease-in-out overflow-hidden ${
              isExpandedAttachment ? 'max-h-screen' : 'max-h-0'
            }`}>
            <>
              <Upload
                uploadFilesListing={[]}
                setUploadFilesListing={[]}
                handleFiles={[]}
                setShowFileComponent={[]}
                type={'contact_attachment'}
              />
            </>
          </div>
        </div>

        {type !== 'standard' && comparisonList.length > 0 && (
          <div className="template-btns">
            <div>
              <Button
                className="new-btn btn-green "
                onClick={() => {
                  handleComparisonSubmit('');
                }}>
                Send Estimates
              </Button>
            </div>

            <button
              style={{
                color: '#68b245',
              }}
              className="as-link "
              onClick={() => {
                handleComparisonSubmit('template');
              }}>
              Save As Template
            </button>

            <button
              style={{
                color: '#68b245',
              }}
              className="as-link "
              onClick={() => {
                handleComparisonSubmit('draft');
              }}>
              Save as Draft
            </button>
          </div>
        )}
        {type === 'standard' && props.selectedOfferings.length > 0 && (
          <div className="template-btns">
            <div>
              <Button
                className="new-btn btn-green "
                onClick={() => {
                  handleStandardSubmit('');
                }}>
                Send Estimates
              </Button>
            </div>

            <button
              style={{
                color: '#68b245',
              }}
              className="as-link "
              onClick={() => {
                handleStandardSubmit('template');
              }}>
              Save As Template
            </button>

            <button
              style={{
                color: '#68b245',
              }}
              className="as-link "
              onClick={() => {
                handleStandardSubmit('draft');
              }}>
              Save as Draft
            </button>
          </div>
        )}
      </div>

      {type == 'standard' && (
        <div className="estimate">
          {/* {standardList.length > 0 && ( */}
          <></>
          {/* )} */}

          {/* <div className="create-ticket-tabs mt-0">
            <CustomTabs
              darkmode
              tabs={TogleButtons}
              active={type}
              onSelect={item => {
                console.log(item, 'itemitemitem');
                // handleReset();
                setType(item);
                handleResetDraft('standard');
              }}
            />
          </div> */}

          {/* <div className="roi-form-input">
            <Form.Control
              type="text"
              // value={invoiceService}
              pattern="^\S.*$"
              // required
              placeholder="Search user"
              value={props.userSearchQry}
              onChange={e => {
                get_users_for_invoices(e.target.value);
                props.setUserSearchQry(e.target.value);
              }}
            />
            {props.userSearchQry && props.userDetail.length > 0 ? (
              <ul
                className="custom-suggestions invoices_suggestion"
                ref={props.dropdownRef}>
                {props.userDetail.map(suggestion => (
                  <li
                    className="as-link"
                    key={'suggestion' + suggestion.id}
                    onClick={() => {
                      props.handleStandardUsers(suggestion);
                    }}>
                    {fixName(suggestion.first_name, suggestion.last_name)}
                  </li>
                ))}
              </ul>
            ) : (
              noResults &&
              props.userSearchQry && (
                <div className="text-center mt-3">No user found.</div>
              )
            )}
          </div>

          <div className="roi-form-input">
            <Form.Control
              type="text"
              value={standardName}
              pattern="^\S.*$"
              required
              placeholder="Title"
              onChange={e => {
                setStandardName(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Title is required.
            </Form.Control.Feedback>
          </div> */}

          {/* {Object.entries(standardList).length > 0 ? (
            standardList.map((item, index) => (
              <ComparisonItem
                type={'standard'}
                item={item}
                redraw={redraw}
                ItemIndex={index}
                comparisonList={standardList}
                setComparisonList={setStandardList}
                comparisonDraft={standardDraft}
                selectedItems={
                  standardDraft.length > 0 &&
                  standardDraft[index]?.catalog_details &&
                  standardDraft[index].catalog_details.length > 0
                    ? standardDraft[index].catalog_details
                    : []
                }
                handleUpdateItem={props.handleUpdateItem}
                setShowComponent={props.setShowComponent}
                deleteCatalogItem={props.deleteCatalogItem}
                setDeleteCatalogItem={props.setDeleteCatalogItem}
                outOfStockItems={outOfStockItems}
                setOutOfStockItems={setOutOfStockItems}
                builders={props.standardUsers}
                checkPermesionOfCreate={props.checkPermesionOfCreate}
                expensesList={props.expensesList}
              />
            ))
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{height: '20vh'}}>
              <div>
                <Button
                  className="new-btn btn-green "
                  onClick={() => {
                    props.setShowComponent('standardModal');
                  }}>
                  Add Add-on Option
                </Button>
              </div>
            </div>
          )}

          {standardList.length > 0 && (
            <>
              <button
                style={{
                  color: '#68b245',
                }}
                className="as-link d-block mb-3 "
                onClick={() => {
                  props.setShowComponent('standardModal');
                }}>
                Add Add-on Option
              </button>

              <div
                className="more-option template-text as-link fit_content mb-3"
                onClick={() => {
                  setMoreOptions(!moreOptions);
                }}>
                More Options
              </div>
            </>
          )}
           {standardList.length > 0 && (
            <div className="template-btns">
              <div>
                <Button
                  className="new-btn btn-green "
                  onClick={() => {
                    handleStandardSubmit('');
                  }}>
                  Send Estimates
                </Button>
              </div>

              <button
                style={{
                  color: '#68b245',
                }}
                className="as-link "
                onClick={() => {
                  handleStandardSubmit('template');
                }}>
                Save As Template
              </button>

              <button
                style={{
                  color: '#68b245',
                }}
                className="as-link "
                onClick={() => {
                  handleStandardSubmit('draft');
                }}>
                Save as Draft
              </button>
            </div>
          )} */}

          {/* {moreOptions && standardList.length > 0 && (
            <>
              <div className="template-flex payment-schedule">
                <span>Delivery Date And Time</span>

                <div onClick={handleClickD}>
                  {invoiceDate === '' ? (
                    <span className="template-text">
                      {' '}
                      <i className="fa-solid fa-calendar-days" />
                    </span>
                  ) : (
                    <span className="template-text">
                      {Moment(invoiceDate)
                        .format('MMM D, YYYY h:mm A')
                        .toString()}
                    </span>
                  )}
                </div>

                <div className="invoice_calender-wrapper">
                  {isOpen && (
                    // <DatePicker
                    //   selected={invoiceDate}
                    //   onChange={handleChange}
                    //   onClickOutside={() => {
                    //     handleOnClickOutside();
                    //   }}
                    //   showTimeSelect
                    //   timeIntervals={1}
                    //   minDate={new Date()}
                    //   maxDate={dueDate}
                    //   inline
                    // />

                    <Calender
                      minDate={new Date()}
                      maxDate={dueDate && new Date(dueDate)}
                      setSelectedDate={handleChange}
                      selectedDate={invoiceDate ? new Date(invoiceDate) : ''}
                      showDate={true}
                      showTime={true}
                      type="invoice"
                      // calendarRef={calendarRef}
                      isOpenCalendar={isOpen}
                      setIsOpenCalendar={setIsOpen}
                    />
                  )}
                </div>
              </div>

              <div className="template-flex payment-schedule">
                <span>Expiration Date And Time</span>

                <div onClick={handleClickE}>
                  {dueDate === '' ? (
                    <span className="template-text">
                      {' '}
                      <i className="fa-solid fa-calendar-days" />
                    </span>
                  ) : (
                    <span className="template-text">
                      {Moment(dueDate).format('MMM D, YYYY h:mm A').toString()}
                    </span>
                  )}
                </div>

                <div className="invoice_calender-wrapper">
                  {endIsOpen && (
                    // <DatePicker
                    //   selected={dueDate}
                    //   onChange={handleChange}
                    //   onClickOutside={() => {
                    //     handleOnClickOutside();
                    //   }}
                    //   showTimeSelect
                    //   timeIntervals={1}
                    //   minDate={new Date()}
                    //   inline
                    // />
                    <Calender
                      minDate={new Date()}
                      maxDate={getMaxDateFromToday}
                      setSelectedDate={handleChange}
                      selectedDate={dueDate ? new Date(dueDate) : ''}
                      showDate={true}
                      showTime={true}
                      type="invoice"
                      // calendarRef={calendarRef}
                      isOpenCalendar={endIsOpen}
                      setIsOpenCalendar={setEndIsOpen}
                    />
                  )}
                </div>
              </div>
            </>
          )} */}

          {/* {standardList.length > 0 && (
            <div className="template-btns">
              <div>
                <Button
                  className="new-btn btn-green "
                  onClick={() => {
                    handleStandardSubmit('');
                  }}>
                  Send Estimates
                </Button>
              </div>

              <button
                style={{
                  color: '#68b245',
                }}
                className="as-link "
                onClick={() => {
                  handleStandardSubmit('template');
                }}>
                Save As Template
              </button>

              <button
                style={{
                  color: '#68b245',
                }}
                className="as-link "
                onClick={() => {
                  handleStandardSubmit('draft');
                }}>
                Save as Draft
              </button>
            </div>
          )} */}
        </div>
      )}
    </>
  );
};

export default InvoiceEstimate;

import React, {useState, useRef, useEffect} from 'react';
import moment from 'moment';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {FireIcon, MachineIcon, layers} from '../../Constants/Images';
import {Row} from 'react-bootstrap';
import {
  calculateDatePercentage,
  fixName,
  truncateTitle,
} from '../../Utils/commonUtils';
import {DEFAULT_IMAGE} from '../../Utils/env';
const DateGraph = ({
  projectListingBoardData,
  selectMileStoneId,
  startFrom,
  startEnd,
  type,
  setShowDetail,
  showDetail,
  setShowTagsModal,
  setActions
}) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showSelectedEvent, setShowSelectedEvent] = useState(false);
  const [showMilestoneEvent, setShowMilestoneEvent] = useState(false);
  const [selectedMilestoneEvent, setSelectedMilestoneEvent] = useState(null);

  const MergeDeliverTask = [
    ...projectListingBoardData.tasks,
    ...projectListingBoardData.deliverables,
  ];

  console.log(MergeDeliverTask, 'MergeDeliverTaskMergeDeliverTask');

  const milestoneRef = useRef(null);
  const [milestoneHeight, setMilestoneHeight] = useState(0);

  const tagsListing =
    projectListingBoardData?.tag_titles?.length > 0
      ? projectListingBoardData?.tag_titles?.slice(0, 2)
      : [];

  console.log(
    projectListingBoardData,
    '=======>>>>>.projectListingBoardDataprojectListingBoardData',
  );

  const showTeamsAlias =
    projectListingBoardData?.team_alias_detail_users?.length > 0
      ? projectListingBoardData?.team_alias_detail_users.slice(0, 2)
      : [];

  useEffect(() => {
    setShowSelectedEvent(false);
    setShowMilestoneEvent(false);
  }, [type, selectMileStoneId]);

  useEffect(() => {
    // Check if milestoneRef is defined, then set the height
    setTimeout(() => {
      if (milestoneRef.current) {
        // Get the height and add marginTop and marginBottom if applicable
        const rect = milestoneRef.current.getBoundingClientRect();
        const computedStyle = window.getComputedStyle(milestoneRef.current);

        const totalHeight =
          rect.height +
          parseFloat(computedStyle.marginTop) +
          parseFloat(computedStyle.marginBottom);

        setMilestoneHeight(totalHeight);
      }
    }, 1500);
  }, [projectListingBoardData, startFrom, startEnd]);

  const onBarClick = (event, offset, top) => {
    if (selectedEvent && selectedEvent?.id === event.id) {
      setSelectedEvent(null);
      setActions(null);
      setShowSelectedEvent(false);
    } else {
      setSelectedEvent({...event, top: top + 25, left: offset});
      setActions({...event, top: top + 25, left: offset});
      setShowSelectedEvent(true);
      setShowMilestoneEvent(false);
    }
  };

  const calculateTotalDays = (startDate, endDate) => {
    const totalMilliseconds = endDate - startDate;
    return Math.floor(totalMilliseconds / (1000 * 60 * 60 * 24));
  };
  let topOffset = 35;

  console.log(selectedEvent, '====>>>>selectedEventselectedEvent')
  // Initialize the top offset globally to manage stacking

  const renderEvents = (event, i, type) => {
    console.log(event.type, '===>>>>eventevent', i)
    // Calculate the total number of days in the range
    const totalDays = Math.ceil(
      (new Date(startEnd) - new Date(startFrom)) / (1000 * 60 * 60 * 24),
    );

    // Calculate pixel width per day dynamically based on the timeline width (each slot is 133px for 7 days)
    const pxPerDay = 126 / 7; // You can adjust this or make it dynamic if needed

    const firstSlotDate = new Date(startFrom); // Start of timeline
    const endSlotDate = new Date(startEnd); // End of timeline

    const startDate =
      event?.type === 'task'
        ? new Date(event.task_start_date)
        : new Date(event.delivery_start_date);

    const endDate =
      event?.type === 'task'
        ? new Date(event.task_due_date)
        : new Date(event.delivery_end_date);

    // Check if the event falls within the date range
    if (startDate < firstSlotDate || endDate > endSlotDate) {
      return null; // Skip rendering if outside the range
    }

    // Calculate the day difference from `firstSlotDate` for offset
    const daysFromStart = Math.floor(
      (startDate - firstSlotDate) / (1000 * 60 * 60 * 24),
    );
    const offset = daysFromStart * pxPerDay; // Dynamic left offset in pixels

    // Calculate event duration in days for width
    const eventDurationDays = Math.ceil(
      (endDate - startDate) / (1000 * 60 * 60 * 24),
    );
    const width = eventDurationDays * pxPerDay; // Dynamic width in pixels

    // Track vertical stacking for each deliverable
    const currentTop = topOffset;
    topOffset += 50; // Increment for each deliverable to stack vertically

    return (
      <div
        key={`event-${event.id}-${i}`}
        className="project-line-bar as-link"
        onClick={() => onBarClick(event, offset, currentTop)}
        style={{
          left: `${offset}px`, // Offset in pixels from start
          width: `${width}px`, // Width in pixels for event duration
          height: '20px',
          backgroundColor:
            selectedEvent?.id === event.id ? '#68C559' : '#AEABA0',
          top: `${currentTop}px`, // Vertical position
        }}></div>
    );
  };

  const handleCalculateHeight = (deliverableArray, type) => {
    // Start of timeline
    const firstSlotDate = new Date(startFrom); // Start of timeline
    const endSlotDate = new Date(startEnd);

    let count = 0; // Initialize count to 0

    deliverableArray.forEach(event => {
      const startDate =
      event?.task_start_date 
        ? new Date(event.task_start_date)
        : new Date(event.delivery_start_date);

    const endDate =
    event?.task_due_date
        ? new Date(event.task_due_date)
        : new Date(event.delivery_end_date);;
      // Check if the event falls within the date range
      if (startDate >= firstSlotDate && endDate <= endSlotDate) {
        count++; // Increment count if condition is met
      }
    });
    console.log(count, 'countcountcountcount');

    // Return the total count after loop completes
    return count;

    // Loop through the array of deliverables
  };

  const generateDateSlots = () => {
    const start = new Date(startFrom); // Start from today
    const end = new Date(startEnd); // End date as specified

    const lines = [];
    const days = calculateTotalDays(start, end); // Total days from today to the end date
    const interval = 7; // Interval in days

    for (let i = 0; i <= days; i += interval) {
      // Create a new date incremented by `i` days from the start
      const currentDate = new Date(start);
      currentDate.setDate(start.getDate() + i);

      // Format the date
      const data = moment(currentDate).format('D MMM YY').toString();

      // Push date slot to lines array
      lines.push(
        <div className="tasks-listing" key={`slot-${i}`}>
          <div className="date">{data}</div>
          <div className="activity-line"></div>
        </div>,
      );
    }

    return lines;
  };
  const ShowMilestoneBar = (milestone, i, type,seprateData) => {
    const firstSlotDate = new Date(startFrom); // Start of timeline
    const endSlotDate = new Date(startEnd); // End of timeline
  
    // Function to check if the deliverable/task falls within the timeline
    const isWithinDateRange = (event) => {
      if (!event) return false;
  
      const startDate =
        event?.type === "task"
          ? new Date(event.task_start_date)
          : new Date(event.delivery_start_date);
  
      const endDate =
        event?.type === "task"
          ? new Date(event.task_due_date)
          : new Date(event.delivery_end_date);
  
      return startDate >= firstSlotDate && endDate <= endSlotDate;
    };
  
    // Determine if this milestone should be displayed
    const shouldShow = (type === 'mix' || type === "justTask")
    ? milestone // If `type` is true, show milestone directly
    : milestone.deliverables?.some(isWithinDateRange);
  
    if (!shouldShow) return null; // Return null if no matching milestone
  
    return (
      <div
        key={milestone.id}
        className="rounded shadow font-bold text-lg as-link"
        style={{
          width: "2px",
          height: `${handleCalculateHeight(
            type ? seprateData : milestone.deliverables,
            type === 'mix' && true
          ) * 45}px`,
          padding: "5px",
          marginTop: "10px",
          backgroundColor:type === 'justTask' ? 'green': type  ? "gray" : "#C945DF",
        }}
        onClick={() => {
          if ( type !== 'mix') {
            showMilestoneData(milestone, i)
          }  }}
      ></div>
    );
  };
  

  const showMilestoneData = (event, i) => {
    const currentTop = i * 50;

    // Update selected milestone data with height
    if (selectedMilestoneEvent && selectedMilestoneEvent?.id === event.id) {
      setSelectedMilestoneEvent(null);
      setShowMilestoneEvent(false);
    } else {
      setSelectedMilestoneEvent({...event, top: currentTop}); // Include height
      setShowMilestoneEvent(true);
      setShowSelectedEvent(false);
    }
  };

  const handleDetail = () => {
    if(selectedEvent.type === "task"){
      setShowDetail('open_task_detail');
    }else{
    setShowDetail('open_project_detail');
    }
  };

  const showProgressBar = calculateDatePercentage(
    selectedEvent?.delivery_start_date,
    selectedEvent?.delivery_end_date,
  );
  const showMilestoneProgressBar = calculateDatePercentage(
    selectedMilestoneEvent?.milestone_start_date,
    selectedMilestoneEvent?.milestone_end_date,
  );

  console.log(
    selectedEvent,
    '=====>>>>>selectedEventselectedEventselectedEvent',
  );

  return (
    <>
      {!showDetail && (
        <div style={{maxHeight: '100vh', width: '100vw', overflow: 'hidden'}}>
          <div
            className="flex flex-row data-graph-vertical-scroll-div"
            style={{overflowY: 'auto', maxHeight: '100vh'}}>
            <div
              className="milestone-container"
              style={{
                marginTop: '43px',
                paddingRight: '6px',
              }}
              id="milestoneHeight"
              ref={milestoneRef}>
              {MergeDeliverTask?.length > 0 &&
        
                 
                    <React.Fragment >
                      {ShowMilestoneBar(MergeDeliverTask, 0, 'mix',MergeDeliverTask)}
                      <></>
                    </React.Fragment>
                  }
                   {projectListingBoardData?.milestones_task?.length > 0 &&
                projectListingBoardData.milestones_task.map((milestone, i) => {
                  if (
                    selectMileStoneId.includes(milestone.id) &&
                    milestone.tasks
                  ) {
                    return (
                      <React.Fragment key={milestone.id}>
                        {ShowMilestoneBar(milestone.tasks, i,'justTask',milestone.tasks)}
                        <></>
                      </React.Fragment>
                    );
                  } else {
                    return null;
                  }
                })}
              {projectListingBoardData?.milestones?.length > 0 &&
                projectListingBoardData.milestones.map((milestone, i) => {
                  if (
                    selectMileStoneId.includes(milestone.id) &&
                    milestone.deliverables
                  ) {
                    return (
                      <React.Fragment key={milestone.id}>
                        {ShowMilestoneBar(milestone, i)}
                        <></>
                      </React.Fragment>
                    );
                  } else {
                    return null;
                  }
                })}
            </div>

            <div
              style={{
                overflowX: 'auto',
                width: '100%',
                overflowY: 'hidden',
                height: `${'900'}px`,
              }}>
              <div className="mt-4 project-graph" style={{width: '100%'}}>
                <div className="flex">
                  {generateDateSlots()}

                  {MergeDeliverTask?.length > 0 &&
                    MergeDeliverTask.map((event, i) => renderEvents(event, i, 'tasks'))}
                  {projectListingBoardData?.milestones?.length > 0 &&
                    projectListingBoardData.milestones.map(milestone => {
                      if (selectMileStoneId.includes(milestone.id)) {
                        return milestone.deliverables.map((event, i) =>
                          renderEvents(event, i, milestone.deliverables),
                        );
                      }
                      return null;
                    })}
                    {projectListingBoardData?.milestones_task?.length > 0 &&
                    projectListingBoardData.milestones_task.map(milestone => {
                      if (selectMileStoneId.includes(milestone.id)) {
                        return milestone.tasks.map((event, i) =>
                          renderEvents(event, i, milestone.tasks),
                        );
                      }
                      return null;
                    })}

                  {showSelectedEvent && (
                    <div
                      style={{
                        top: selectedEvent.top,
                        left: selectedEvent.left,
                      }}
                      className="project-task as-link">
                      <div
                        className="duration"
                        onClick={() => {
                          handleDetail();
                        }}>
                        <span>
                          {moment(selectedEvent.delivery_start_date).format(
                            'DD MMM YY',
                          )}
                        </span>
                        <span>
                          {moment(selectedEvent.delivery_end_date).format(
                            'DD MMM YY',
                          )}
                        </span>
                      </div>

                      <div className="project-task-inner-content">
                        <div className="bar-icon">
                          <div className="bar-main">
                            <ProgressBar
                              className="task-bar"
                              variant="warning"
                              now={showProgressBar ? showProgressBar : 0}
                            />
                          </div>
                          <div className="task-layer-icon">
                            <img src={layers} alt="Layers icon" />
                          </div>
                        </div>

                        <div className="task-detail">
                          <span>{selectedEvent.title}</span>
                          <div style={{display: 'flex'}}>
                            {showTeamsAlias &&
                              showTeamsAlias.length > 0 &&
                              showTeamsAlias.map((item, idx) => (
                                <p style={{marginRight: '5px'}}>
                                  {fixName(item.first_name, item.last_name)}
                                </p>
                              ))}
                          </div>
                        </div>

                        <div className="bottom-image-icon">
                          <div className="task-images">
                            {showTeamsAlias &&
                              showTeamsAlias.length > 0 &&
                              showTeamsAlias.map((item, idx) => (
                                <div className="img-consulting" key={idx}>
                                  {item.profile_img ? (
                                    <img
                                      src={item.profile_img}
                                      alt="Consulting example"
                                    />
                                  ) : (
                                    <img
                                      src={DEFAULT_IMAGE}
                                      alt="Consulting example"
                                    />
                                  )}
                                </div>
                              ))}
                            {projectListingBoardData?.team_alias_detail_users
                              ?.length > 2 && (
                              <div
                                // onClick={() => setShowTagsModal(true)}
                                className="dots">
                                ...
                              </div>
                            )}
                          </div>

                          <div className="task-icons">
                            <Row>
                              {tagsListing?.length > 0 &&
                                tagsListing?.map((icon, idx) => (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flex: '1 1',
                                      paddingTop: '18px',
                                    }}
                                    key={idx}>
                                    <span>{truncateTitle(icon, 1, 2)}</span>
                                  </div>
                                ))}
                              {projectListingBoardData?.tag_titles?.length >
                                2 && (
                                <div
                                  onClick={() => setShowTagsModal(true)}
                                  className="dots">
                                  ...
                                </div>
                              )}
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {showMilestoneEvent && (
                    <div
                      style={{
                        top: selectedMilestoneEvent.top,
                        left: '10px',
                      }}
                      className="project-task as-link">
                      <div
                        className="duration"
                        onClick={() => {
                          handleDetail();
                        }}>
                        <span>
                          {moment(
                            selectedMilestoneEvent.milestone_start_date,
                          ).format('DD MMM YY')}
                        </span>
                        <span>
                          {moment(
                            selectedMilestoneEvent.milestone_end_date,
                          ).format('DD MMM YY')}
                        </span>
                      </div>

                      <div className="project-task-inner-content">
                        <div className="bar-icon">
                          <div className="bar-main">
                            <ProgressBar
                              className="task-bar"
                              variant="warning"
                              now={
                                showMilestoneProgressBar
                                  ? showMilestoneProgressBar
                                  : 0
                              }
                            />
                          </div>
                          <div className="task-layer-icon">
                            <img src={layers} alt="Layers icon" />
                          </div>
                        </div>

                        <div className="task-detail">
                          <span>{selectedMilestoneEvent.title}</span>
                          <div style={{display: 'flex'}}>
                            {showTeamsAlias &&
                              showTeamsAlias.length > 0 &&
                              showTeamsAlias.map((item, idx) => (
                                <p style={{marginRight: '5px'}}>
                                  {fixName(item.first_name, item.last_name)}
                                </p>
                              ))}
                          </div>
                        </div>

                        <div className="bottom-image-icon">
                          <div className="task-images">
                            {showTeamsAlias &&
                              showTeamsAlias.length > 0 &&
                              showTeamsAlias.map((item, idx) => (
                                <div className="img-consulting" key={idx}>
                                  {item.profile_img ? (
                                    <img
                                      src={item.profile_img}
                                      alt="Consulting example"
                                    />
                                  ) : (
                                    <img
                                      src={DEFAULT_IMAGE}
                                      alt="Consulting example"
                                    />
                                  )}
                                </div>
                              ))}
                            {projectListingBoardData?.team_alias_detail_users
                              ?.length > 2 && (
                              <div
                                // onClick={() => setShowTagsModal(true)}
                                className="dots">
                                ...
                              </div>
                            )}
                          </div>

                          <div className="task-icons">
                            <Row>
                              {tagsListing?.length > 0 &&
                                tagsListing?.map((icon, idx) => (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flex: '1 1',
                                      paddingTop: '18px',
                                    }}
                                    key={idx}>
                                    <span>{truncateTitle(icon, 1, 2)}</span>
                                  </div>
                                ))}
                              {projectListingBoardData?.tag_titles?.length >
                                2 && (
                                <div
                                  onClick={() => setShowTagsModal(true)}
                                  className="dots">
                                  ...
                                </div>
                              )}
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DateGraph;

import {FormattedText} from '../../Utils/Helpers';
import React, {useState, useEffect} from 'react';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import CreateDeal from './CreateDeal';
import {formatHoursMinutesTime} from '../../Utils/commonUtils';
import {Button, Col, Dropdown, Form, Row} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {successMessage} from '../../Utils/ToastMessages';
import {getDealsData} from '../../Store/Actions/ProfileAction';
import {useSelector, useDispatch} from 'react-redux';

import CreateInvoice from '../InvoiceEstimate/CreateInvoice';
import {getUpdateDataOfDeals} from '../../Store/Actions/ProfileAction';
import { getPipelineData } from '../../Store/Actions/ProfileAction';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import { RoiLogo } from '../../Constants/Images';
const DealDetail = props => {
  const [showComponent, setShowComponent] = useState('');
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const showMoreClassDescription = showMoreDescription ? 'show-all' : '';
  const dealList = useSelector(state => state.dealList);
  const [selectUser, setSelectUser] = useState(props.userData.data.id ? props.userData.data.id : []);

  console.log(props.userData, 'selectUserselectUserselectUserselectUser')

  const [dealstatus, setDealStatus] = useState(props.dealItem.deal_status);
  const [phaseStatus, setPhaseStatus] = useState(
    props.dealItem.phase_status_id,
  );

  console.log(phaseStatus, 'kkkkkkkkkkkktyyyyyyyyyyyyyyy')

  const [showAll, setShowAll] = useState(false);
  const [showAllPhases, setShowAllPhases] = useState(false);
  const [templateItem, setTemplateItem] = useState({});

  const [updateData, setUpdateData] = useState(false);

  // const lines =
  //   props?.dealItem?.description !== ''
  //     ? props?.dealItem?.description.split('\n')
  //     : '';

  const dispatch = useDispatch();

  useEffect(()=>{
    if (props.dealItem && dealList?.length > 0) {
      const filterData =
        dealList && dealList.length > 0
          ? dealList.find((item) => item.id === props.dealItem.id)
          : {}; // Using `find` to get a single object
      if (filterData) {
        props.setDealItem(filterData); // Ensure a single object is passed
      } 
    }    
  },[dealList, props.dealItem])

  const dealStatus = [
    {
      status: '1',
      color: '#3597C2',
    },
    {
      status: '2',
      color: '#FEBE13',
    },

    {
      status: '3',
      color: '#49B038',
    },
  ];

  console.log(props.dealItem, 'lkjhgfgfsdsaasdsdfjhhhhhhhhhh')

  const updateDeal = () => {
    const payload = {
      deal_id: props.dealItem ? props.dealItem.id : '',
      deal_status: dealstatus,
      phase_status_id: phaseStatus,
    };

    console.log('payload', payload);
    commonApi
      .create_deal(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          if(props.type !=='pipeline'){
            props.getUserData &&  props.getUserData();
            var payload = {
              user_id: selectUser?.length > 0 ? selectUser.toString(',') : '',
            };
            dispatch(getDealsData(payload));
          }
          if(props.type==='pipeline'){
            dispatch(getPipelineData());
          }
         
          
          props.onClose();
         

          // props.onClose();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    generateTemplateItem(props.dealItem.catalog_details);
  }, [props.dealItem]);

  const generateTemplateItem = catalogDetails => {
    const subTotal = catalogDetails.reduce(
      (acc, item) => acc + parseFloat(item.cost),
      0,
    );

    setTemplateItem({
      id: '',
      estimate_id: '',
      invoice_title: '',
      company_id: '',
      invoice_type: 'invoice',
      sub_type: '',
      catalog_ids: catalogDetails.map(item => item.id).join(','),
      group_name: '',
      sub_total: subTotal,
      taxes_fee: '',
      discount: '',
      shipping_handling: '',
      total: subTotal,
      is_template: 'false',
      is_draft: 'false',
      add_on: '',
      comparison_match: '',
      draft_user_ids: props.userData.data.id,
      created_by: '2515',
      invoice_date: '2024-05-22 12:00:00',
      due_date: '2024-05-23 12:00:00',
      customer_note: '',
      created_at: '2024-05-22 09:44:07',
      updated_at: '2024-05-23 02:57:26',
      catalog_details: catalogDetails,
    });
  };

  const renderHeader = () => {
        
    return (
      <>
    
    {props.dealItem?.update_permission ?  (  <span
                  className="as-link"
                  onClick={() => {
                    setShowComponent('edit_deal');
                  }}>
                  <EditPencilSvg fill={'white'} h={20} w={20} />
                </span>
    ) :(
      <img src={RoiLogo} alt="" />
    )}
        
      
      </>
    );
  }; 

  // console.log(props.dealItem, 'templateItemtemplateItem');

  const [showMore, setShowMore] = useState(false);
  const showMoreClass = showMore ? 'show-all' : '';

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
          dispatch(getUpdateDataOfDeals(false));
        }}
        // closeIconRight={props.closeIconRight ? props.closeIconRight : false}
        renderData={renderHeader}
       showBlackHeader={true}
       lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
       firstDivStyle={{
         padding: 0,
         background:
           'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
       }}
       
       >
        <div className="serach-filter-wrapper edit_Action pt-3">
          {/* {props.dealItem?.update_permission && (
            <h1
              className="as-link mb-2"
              onClick={() => {
                setShowComponent('edit_deal');
              }}>
              <i class="fa-solid fa-pen"></i> {''} Edit
            </h1>
          )} */}

          <Scrollbars autoHide="scroll">
            <div className="fix-width-forms fixed-width-inputs black-head-form-pad-left mb-5 deals-detail-form">
              <div className="mt-2">
                <div className="refferals-list action-listing  p-0 bg-transparent border-0">
                  <h2 className="actions-title mb-3">
                    {props.dealItem.deal_name}
                  </h2>
                </div>
              </div>

              {/* <FormattedText
                className={`profile-description mt-0 mb-3 ${showMoreClassDescription}`}
                content={props.dealItem.description}
              />

              {(props.dealItem.description?.length > 250 ||
                lines?.length > 3) && (
                <>
                  {' '}
                  <span
                    className="show_more-btn"
                    onClick={() => setShowMore(!showMore)}>
                    {!showMore
                      ? labels.show_more[selected_lang]
                      : labels.show_less[selected_lang]}
                  </span>
                </>
              )} */}

              <span className="">
                <b>Included</b>
              </span>

              <div className="ld_list_card mt-2 ">
                <ul>
                  {props.dealItem.catalog_details.length > 0 &&
                    props.dealItem.catalog_details.map((item, index) => {
                      let limit = showAll
                        ? props.dealItem.catalog_details.length
                        : 2;

                      if (index < limit) {
                        const linesOfCatalog = item.description
                          ? item.description.split('\n')
                          : [];
                        return (
                          <div
                            className="deal_item"
                            key={index}
                            onClick={() => {
                              // AddCataloginDeal(item);
                            }}>
                            <div className="f_600_h text_overflow_container">{item.title}</div>

                            <FormattedText
                              className={`profile-description ${showMoreClass}`}
                              style={'margin'}
                              content={item.description ? item.description : ''}
                            />
                            {(item.description?.length > 250 ||
                              linesOfCatalog?.length > 3) && (
                              <>
                                {' '}
                                <span
                                  className="show_more-btn"
                                  onClick={() => setShowMore(!showMore)}>
                                  {!showMore
                                    ? labels.show_more[selected_lang]
                                    : labels.show_less[selected_lang]}
                                </span>
                              </>
                            )}

                            <div className="d-flex justify-content-between">
                              <span className="deal_time">
                                {item.duration
                                  ? formatHoursMinutesTime(item.duration)
                                  : ''}
                              </span>
                              <span className="deal_cost f_600_h">
                              {item.cost !==''
                                                ? `$${item.cost}`
                                                : 'Free'}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    })}
                </ul>

                <div className="mt-2">
                  {!showAll && props.dealItem.catalog_details.length > 2 && (
                    <span
                      onClick={() => {
                        setShowAll(!showAll);
                      }}
                      className="as-link view_more_btn">
                      View More
                    </span>
                  )}

                  {showAll && props.dealItem.catalog_details.length > 2 && (
                    <span
                      onClick={() => {
                        setShowAll(!showAll);
                      }}
                      className="as-link view_more_btn">
                      View Less
                    </span>
                  )}
                </div>
              </div>

              <span className="">
                <b>All Phases</b>
              </span>

              <p className="mb-3 mt-3 p-reg-truncate">
                We are currently in the {props.dealItem.phase} phase.
              </p>

              <div className="ld_list_card mt-2 ">
                <ul>
                  {props.dealItem.all_phases.length > 0 &&
                    props.dealItem.all_phases.map((item, index) => {
                      let limit = showAllPhases
                        ? props.dealItem.all_phases.length
                        : 2;

                      if (index < limit) {
                        return (
                          <div
                            // className={
                            //   props.dealItem.all_phases.length > 1
                            //     ? 'phase_item p-reg-truncate as-link'
                            //     : 'phase_item p-reg-truncate'
                            // }
                            className={` ${
                              props.dealItem.all_phases.length > 1
                                ? 'phase_item p-reg-truncate'
                                : 'phase_item p-reg-truncate'
                            } ${
                              props.dealItem.all_phases.length > 1 &&
                              props.dealItem?.update_permission
                                ? 'as-link'
                                : ''
                            } `}
                            style={{
                              background:
                                phaseStatus === item.id ? '#89C98D' : '',
                              color: phaseStatus === item.id ? '#ffffff' : '',
                            }}
                            onClick={() => {
                              if (props.dealItem?.update_permission) {
                                setPhaseStatus(item.id);
                              }
                            }}>
                            {item.phase}
                          </div>
                        );
                      }
                    })}
                </ul>

                <div className="mt-2">
                  {!showAllPhases && props.dealItem.all_phases.length > 2 && (
                    <span
                      onClick={() => {
                        setShowAllPhases(!showAllPhases);
                      }}
                      className="as-link view_more_btn">
                      View More
                    </span>
                  )}

                  {showAllPhases && props.dealItem.all_phases.length > 2 && (
                    <span
                      onClick={() => {
                        setShowAllPhases(!showAllPhases);
                      }}
                      className="as-link view_more_btn">
                      View Less
                    </span>
                  )}
                </div>
              </div>

              <span className="">
                <b>Deal Status</b>
              </span>

              <div className="likelihood_color_flex">
                {dealStatus.map((status, index) => (
                  <div
                    style={{
                      border:
                        dealstatus === status.status ? '3px solid #424242' : '',
                    }}
                    className={`deal_status_border ${
                      dealstatus === status.status ? 'white' : ''
                    } ${props.dealItem?.update_permission ? 'as-link' : ''} `}>
                    <div
                      key={index}
                      className="deal_status"
                      style={{
                        backgroundColor: status.color,
                      }}
                      onClick={() => {
                        if (props.dealItem?.update_permission) {
                          setDealStatus(status.status);
                        }
                      }}></div>
                  </div>
                ))}
              </div>

              {props.dealItem?.update_permission && (
                <div className="mt-3">
                  <Button
                    className="new-btn btn-green "
                    style={{padding: '12px 25px'}}
                    onClick={() => {
                      setShowComponent('createInvoice');
                    }}>
                    Create Invoice
                  </Button>
                </div>
              )}
              <div className="filter-btn-wrapper pt-2">
                <div className="invite-btn">
                  <div className="fieldset"></div>
                </div>
              </div>
            </div>
          </Scrollbars>

          {props.dealItem?.update_permission &&
            (phaseStatus !== props.dealItem.phase_status_id ||
              dealStatus !== props.dealItem.deal_status) && (
              <div className="filter-btn-wrapper   bg_transparent fix-width-forms fixed-width-inputs bottom-0 pt-2 pb-2 ">
                <div className="invite-btns text-center p-0">
                  <Button
                    // disabled={participantArray.length > 0 ? false : true}
                    className='m-0'
                    onClick={() => {
                      updateDeal();
                    }}>
                    Update Phase
                  </Button>
                </div>
              </div>
            )}
        </div>
      </SlidingPanel>

      {showComponent === 'edit_deal' && (
        <CreateDeal
          editDealItem={props.dealItem}
          onClose={() => {
            setShowComponent('');
           
          }}
          closeActionDeatil={props.onClose}
          type={true}
          userData={props.userData.data}
          setUpdateData={setUpdateData}
          phaseId={props.phaseId}
          PieplineId={props.PieplineId}
          setSelectUser={setSelectUser}
          setPhaseStatus={setPhaseStatus}
        />
      )}

      {showComponent === 'createInvoice' && (
        <CreateInvoice
          type={'deals'}
          dealItem={props.dealItem}
          templateItem={templateItem}
          onClose={() => {
            setShowComponent('');
          }}
          hitUserUserInfo={() => {
         
            if(props.type !=='pipeline'){
              props.getUserData();
              var payload = {
                user_id: props.userData.data.id,
              };
              dispatch(getDealsData(payload));
              dispatch(getUpdateDataOfDeals(false));
            }
            if(props.type==='pipeline'){
              dispatch(getPipelineData());
            }


            props.onClose();
            
          }}
        />
      )}
    </>
  );
};

export default DealDetail;

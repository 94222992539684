import React, {useState, useEffect} from 'react';
import {Button, Col, Row, Form, Dropdown} from 'react-bootstrap';
import {fixName} from '../../Utils/commonUtils';
import {useHistory} from 'react-router-dom';
import commonApi from '../../Services/CommonService';
import {DEFAULT_IMAGE} from '../../Utils/env';
const ProfileMeetup = props => {
  const [userUpcomming, setUpcommingData] = useState([]);
  const history = useHistory();

  const [searchQry, setSearchQry] = useState('');

  const handleClickLink = link => {
    history.push(link);
  };
  const SessionItem = item => {
    var totalMem = item.total_members + 1;
    var totalMode = item.total_moderators + 1;
    return (
      <div
        onClick={() => {
          props.handleCommand(item, 'session');
        }}
        className="session-item card as-link">
        <div className="session-heading">
          <h2>{item.session_title}</h2>
          <span className="users-text">
            <i className="fas fa-circle-user" /> {`${totalMem}/${totalMode}`}
          </span>
          <span className="category-text">{item.category_name}</span>
        </div>

        <div className="persons-img-list">
          {item && _renderCreatorImg(item)}
          {item.audience_ids &&
            item.audience_list &&
            Object.entries(item.audience_list).length > 0 &&
            item.audience_list.map((person, index) => _renderAudiImg(person))}
        </div>
        <div className="persons-name-list">
          <ul>
            {
              <li key={`person-name-creator`}>
                {
                  <span className="owner-icon">
                    <i className="fas fa-crown" />
                  </span>
                }
                {fixName(item.creator_first_name, item.creator_last_name)}
              </li>
            }
            {item.audience_ids &&
              item.audience_list &&
              Object.entries(item.audience_list).length > 0 &&
              item.audience_list.map((person, index) => (
                <li key={`person-name-${index}`}>
                  {/* {person.owner && (
                                        <span className="owner-icon">
                                            <i className="fas fa-crown" />
                                        </span>
                                    )}
                                    {!person.owner && person.speaker && (
                                        <span className="speaker-icon">
                                            <i className="fas fa-microphone" />
                                        </span>
                                    )} */}
                  {fixName(person.first_name, person.last_name)}
                </li>
              ))}
          </ul>
          {/* <ul>
                        {Object.entries(item.persons).length > 0 &&
                            item.persons.map((person, index) => (
                                <li key={`person-name-${index}`}>
                                    {person.owner && (
                                        <span className="owner-icon">
                                            <i className="fas fa-crown" />
                                        </span>
                                    )}
                                    {!person.owner && person.speaker && (
                                        <span className="speaker-icon">
                                            <i className="fas fa-microphone" />
                                        </span>
                                    )}
                                    {person.name}
                                </li>
                            ))}
                    </ul> */}
        </div>
        {item.address && (
          <div className="person-address-place" style={{fontSize: 15}}>
            <i className="fas fa-location-dot" /> {item.address}
          </div>
        )}
      </div>
    );
  };

  const _renderCreatorImg = person => {
    return (
      <div className="img-item">
        {
          <span className="owner-icon">
            <i className="fas fa-crown" />
          </span>
        }
        <img
          src={person.creator_img === '' ? DEFAULT_IMAGE : person.creator_img}
          alt=""
        />
      </div>
    );
  };
  const _renderAudiImg = person => {
    return (
      <div key={`person-id-${person.id}`} className="img-item">
        <img
          src={person.profile_img === '' ? DEFAULT_IMAGE : person.profile_img}
          alt=""
        />
      </div>
    );
  };

  const getUpcommingData = (qry, type) => {
    var data = {
      user_id: props.userId,
      search_query: qry,
      type: type,
    };
    commonApi
      .get_user_sessions(data)
      .then(res => {
        if (res.status === 200) {
          setUpcommingData(res.user_session);
          
          setTimeout(() => {
            props.setshowLoader(false)
          }, 500);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    getUpcommingData(searchQry, props.sessionFilterValue);
  }, [searchQry, props.sessionFilterValue]);


  return (
    <>
      {(props.threeDots || props.type === 'profile') && (
        <div className="person-search-wrapper mb-3 mt-3 messages-users-wrapper">
          <div className="search-form d-flex">
            <Form.Group className="search-input">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchQry}
                onChange={e => {
                  setSearchQry(e.target.value);
                }}
              />
            </Form.Group>
            <Button>
              <i
                className="icon-filter-icon"
                onClick={() => {
                  props.handleCommand('', 'session_filter');
                }}
              />
            </Button>
          </div>
        </div>
      )}
      {!props.showLoader && Object.entries(userUpcomming).length > 0 ? (
        Object.entries(userUpcomming).length > 0 &&
        userUpcomming.map((item, index) => (
          <div key={`ses-item-${index}`}>{item && SessionItem(item)}</div>
        ))
      ) : (!props.showLoader && props.userId === localStorage.getItem('user')) ? (
        <div className="no-results-wrapper">
          {/* <div className="img-bg-wrapper">
                            <img src={QA} alt="" />
                          </div> */}

          <div className="no-results-content">
            <h2>You don't have any upcoming activities.</h2>
            <p>
              Grow your personal and professional circles by creating and
              attending meetups.
            </p>

            <div className="btn-wrapper mt-3">
              <Button
                className="btn btn-dark"
                onClick={() => {
                    props.handleCommand &&
                      props.handleCommand('', 'create_session');
                //   handleClickLink('/');
                }}>
                Create Meetup
              </Button>
            </div>
          </div>
        </div>
      ) : !props.showLoader && (
        <div className="no-results-wrapper">
        {/* <div className="img-bg-wrapper">
                          <img src={QA} alt="" />
                        </div> */}

        <div className="no-results-content mt-5">
          {/* <h2>No record found.</h2> */}
          <p>
          No record found.
          </p>

          <div className="btn-wrapper mt-3">
            <Button
              className="btn btn-dark"
              onClick={() => {
                  props.handleCommand &&
                    props.handleCommand('', 'create_session');
              //   handleClickLink('/');
              }}>
              Create Meetup
            </Button>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default ProfileMeetup;

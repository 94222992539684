import React, {useState, useEffect, useRef} from 'react';

import {Button, Form, Dropdown, Accordion} from 'react-bootstrap';
import {errorMessage} from '../../Utils/ToastMessages';
import commonApi from '../../Services/CommonService';
import LoaderSpinner from '../Modals/LoaderSpinner';

import EditPencilSvg from '../../Svg/EditPencilSvg';
import {successMessage} from '../../Utils/ToastMessages';
import {
  blockInvalidChar,
  capitalizeFirst,
  capitalizeFirstLetter,
  compareDates,
  durationArray,
  generatePaymentFrequency,
  getNameFromDurationValue,
  isCostEqualToSum,
  isPaymentMilestoneDuplicate,
  isWithinBudget,
  validateCatalogCost,
  validateCatalogItem,
  getMaxDateFromToday,
} from '../../Utils/commonUtils';
import SlidingPanel from '../SlidingPanel';
import {Scrollbars} from 'react-custom-scrollbars';
import CustomModal from '../CustomModal';
import CropImage from '../Cropper/CropImage';
import CreatCircleList from '../Circle/CreatCircleList';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import {monthDays, getNameOfDuration} from '../../Utils/commonUtils';
import CreateCatalogGroup from './CreateCatalogGroup';
import CustomDropdown from '../CustomDropdown';
import {fixName, isDateMatched} from '../../Utils/commonUtils';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import moment from 'moment';
import MultiSelectionDropdown from '../MultiSelectionDropdown';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import ImagePreview from '../Modals/ImagePreview';
import {RoiLogo} from '../../Constants/Images';
import Calender from '../FeedBack/Calender';
const CreateCatalog = props => {
  const [selectedServiceId, setSelectedServiceId] = useState(['1']);
  const [showModal, setShowModal] = useState(false);

  const [catalogName, setCatalogName] = useState('Product');
  // const [titleValidation, setTitleValidation] = useState(false);
  // const [catalogItems, setCatalogItems] = useState([]);

  // const [catalogTitleList, setCatalogTitleList] = useState([]);

  const [typeForAudience, setTypeForAudience] = useState('1');
  const [typeForAliasRole, setTypeForAliasRole] = useState('');

  const [typeForAliasRoleM, setTypeForAliasRoleM] = useState('');
  const [typeForDelievery, setTypeForDelievery] = useState('virtual');

  const [pointsValue, setPointsValue] = useState('');
  const [moneyValue, setMoneyValue] = useState('');
  const [paymentTypeId, setPaymentTypeId] = useState(['one_time']);

  const [catalogImage, setCatalogImage] = useState('');
  const [showLoader, setShowLoader] = useState('');
  const [showModel, setShowModel] = useState('');
  const [dleteLevelIndex, setDleteLevelIndex] = useState('');

  const RadioButtonName = [
    {id: '1', label: 'Monthly'},
    {id: '3', label: 'Quarterly'},
    {id: '12', label: 'Annually'},
  ];

  const RadioIncentives = ['Points', 'Money'];
  const [subcribtionIdvalue, setSubcribtionIdvalue] = useState(
    RadioButtonName[0].id,
  );
  const [incentiveIdvalue, setIncentiveIdvalue] = useState(RadioIncentives[0]);
  const [incentiveValues, setIncentiveValues] = useState([]);

  const [rolesPointsMoney, setRolesPointsMoney] = useState([]);
  const [rolesAssignMoney, setRolesAssignMoney] = useState([]);

  const [companySuppliers, setCompanySuppliers] = useState([]);
  const [suppliersIds, setSuppliersIds] = useState([]);
  const [ticketImg, setTicketImg] = useState('');
  const [file, setFile] = useState(null);
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  //  const [pointIncentive, setPointincentive] = useState('');
  // const [moneyIncentive, setMoneyincentive] = useState('');

  const [catalogData, setCatalogData] = useState({
    type: '',
    title: '',
    // id: '',
    description: '',
    condition: '',
    rentalFee: '',
    cost: '',
    replacement_cost: '',
    recipient_cost: '',
    documents: '',
    audience: '',
    responsible_party: '',
    delivery_method: '',
    quantity_available: '',
    duration: '',
    variation: '',
    restock: '',
    address: '',
    bedrooms: '',
    bathrooms: '',
    amenities: '',
    retail_value: '',
    media_type: '',
    subscription: '',
    incentive: incentiveIdvalue,
    role_id: '',
    catalog_type: catalogName.toLowerCase(),
    image: '',
    group_ids: [],
    incentive_points: [],
    incentive_money: [],
    taxes_fee: '',
    discount: '',
    grace_period: '',
    late_fee: '',
    shipping_handling: '',
    billing_checked: false,
    billing_day: '',

    levels: [],
    purchase_order: 'no',
    supplier_ids: [],
    payment_milestones: [],
    payment_type: catalogName === 'Membership' ? [] : paymentTypeId,
    // group_type: 'single',
  });

  const [paymentMilestone, setPaymentMilestone] = useState({
    date: '',
    amount: '',
  });
  const todayDate = moment().format('YYYY-MM-DD');

  const [validated, setValidated] = useState(false);

  const groupSingleArray = [
    {
      name: 'Single',
      id: 'single',
    },
    {
      name: 'Group',
      id: 'group',
    },
  ];
  console.log(dleteLevelIndex, 'dleteLevelIndex', showModal);

  const [groupSingelId, setGroupSingelId] = useState(groupSingleArray[0].id);
  const [showGroupFields, setShowGroupFields] = useState(false);
  const [addNewGroup, setAddNewGroup] = useState(false);
  const [groupsDeatil, setGroupsDeatil] = useState([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [aliasRoles, setAlliasRoles] = useState([]);
  const [rolesTypes, setRolesType] = useState([]);
  const [allCatalogData, setAllCatalogData] = useState([]);
  const buttonRef = useRef(null);

  const AudienceTypes = [
    {id: '1', name: 'Standard', value: 'standard'},
    {
      id: '2',
      name: 'Customer Role',
      value: 'customer_role',
    },

    {
      id: '4',
      name: 'Individuals',
      value: 'individuals',
    },
  ];

  const DelieveryTypes = [
    {id: '1', name: 'Virtual', value: 'virtual'},
    {id: '2', name: ' In Person', value: ' in-person'},
    {
      id: '3',
      name: 'Delivery',
      value: 'delivery',
    },

    {
      id: '5',
      name: 'Delivered',
      value: 'delivered',
    },

    {
      id: '4',
      name: 'Deliver',
      value: 'deliver',
    },
    {id: '6', name: 'Pickup', value: 'pickup'},
  ];

  const DelieveryTypes2 = [
    {id: '1', name: 'Virtual', value: 'virtual'},
    {id: '2', name: ' In Person', value: ' in-person'},

    {
      id: '5',
      name: 'Delivered',
      value: 'delivered',
    },
  ];

  const CatalogTypeList = [
    {
      id: 'Product',
      name: 'Product',
      content:
        'Choose if you offer retail and e-commerce options (virtual or physical)',
    },
    {
      id: 'Service',
      name: 'Service',
      content: 'Choose if you do consulting or exchange time for reward',
    },
    {
      id: 'Membership',
      name: 'Membership',
      content: 'Choose if you need to simply stay organized or rent to others',
    },
  ];

  console.log('catalogData', catalogData);

  const getSystemRoles = () => {
    commonApi
      .get_user_roles()
      .then(res => {
        if (res.status === 200) {
          if (res.user_roles?.length > 0) {
            // const rolesWithInvites = res.user_roles.map(role => ({
            //   ...role,
            //   invites: '',
            // }));
            setRolesType(res.user_roles);

            // const menusArray = res.user_roles.map(
            //   ({id, role_name, user_ids, alias}) => ({
            //     id: id,
            //     name: role_name,
            //     menus: '',
            //   }),
            // );

            // setCompanyRolesMenus(menusArray);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getCompanyRoles = () => {
    commonApi
      .get_company_users_role()
      .then(res => {
        console.log(res, 'res');
        if (res.status === 200) {
          if (res.data.length > 0) {
            const newFilterData = res.data.filter(
              item => item.role_type_id !== '1',
            );

            setAlliasRoles(newFilterData.length > 0 ? newFilterData : []);
            setTypeForAliasRoleM(
              newFilterData.length > 0 ? newFilterData[0]?.id : '',
            );
            setTypeForAliasRole(
              newFilterData.length > 0 ? newFilterData[0]?.id : '',
            );
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (catalogName === 'Fleet') {
      setCatalogData({
        ...catalogData,
        audience: '4',
      });
      setTypeForAudience('4');
    } else {
      setCatalogData({
        ...catalogData,
        audience: typeForAudience,
        payment_type: catalogName === 'Membership' ? [] : paymentTypeId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogName]);
  useEffect(() => {
    setShowLoader(true);
    getCompanyRoles();
    getSystemRoles();
    getCompanyCatalog();
    getCompanySupplier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   handlereset();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [catalogName]);

  const handlereset = () => {
    setCatalogData({
      type: '',
      title: '',
      // id: '',
      description: '',
      condition: '',
      rentalFee: '',
      cost: '',
      replacement_cost: '',
      recipient_cost: '',
      documents: '',
      audience: '',
      responsible_party: '',
      delivery_method: '',
      quantity_available: '',
      duration: '',
      variation: '',
      restock: '',
      address: '',
      bedrooms: '',
      bathrooms: '',
      amenities: '',
      retail_value: '',
      media_type: '',
      subscription: '',
      incentive: '',
      role_id: '',
      catalog_type: catalogName.toLowerCase(),
      image: '',
      group_ids: [],
      taxes_fee: '',
      discount: '',
      grace_period: '',
      late_fee: '',
      shipping_handling: '',
      billing_checked: false,
      billing_day: '',

      levels: [],
      purchase_order: 'no',
      supplier_ids: [],
      payment_milestones: [],
      payment_type: catalogName === 'Membership' ? [] : ['one_time'],
      // group_type: 'single',
    });

    setPointsValue('');
    setMoneyValue('');
    setRolesAssignMoney([]);
    setRolesPointsMoney([]);
    setIncentiveValues([]);
    setFrequencyData({
      duration: '1',
      payment_frequency: '1',
      payment_amount: '',
      renewal: false,
      payment_type: ['one_time'],
      subscription: RadioButtonName[0].id,
      payment_milestones: [],
    });
    setGracePeriodType('1');

    setPaymentTypeId(['one_time']);

    setFrequencyType('1');
    setDurationType('1');
    setlevelData({
      level_name: '',
      frequencies: [],
    });
    setPaymentMilestone({
      date: '',
      amount: '',
    });
    setPaymentError(false);
    setLevelError(false);
  };
  const addCatalog = (id, name) => {
    // setTitleValidation(false);
    setCatalogName(name);
    // props.setCatalogName(name);
    handlereset();
    // setCatalogItems([]);
    setCatalogImage('');
    // props.setTicketImg('');
    setSubcribtionIdvalue(RadioButtonName[0].id);
    setIncentiveIdvalue(RadioIncentives[0]);
    setGroupSingelId(groupSingleArray[0].id);
    // props.setCatalogItemsList([]);
    setSelectedServiceId([id]);
    setShowModal(false);
    // setCatalogTitleList([]);

    // if (!selectedServiceId.includes(id)) {
    //   setSelectedServiceId([id]);
    // } else {
    //   setSelectedServiceId(previous =>
    //     previous.filter(miniId => miniId !== id),
    //   );
    // }
  };

  console.log(catalogData, 'catalogData');
  const changeTicketImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];
    // let fileExtension = file.name.split('.').pop().toLowerCase();

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else {
      const filePath = e.target.files[0];
      // const objectUrl = URL.createObjectURL(filePath);
      let reader = new FileReader();
      reader.readAsDataURL(filePath);
      reader.onload = e => {
        //

        uploadCatalogImages(e.target.result);
      };

      // setTicketImg(objectUrl);
      //
      //
    }
    // const imgTypes = ['image/gif', 'image/jpeg', 'image/png'];
  };

  const uploadCatalogImages = img => {
    setShowLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append(
      'image',
      img.replace(/^data:image\/[a-z]+;base64,/, ''),
    );

    commonApi
      .upload_catalog_images(bodyFormData)
      .then(res => {
        if (res.status === 200) {
          setCatalogImage(res.image);
          setCatalogData({
            ...catalogData,
            image: res.image,
          });
        }
        setShowLoader(false);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const generateRandomId = () => {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  };
  const addItems = item => {
    // if (!catalogItems.includes(item.title)) {
    const newItem = {
      ...item,
      id: generateRandomId(), // Generate a random UUID and assign it to the 'id' property
      catalog_type:
        catalogName === 'RE Listing'
          ? 're_listing'
          : catalogName === 'Play Center'
          ? 'play_center'
          : catalogName.toLowerCase(),
    };
    // setCatalogItaems(previous => [...previous, newItem.title]);
    // if (!catalogTitleList.includes) {
    // setCatalogTitleList(previous => [...previous, newItem.title]);
    props.setCatalogListSaveByUser(previous => [...previous, newItem]);

    // props.setCatalogItemsList(previous => [...previous, item]);

    // const updatedCatalogListTemp = {
    //   ...props.catalogListSaveByUser,
    //   [catalogName.toLowerCase()]: [
    //     ...props.catalogListSaveByUser[catalogName.toLowerCase()],
    //     item,
    //   ],
    // };
    // props.setCatalogListSaveByUser(updatedCatalogListTemp);
    const updatedCatalogList = {
      ...props.catalogItemsList,
      [catalogName === 'RE Listing'
        ? 're_listing'
        : catalogName === 'Play Center'
        ? 'play_center'
        : catalogName.toLowerCase()]: [
        ...props.catalogItemsList[
          catalogName === 'RE Listing'
            ? 're_listing'
            : catalogName === 'Play Center'
            ? 'play_center'
            : catalogName.toLowerCase()
        ],
        newItem,
      ],
    };
    props.setCatalogItemsList(updatedCatalogList);

    setShowModal(false);
    handlereset();
    // setSubcribtionIdvalue('');
    // setIncentiveIdvalue('');
    setSubcribtionIdvalue(RadioButtonName[0].id);
    setIncentiveIdvalue(RadioIncentives[0]);
    setGroupSingelId(groupSingleArray[0].id);
    setCatalogImage('');
    setTicketImg('');
    // } else {
    //   errorMessage(`you have already added this ${catalogName}`);
    // }
  };

  const removeCatalogItems = (title, userId) => {
    const updatedCatalogList = {
      ...props.catalogItemsList,
      [catalogName === 'RE Listing'
        ? 're_listing'
        : catalogName === 'Play Center'
        ? 'play_center'
        : catalogName.toLowerCase()]: props.catalogItemsList[
        catalogName === 'RE Listing'
          ? 're_listing'
          : catalogName === 'Play Center'
          ? 'play_center'
          : catalogName.toLowerCase()
      ].filter((item, index) => item.title !== title && index !== userId),
    };

    props.setCatalogItemsList(updatedCatalogList);

    props.setCatalogListSaveByUser(previous =>
      previous.filter(
        (item, index) => item.title !== title && index !== userId,
      ),
    );
  };

  const handleChange = e => {
    // const {id, checked} = e.target;
    // setOpportunity([...opportunity, id]);

    if (catalogName !== 'Membership') {
      setSubcribtionIdvalue(e.target.value);
      setCatalogData({
        ...catalogData,
        subscription: e.target.value,
      });
    }

    if (catalogName === 'Membership') {
      setSubcribtionIdvalue(e.target.value);
      setFrequencyData({
        ...frequencyData,
        subscription: e.target.value,
      });
    }
  };

  const delete_company_catalog = id => {
    props.setCatalogListSaveByUser(previous =>
      previous.filter(miniId => miniId.id !== id),
    );

    const updatedCatalogList = {
      ...props.catalogItemsList,
      [catalogName === 'RE Listing'
        ? 're_listing'
        : catalogName === 'Play Center'
        ? 'play_center'
        : catalogName.toLowerCase()]: props.catalogItemsList[
        catalogName === 'RE Listing'
          ? 're_listing'
          : catalogName === 'Play Center'
          ? 'play_center'
          : catalogName.toLowerCase()
      ].filter(item => item.id !== id),
    };
    props.setCatalogItemsList(updatedCatalogList);
    props.setCatalogDeleteids(previous => [...previous, id]);
  };

  const handleIncentivesValues = e => {
    if (!incentiveValues.includes(e.target.value)) {
      setIncentiveValues(previous => [...previous, e.target.value]);
    } else {
      setIncentiveValues(previous =>
        previous.filter(item => item !== e.target.value),
      );
      if (e.target.value === 'Points') {
        setRolesPointsMoney([]);
        setCatalogData({
          ...catalogData,
          incentive_points: [],
        });
      } else if (e.target.value === 'Money') {
        setRolesAssignMoney([]);
        setCatalogData({
          ...catalogData,
          incentive_money: [],
        });
      }
    }
  };

  const handleSubmitOfForm = e => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      // if (!catalogData.image || catalogData.image === '') {
      //   errorMessage('Please add image');
      // }

      setValidated(true);
    } else {
      e.preventDefault();

      if (
        catalogName === 'Membership' &&
        (!catalogData?.levels || catalogData.levels?.length === 0)
      ) {
        errorMessage('Please add level');
      } else {
        if (
          catalogData.cost <= catalogData.discount &&
          catalogName !== 'Membership'
        ) {
          errorMessage(labels.discount_Error[selected_lang]);
          return;
        }

        if (paymentTypeId.includes('milestone')) {
          if (catalogData.payment_milestones.length > 0) {
            const valid = validateCatalogCost(
              catalogData.payment_milestones,
              catalogData.cost,
              catalogData.discount,
            );
            if (valid) {
              if(groupSingelId === 'group'){
                if(selectedGroupIds?.length > 0){
                  create_offering();
                }else{
                  errorMessage('Please select atleast one group')
                }
              }else{
                create_offering()
              }
            } else {
              errorMessage('Sum of payment milestones must be equal to cost');
            }
          } else {
            errorMessage('Please add milestones');
          }
        } else {

          if(groupSingelId === 'group'){
            if(selectedGroupIds?.length > 0){
              create_offering();
            }else{
              errorMessage('Please select atleast one group')
            }
          }else{
            create_offering()
          }
        }
      }

      setValidated(false);
    }
  };

  const create_offering = () => {
    if (catalogData.image === '') {
      catalogData.image = 'https://s3.amazonaws.com/build-dev/no_image_2.jpg';
    }

    const catalogItem = validateCatalogItem(catalogData);
    // if(catalogName === 'Membership'){
    //   catalogData.payment_type =[]
    // }

    // if(catalogName !== 'Membership' && !catalogData.payment_type.includes('subscription')){
    //   catalogData.subscription =''
    // }else if(catalogName !== 'Membership' && !catalogData.payment_type.includes('milestone')){
    //   catalogData.payment_milestones =[]
    // }
    const paylaod = {
      catalog_details: [catalogItem],
      catalog_id: props.CatalogItem ? props.CatalogItem.catalog_id : '',
    };

    console.log(paylaod, 'paylaodpaylaodpaylaod');

    commonApi
      .create_offering(paylaod)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          if (props.CatalogItem) {
            props.onClose(res.data);
          } else {
            props.onCloseWithApiHit();
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getCompanySupplier = type => {
    var payload = {};

    commonApi
      .get_company_supplier(payload)
      .then(res => {
        if (res.status === 200) {
          if (type === 'addSupplier' && res.supplier_details.length > 0) {
            setCatalogData({
              ...catalogData,
              supplier_ids: [res.supplier_details[0]?.id],
            });
          }
          setCompanySuppliers(res.supplier_details);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getGroupDetail = () => {
    const paylaod = {
      search_query: '',
    };

    commonApi
      .get_all_groups(paylaod)
      .then(res => {
        if (res.status === 200) {
          const groupArray = [...res.all_groups];
          groupArray.unshift({group_name: 'All', id: 'all'});
          const newArray = groupArray;
          setGroupsDeatil(
            res.all_groups.length > 0 ? newArray : res.all_groups,
          );
          console.log(newArray, '---------->newArray');
          // setSelctedGroupId(res.all_groups[0]?.id)

          if (!props.CatalogItem && res.all_groups.length > 0) {
            setSelectedGroupIds([res.all_groups[0]?.id]);
            // const newData = {
            //   ...catalogData,
            //   group_ids: [res.all_groups[0]?.id],
            // };
            // setCatalogData(newData);
          }

          if (
            props.CatalogItem &&
            props.CatalogItem.group_ids &&
            props.CatalogItem.group_ids.length > 0 &&
            props.CatalogItem.group_ids.length === res.all_groups.length
          ) {
            const newArray = [...props.CatalogItem.group_ids];
            newArray.unshift('all');
            // const groupIds = newArray
            setSelectedGroupIds(newArray);
          }

          if (res.all_groups.length === 0) {
            setAddNewGroup(true);
          }
          setShowGroupFields(true);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  useEffect(() => {
    if (showGroupFields) {
      getGroupDetail();
    }
  }, [props.showModel]);

  useEffect(() => {
    setCatalogData({
      ...catalogData,
      image: ticketImg,
    });
  }, [ticketImg]);

  useEffect(() => {
    if (catalogName !== 'Membership') {
      setCatalogData({
        ...catalogData,
        payment_type: catalogName === 'Membership' ? [] : paymentTypeId,
        payment_milestones: !paymentTypeId.includes('milestone')
          ? []
          : catalogData.payment_milestones,
        subscription: !paymentTypeId.includes('subscription')
          ? RadioButtonName[0].id
          : catalogData.subscription,
      });
    }

    if (catalogName === 'Membership') {
      setFrequencyData({
        ...frequencyData,
        payment_type: paymentTypeId,
        payment_milestones: !paymentTypeId.includes('milestone')
          ? []
          : frequencyData.payment_milestones,
        subscription: !paymentTypeId.includes('subscription')
          ? RadioButtonName[0].id
          : frequencyData.subscription,
      });
    }

    if (!paymentTypeId.includes('subscription')) {
      setSubcribtionIdvalue(RadioButtonName[0].id);
    }
  }, [paymentTypeId]);

  useEffect(() => {
    const newData = {
      ...catalogData,
      group_ids: selectedGroupIds.filter(item => item !== 'all'),
    };
    setCatalogData(newData);
  }, [selectedGroupIds]);

  const isAliasIdExists = (id, stateArray) => {
    return stateArray.some(item => item.alias_id === id);
  };

  const handlePointsMoney = (aliasId, value) => {
    if (!isAliasIdExists(aliasId, rolesPointsMoney)) {
      const filteredarray = aliasRoles.filter(item => item.id === aliasId);
      let item = filteredarray[0];
      setRolesPointsMoney(previous => [
        ...previous,
        {alias_id: aliasId, alias_name: item.role_alias, points: value},
      ]);

      setPointsValue('');
    } else {
      errorMessage('You have already assign Points');
    }
  };

  const handleMoney = (aliasId, value) => {
    if (!isAliasIdExists(aliasId, rolesAssignMoney)) {
      const filteredarray = aliasRoles.filter(item => item.id === aliasId);
      let item = filteredarray[0];
      setRolesAssignMoney(previous => [
        ...previous,
        {alias_id: aliasId, alias_name: item.role_alias, money: value},
      ]);

      setMoneyValue('');
    } else {
      errorMessage('You have already assign Money');
    }
  };

  const removeAssignPointsRoles = id => {
    setRolesPointsMoney(prev => prev.filter(item => item.alias_id !== id));
  };

  const removeAssignMoneyRoles = id => {
    setRolesAssignMoney(prev => prev.filter(item => item.alias_id !== id));
  };

  useEffect(() => {
    if (rolesPointsMoney.length > 0) {
      setCatalogData({
        ...catalogData,
        incentive_points: rolesPointsMoney,
      });
    }
  }, [rolesPointsMoney]);

  useEffect(() => {
    if (rolesAssignMoney.length > 0) {
      setCatalogData({
        ...catalogData,
        incentive_money: rolesAssignMoney,
      });
    }
  }, [rolesAssignMoney]);

  useEffect(() => {
    setCatalogData({
      ...catalogData,
      catalog_type: catalogName.toLowerCase(),
    });
  }, [catalogName]);

  const uploadCatalogImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);

      setShowModel('uploadCatalogImg');

      e.target.value = null;
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null;
    }
  };

  const getCompanyCatalog = async () => {
    try {
      const res = await commonApi.get_company_catalog();
      console.log(res, 'res');

      if (res.status === 200) {
        if (res.status === 200) {
          if (res.data) {
            setAllCatalogData(res.data);
            setTimeout(() => {
              setShowLoader(false);
            }, 1000);
          }
        }
      }
    } catch (err) {
      console.log('Err', err);
      return {type: 'error', message: err.message};
    }
  };

  useEffect(() => {
    if (props.CatalogItem) {
      handleEditCatalogItem(props.CatalogItem);
      console.log(props.CatalogItem, 'props.CatalogItemprops.CatalogItem');
      setCatalogName(capitalizeFirstLetter(props.CatalogItem.catalog_type));
      if (
        props.CatalogItem.group_ids &&
        props.CatalogItem.group_ids.length > 0
      ) {
        getGroupDetail();
      }
    }
  }, []);

  const handleEditCatalogItem = data => {
    console.log(data, 'datadatadatadata');
    setTypeForDelievery(
      data.delivery_method ? data.delivery_method : 'virtual',
    );
    const catalogData = {
      type: data.type || '',
      title: data.title || '',
      // id: data.id || '', // Uncomment if id needs to be included
      description: data.description || '',
      condition: data.condition || '',
      rentalFee: data.rentalFee || '',
      cost: data.cost || '',
      replacement_cost: data.replacement_cost || '',
      recipient_cost: data.recipient_cost || '',
      documents: data.documents || '',
      audience: data.audience || '',
      responsible_party: data.responsible_party || '',
      delivery_method: data.delivery_method || '',
      quantity_available: data.quantity_available || '',
      duration: data.duration || '',
      variation: data.variation || '',
      restock: data.restock || '',
      address: data.address || '',
      bedrooms: data.bedrooms || '',
      bathrooms: data.bathrooms || '',
      amenities: data.amenities || '',
      retail_value: data.retail_value || '',
      media_type: data.media_type || '',
      subscription: data.subscription || '',
      role_id: data.role_id || '',
      catalog_type: data.catalog_type ? data.catalog_type.toLowerCase() : '',
      image:
        data.image &&
        data.image !== 'https://s3.amazonaws.com/build-dev/no_image_2.jpg'
          ? data.image
          : '',
      group_ids: data.group_ids || [],
      incentive_points: data.incentive_points || [],
      incentive_money: data.incentive_money || [],
      taxes_fee: data.taxes_fee || '',
      discount: data.discount || '',
      grace_period: data.grace_period || '',
      late_fee: data.late_fee || '',
      shipping_handling: data.shipping_handling || '',
      levels:
        data.catalog_type === 'membership' && data.membership_levels.length > 0
          ? data.membership_levels
          : [],
      purchase_order:
        data.catalog_type === 'product' && data.purchase_order === 'yes'
          ? 'yes'
          : 'no',
      supplier_ids:
        data.catalog_type === 'product' && data.supplier_ids.length > 0
          ? data.supplier_ids
          : [],
      payment_milestones:
        data.payment_milestones && data.payment_milestones.length > 0
          ? data.payment_milestones
          : [],
      payment_type:
        data.payment_type && data.payment_type.length > 0
          ? data.payment_type
          : ['one_time'],
    };

    setPaymentTypeId(
      !data.payment_type.includes('') &&
        data.catalog_type !== 'membership' &&
        data.payment_type &&
        data.payment_type.length > 0
        ? data.payment_type
        : ['one_time'],
    );
    setRolesAssignMoney(
      data.incentive_money && data.incentive_money.length > 0
        ? data.incentive_money
        : [],
    );
    setRolesPointsMoney(
      data.incentive_points && data.incentive_points.length > 0
        ? data.incentive_points
        : [],
    );
    setSubcribtionIdvalue(data.subscription || RadioButtonName[0].id);
    setCatalogData(catalogData);

    setTicketImg(
      data.image &&
        data.image !== 'https://s3.amazonaws.com/build-dev/no_image_2.jpg'
        ? data.image
        : '',
    );
    setGracePeriodType(
      data.grace_period && data.grace_period !== '' ? data.grace_period : '1',
    );

    setIncentiveValues(previous =>
      [
        ...previous,
        ...(data?.incentive_money?.length > 0 ? ['Money'] : []),
        ...(data?.incentive_points?.length > 0 ? ['Points'] : []),
      ].filter(Boolean),
    );

    if (data.group_ids && data.group_ids.length > 0) {
      setGroupSingelId(groupSingleArray[1].id);
      setShowGroupFields(true);
      setSelectedGroupIds(data.group_ids);
    }
  };

  const deleteUpdatedImage = img => {
    var payload = {image: img};

    commonApi
      .delete_offering_image(payload)
      .then(res => {
        if (res.status === 200) {
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  console.log(catalogName, 'catalogNamecatalogName ');

  console.log(
    props.CatalogItem,
    'props.CatalogItemprops.CatalogItemprops.CatalogItem ',
  );

  console.log(catalogData.group_type, 'catalogData.group_type');
  console.log(groupSingelId, 'groupSingelId');

  // memeber ship work

  // ==================================================================>

  const [gracePeriodType, setGracePeriodType] = useState('1');
  const [levelData, setlevelData] = useState({
    level_name: '',
    frequencies: [],
  });

  const [frequencyData, setFrequencyData] = useState({
    duration: '1',
    payment_frequency: '1',
    payment_amount: '',
    renewal: false,
    payment_type: paymentTypeId,
    subscription: RadioButtonName[0].id,
    payment_milestones: [],
  });

  const [durationType, setDurationType] = useState('1');
  const [frequencyType, setFrequencyType] = useState('1');

  const [durationItem, setDurationItem] = useState({});
  const [frequencyItem, setFrequencyItem] = useState({});

  const [levelItem, setLevelItem] = useState({});
  const [levelError, setLevelError] = useState(false);
  const [levelItemIndex, setLevelItemIndex] = useState('');
  const [deleteItemName, setDeleteItemName] = useState('');
  const [paymentError, setPaymentError] = useState(false);
  const [frequencyValues, setFrequencyValues] = useState([
    {id: '1', name: '1 month', value: '1'},
  ]);

  const handlePaymentFrequency = selectedDuartion => {
    console.log(selectedDuartion, 'selectedDuartionselectedDuartion');
    const frequencyArray = generatePaymentFrequency(
      selectedDuartion,
      durationArray,
    );

    setFrequencyValues(frequencyArray);
  };

  const handleFrequency = () => {
    if (paymentTypeId.includes('milestone')) {
      if (frequencyData.payment_amount === '') {
        errorMessage('Please add amount');
      } else if (frequencyData.payment_milestones.length === 0) {
        errorMessage('Please add milestones');
      } else {
        const valid = isCostEqualToSum(
          frequencyData.payment_milestones,
          frequencyData.payment_amount,
        );
        console.log(valid, '==========>validvalid');
        if (valid) {
          addFrequency();
        } else {
          errorMessage(labels.paymentMilestoneError[selected_lang]);
        }
      }
    } else {
      addFrequency();
    }
  };

  const addFrequency = () => {
    setlevelData({
      ...levelData,
      frequencies: [...levelData.frequencies, frequencyData],
    });

    setPaymentTypeId(['one_time']);
    setFrequencyData({
      duration: '1',
      payment_frequency: '1',
      payment_amount: '',
      renewal: false,
      payment_type: ['one_time'],
      subscription: RadioButtonName[0].id,
      payment_milestones: [],
    });
    setDurationType('1');
    setFrequencyType('1');

    setPaymentError(false);
  };

  const handleDurationMileStone = (type, index) => {
    console.log(
      paymentMilestone.amount,
      frequencyData.payment_milestones,
      frequencyData.payment_amount,
      'dddddddddddd',
    );
    if (frequencyData.payment_amount === '') {
      errorMessage('Please add amount');
      return;
    } else if (
      (paymentMilestone.date === '' || paymentMilestone.amount === '') &&
      type === 'add'
    ) {
      errorMessage(
        paymentMilestone.date === ''
          ? 'Please select date'
          : 'Please add amount',
      );
      return;
    } else if (
      !isWithinBudget(
        paymentMilestone.amount,
        frequencyData.payment_milestones,
        frequencyData.payment_amount,
      ) &&
      type !== 'remove'
    ) {
      errorMessage(
        `please select equal and less than $${frequencyData.payment_amount} `,
      );

      return;
    }

    const isValid = isPaymentMilestoneDuplicate(
      frequencyData.payment_milestones,
      paymentMilestone,
    );
    const validateDate = compareDates(
      getNameFromDurationValue(frequencyData.duration),
      paymentMilestone.date,
    );
    const dateIsValid = isDateMatched(
      frequencyData.payment_milestones,
      frequencyData.date,
    );
    console.log(validateDate, '----------date validation');
    // console.log(isValid, '-------->isValid');
    if (type === 'add' && isValid && validateDate && !dateIsValid) {
      setFrequencyData({
        ...frequencyData,
        payment_milestones: [
          ...frequencyData.payment_milestones,
          paymentMilestone,
        ],
      });
    } else if (type !== 'remove' && !isValid) {
      errorMessage('You have already added this date');
      return;
    } else if (type !== 'remove' && !validateDate) {
      errorMessage('Invalid date');
      return;
    } else if (type !== 'remove' && dateIsValid) {
      errorMessage('Date is repeated');
      return;
    }

    if (type === 'remove') {
      const newPaymentMilestones = frequencyData.payment_milestones.filter(
        (_, i) => i !== index,
      );

      setFrequencyData({
        ...frequencyData,
        payment_milestones: newPaymentMilestones,
      });
    }

    setPaymentMilestone({
      date: '',
      amount: '',
    });
  };

  const handlePaymentMileStone = (type, index) => {
    console.log(
      paymentMilestone.amount,
      catalogData.payment_milestones,
      catalogData.cost,
      'dddddddddddd',
    );
    if (catalogData.cost === '') {
      errorMessage('Please add first cost');
      return;
    } else if (
      (paymentMilestone.date === '' || paymentMilestone.amount === '') &&
      type === 'add'
    ) {
      errorMessage(
        paymentMilestone.date === ''
          ? 'Please select date'
          : 'Please add amount',
      );
      return;
    } else if (
      !isWithinBudget(
        paymentMilestone.amount,
        catalogData.payment_milestones,
        catalogData.cost,
      ) &&
      type !== 'remove'
    ) {
      errorMessage(`please select equal and less than $${catalogData.cost} `);

      return;
    }

    const isValid = isPaymentMilestoneDuplicate(
      catalogData.payment_milestones,
      paymentMilestone,
    );

    console.log(isValid, '-------->isValid');
    if (type === 'add' && isValid) {
      setCatalogData({
        ...catalogData,
        payment_milestones: [
          ...catalogData.payment_milestones,
          paymentMilestone,
        ],
      });
    } else if (type !== 'remove') {
      errorMessage('You have already added this date');
      return;
    }
    if (type === 'remove') {
      const newPaymentMilestones = catalogData.payment_milestones.filter(
        (_, i) => i !== index,
      );

      setCatalogData({
        ...catalogData,
        payment_milestones: newPaymentMilestones,
      });
    }

    setPaymentMilestone({
      date: '',
      amount: '',
    });
  };

  const removePaymentFrequency = indexToRemove => {
    setlevelData({
      ...levelData,
      frequencies: levelData.frequencies.filter(
        (_, index) => index !== indexToRemove,
      ),
    });
    setDleteLevelIndex('');
  };

  let plusIconStyle = {
    height: '25px',
    padding: '6px',
  };

  // const isLevelsExists = (name, stateArray,levelItemIndex) => {
  //   return stateArray.some(item => item.level_name === name);
  // };
  const isLevelsExists = (name, stateArray, levelItemIndex) => {
    return stateArray.some((item, index) => {
      if (typeof levelItemIndex === 'number') {
        return index !== levelItemIndex && item.level_name === name;
      } else {
        return item.level_name === name;
      }
    });
  };

  const handleLevels = () => {
    if (levelData.level_name === '' || levelData.frequencies.length === 0) {
      if (levelData.level_name === '') {
        setLevelError(true);
      } else if (levelData.frequencies.length === 0) {
        errorMessage('Please Add Duration');
      }

      return;
    } else {
      if (
        !isLevelsExists(
          levelData.level_name,
          catalogData.levels,
          levelItemIndex,
        )
      ) {
        if (levelItemIndex !== '') {
          setCatalogData({
            ...catalogData,
            levels: catalogData.levels.map((item, index) =>
              index === levelItemIndex ? levelData : item,
            ),
          });

          setLevelItemIndex('');
        } else {
          setCatalogData({
            ...catalogData,
            levels: [...catalogData.levels, levelData],
          });
        }
        setlevelData({
          level_name: '',
          frequencies: [],
        });

        setFrequencyData({
          duration: '1',
          payment_frequency: '1',
          payment_amount: '',
          renewal: false,
          payment_type: ['one_time'],
          subscription: RadioButtonName[0].id,
          payment_milestones: [],
        });

        setPaymentTypeId(['one_time']);
        setFrequencyType('1');
        setDurationType('1');

        setLevelError(false);
      } else {
        errorMessage('You have already added this Level name');
      }
    }
  };

  const removeMembershipLevels = indexToRemove => {
    setCatalogData({
      ...catalogData,
      levels: catalogData.levels.filter((_, index) => index !== indexToRemove),
    });
    setDleteLevelIndex('');
  };
  const editMembershipLevel = item => {
    setlevelData(item);
    setLevelItem({});
  };

  const handleResetlevel = () => {
    setLevelItemIndex('');
    setFrequencyData({
      duration: '1',
      payment_frequency: '1',
      payment_amount: '',
      renewal: false,
      payment_type: ['one_time'],
      subscription: RadioButtonName[0].id,
      payment_milestones: [],
    });
    setlevelData({
      level_name: '',
      frequencies: [],
    });
    setPaymentMilestone({
      date: '',
      amount: '',
    });
  };

  // useEffect(() => {
  //   if(catalogData.purchase_order === 'yes'){

  //     const newData = {
  //       ...catalogData,
  //       supplier_ids: [companySuppliers[0].id],
  //     };
  //     setCatalogData(newData);

  //    }else{
  //     const newData = {
  //       ...catalogData,
  //       supplier_ids: [],
  //     };
  //     setCatalogData(newData);
  //    }
  // }, [catalogData.purchase_order])

  const handlePurchase = () => {
    if (catalogData.purchase_order === 'yes') {
      setCatalogData({
        ...catalogData,
        supplier_ids: [companySuppliers[0].id],
      });
    } else {
      setCatalogData({
        ...catalogData,
        supplier_ids: [],
      });
    }
  };

  console.log(frequencyData, '---------->frequencyData');
  // console.log(typeForAliasRole, 'typeForAliasRoletypeForAliasRole');
  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };

  console.log(groupsDeatil, 'groupsDeatilgroupsDeatil');

  console.log(showGroupFields, 'showGroupFieldsshowGroupFields');

  return (
    <>
      <SlidingPanel
        renderData={renderProfileTagsButtons}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        hidePanel={() => {
          if (
            (ticketImg !== '' && !props.CatalogItem) ||
            (props.CatalogItem && props.CatalogItem.image !== ticketImg)
          ) {
            deleteUpdatedImage(ticketImg);
          }
          props.onClose();
        }}>
        <>
          <div className="catalog-main-content-block create-catalog-main-block">
            <div className="offering-txt-head fixed-width-inputs fix-width-forms pt-3 black-head-form-pad-left black-head-form-pad-right">
              <p className="p-bold f_600_h">
                {props.CatalogItem && props.CatalogItem
                  ? 'Update Offering'
                  : 'Create Offering'}
              </p>
            </div>
            {showLoader && <LoaderSpinner className={'curved-spinner-bg'} />}
            <Scrollbars autoHide>
              <div
                className="catalog-content catalog-content-extended fixed-width-inputs pt-3 black-head-form-pad-left black-head-form-pad-right catalog-content-advancement ml-1"
                style={{paddingBottom: '85px'}}>
                <>
                  <div className="" style={{marginRight: 30}}>
                    <div className="ticket-img-wrapper mb-3 m-0 ">
                      {ticketImg && (
                        <p
                          onClick={() => {
                            setShowModel('image_pre');
                          }}>
                          Onclick
                        </p>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={e => uploadCatalogImg(e)}
                      />
                      {ticketImg && <img src={ticketImg} alt="" />}
                      <i className="icon-camera" />
                    </div>
                  </div>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmitOfForm}>
                    <div className="mt-3">
                      {!props.CatalogItem && (
                        <div className="mb_12">
                          <Form.Label controlid="validationCustom01">
                            Offering Type
                          </Form.Label>

                          <Dropdown
                            className="select-wrapper customized-select-wrapper"
                            onSelect={(eventKey, event) => {
                              handlereset();
                              setCatalogName(eventKey);
                            }}
                            drop="down">
                            <Dropdown.Toggle variant="primary">
                              {groupSingelId
                                ? CatalogTypeList.find(
                                    item => item.id === catalogName,
                                  ).name
                                : 'Select Role'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {CatalogTypeList.map((item, index) => (
                                <Dropdown.Item
                                  key={`sessionCategories-${index}`}
                                  eventKey={item.id}
                                  active={item.id === catalogName}>
                                  {item.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}

                      {catalogName !== 'Product' &&
                        catalogName !== 'Service' &&
                        catalogName !== 'Rewards' &&
                        catalogName !== 'Play Center' &&
                        catalogName !== 'Membership' && (
                          <div className="input-group">
                            <Form.Label controlid="validationCustom01">
                              Type
                            </Form.Label>

                            <Form.Control
                              type="text"
                              value={catalogData.type}
                              pattern="^\S.*$"
                              required
                              placeholder=""
                              onChange={e => {
                                setCatalogData({
                                  ...catalogData,
                                  type: e.target.value,
                                });
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              type is required.
                            </Form.Control.Feedback>
                          </div>
                        )}

                      <div className="input-group customized-input-group mb_12">
                        <Form.Label controlid="validationCustom01">
                          {catalogName === 'Membership'
                            ? 'Membership Title'
                            : 'Title'}
                        </Form.Label>

                        <Form.Control
                          type="text"
                          value={catalogData.title}
                          className="input-membership-hold"
                          pattern="^\S.*$"
                          required
                          placeholder=""
                          onChange={e => {
                            setCatalogData({
                              ...catalogData,
                              title: e.target.value,
                            });
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Title is required.
                        </Form.Control.Feedback>
                      </div>

                      {catalogName === 'RE Listing' && (
                        <div className="input-group">
                          <Form.Label controlid="validationCustom01">
                            Address
                          </Form.Label>

                          <Form.Control
                            type="text"
                            value={catalogData.address}
                            pattern="^\S.*$"
                            required
                            placeholder=""
                            onChange={e => {
                              setCatalogData({
                                ...catalogData,
                                address: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Address is required.
                          </Form.Control.Feedback>
                        </div>
                      )}

                      {catalogName === 'RE Listing' && (
                        <div className="input-group">
                          <Form.Label controlid="validationCustom01">
                            Bedrooms
                          </Form.Label>

                          <Form.Control
                            type="text"
                            value={catalogData.bedrooms}
                            pattern="^\S.*$"
                            required
                            placeholder=""
                            onChange={e => {
                              setCatalogData({
                                ...catalogData,
                                bedrooms: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Bedrooms is required.
                          </Form.Control.Feedback>
                        </div>
                      )}

                      {catalogName === 'RE Listing' && (
                        <div className="input-group">
                          <Form.Label controlid="validationCustom01">
                            Bathrooms
                          </Form.Label>

                          <Form.Control
                            type="text"
                            value={catalogData.bathrooms}
                            pattern="^\S.*$"
                            required
                            placeholder=""
                            onChange={e => {
                              setCatalogData({
                                ...catalogData,
                                bathrooms: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Bathrooms is required.
                          </Form.Control.Feedback>
                        </div>
                      )}

                      <div className="customized-input-group mb_12">
                        <Form.Label controlid="validationCustom01">
                          Description
                        </Form.Label>

                        <Form.Control
                          as="textarea"
                          rows={6}
                          type="text"
                          value={catalogData.description}
                          className="input-membership-hold textarea-field-box-shadow"
                          pattern="^\S.*$"
                          required
                          placeholder=""
                          onChange={e => {
                            setCatalogData({
                              ...catalogData,
                              description: e.target.value,
                            });
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Description is required.
                        </Form.Control.Feedback>
                      </div>

                      {catalogName === 'Product' && (
                        <>
                          {/* <div className="input-group mb-2">
                            <div className="d-flex">
                              <div className="me-2">
                                <input
                                  className="form-check-input rounded-circle as-link"
                                  type="checkbox"
                                  onChange={e => {
                                    const newValue = e.target.checked
                                      ? 'yes'
                                      : 'no';
                                    setCatalogData({
                                      ...catalogData,
                                      purchase_order: newValue,
                                      supplier_ids:
                                        newValue === 'yes'
                                          ? [companySuppliers[0]?.id]
                                          : [],
                                    });

                                   
                                  }}
                                  checked={
                                    catalogData.purchase_order === 'yes'
                                      ? true
                                      : false
                                  }
                                  id={`flexCheck`}
                                />
                              </div>

                              <span className="purchase_order_span">
                                Purchase Orders
                              </span>
                              
                            </div>
                          </div> */}

                          {catalogData.purchase_order === 'yes' &&
                            (companySuppliers.length > 0 ? (
                              <div className="mb_12">
                                <Form.Label controlId="validationCustom01">
                                  Suppliers
                                </Form.Label>

                                <Dropdown
                                  className="select-wrapper customized-select-wrapper"
                                  onSelect={(eventKey, event) => {
                                    if (
                                      catalogData.supplier_ids.length === 1 &&
                                      catalogData.supplier_ids.includes(
                                        eventKey,
                                      )
                                    ) {
                                      errorMessage(
                                        'Atleast one supplier is required',
                                      );
                                    } else {
                                      const groupIds = [
                                        ...catalogData.supplier_ids,
                                      ]; // Copy the existing group ids
                                      if (!groupIds.includes(eventKey)) {
                                        // Add the selected group id if it's not already selected
                                        groupIds.push(eventKey);
                                      } else {
                                        // Remove the selected group id if it's already selected
                                        const index =
                                          groupIds.indexOf(eventKey);
                                        groupIds.splice(index, 1);
                                      }
                                      const newData = {
                                        ...catalogData,
                                        supplier_ids: groupIds,
                                      };
                                      setCatalogData(newData);
                                    }
                                  }}
                                  drop="down">
                                  <Dropdown.Toggle variant="primary">
                                    {catalogData.supplier_ids.length > 0
                                      ? catalogData.supplier_ids
                                          .map(groupId =>
                                            fixName(
                                              companySuppliers.find(
                                                item => item.id === groupId,
                                              )?.first_name,
                                              companySuppliers.find(
                                                item => item.id === groupId,
                                              )?.last_name,
                                            ),
                                          )
                                          .join(', ')
                                      : ' '}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {companySuppliers.map((item, index) => (
                                      <Dropdown.Item
                                        key={`sessionCategories-${index}`}
                                        eventKey={item.id}
                                        active={catalogData.supplier_ids.includes(
                                          item.id,
                                        )}>
                                        {fixName(
                                          item.first_name,
                                          item.last_name,
                                        )}
                                        {/* {item.group_name} */}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            ) : (
                              <div className="filter-btn-wrapper fix-width-forms ">
                                <div
                                  className="invite-btn text-center  pb-2"
                                  style={{bottom: 0}}>
                                  <Button
                                    style={{padding: '13px 35px'}}
                                    className="btn-dark mb-0 mt-2"
                                    onClick={() => {
                                      setShowModel('addSupplier');
                                    }}>
                                    Add Supplier
                                  </Button>
                                </div>
                              </div>
                            ))}
                        </>
                      )}

                      {catalogName === 'RE Listing' && (
                        <div className="input-group">
                          <Form.Label controlid="validationCustom01">
                            Amenities
                          </Form.Label>

                          <Form.Control
                            type="text"
                            value={catalogData.amenities}
                            pattern="^\S.*$"
                            required
                            placeholder=""
                            onChange={e => {
                              setCatalogData({
                                ...catalogData,
                                amenities: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Amenities is required.
                          </Form.Control.Feedback>
                        </div>
                      )}

                      {catalogName === 'Rewards' && (
                        <div className="input-group">
                          <Form.Label controlid="validationCustom01">
                            Retail Value
                          </Form.Label>

                          <Form.Control
                            type="text"
                            value={catalogData.retail_value}
                            pattern="^\S.*$"
                            required
                            placeholder=""
                            onChange={e => {
                              setCatalogData({
                                ...catalogData,
                                retail_value: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Retail is required.
                          </Form.Control.Feedback>
                        </div>
                      )}
                      {catalogName === 'Rental' && (
                        <div className="input-group">
                          <Form.Label controlid="validationCustom01">
                            Documents
                          </Form.Label>

                          <Form.Control
                            type="text"
                            value={catalogData.documents}
                            pattern="^\S.*$"
                            required
                            placeholder=""
                            onChange={e => {
                              setCatalogData({
                                ...catalogData,
                                documents: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Documents is required.
                          </Form.Control.Feedback>
                        </div>
                      )}
                      {catalogName !== 'Membership' && (
                        <div className="mb_12">
                          <Form.Label controlid="validationCustom01">
                            Audience
                          </Form.Label>
                          {catalogName === 'RE Listing' &&
                            Object.entries(AudienceTypes).length > 0 && (
                              <Dropdown
                                className="select-wrapper customized-select-wrapper"
                                onSelect={(eventKey, event) => {
                                  setTypeForAudience(eventKey);
                                  setCatalogData({
                                    ...catalogData,
                                    audience: eventKey,
                                  });
                                }}
                                drop="down">
                                <Dropdown.Toggle variant="primary">
                                  {typeForAudience
                                    ? AudienceTypes.find(
                                        item => item.value === typeForAudience,
                                      )?.name
                                    : 'Select Role'}
                                </Dropdown.Toggle>

                                {catalogName === 'RE Listing' && (
                                  <Dropdown.Menu>
                                    {AudienceTypes.map((item, index) => (
                                      <Dropdown.Item
                                        key={`sessionCategories-${index}`}
                                        eventKey={item.value}
                                        active={item.value === typeForAudience}>
                                        {item.name}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                )}
                              </Dropdown>
                            )}

                          {catalogName !== 'RE Listing' &&
                            rolesTypes &&
                            Object.entries(rolesTypes).length > 0 && (
                              <Dropdown
                                className="select-wrapper customized-select-wrapper"
                                onSelect={(eventKey, event) => {
                                  setTypeForAudience(eventKey);
                                  setCatalogData({
                                    ...catalogData,
                                    audience: eventKey,
                                  });
                                }}
                                drop="down">
                                <Dropdown.Toggle
                                  variant="primary"
                                  id="dropdown-basic">
                                  {typeForAudience
                                    ? rolesTypes.find(
                                        item => item.id === typeForAudience,
                                      ).role_name
                                    : 'Select Role'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {rolesTypes.map((item, index) => (
                                    <Dropdown.Item
                                      key={`sessionCategories-${index}`}
                                      eventKey={item.id}
                                      active={item.id === typeForAudience}>
                                      {item.role_name}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                        </div>
                      )}

                      <div className="mb_12">
                        <div
                          className="d-flex justify-content-between align-items-center"
                          id="unique-group-icon-holder">
                          <Form.Label controlid="validationCustom01">
                            Group/Single
                          </Form.Label>

                          <div className="search-tabs-icons search-createCatalog-tab-icons">
                            <span
                              className={
                                // showComponent === 'createCatalog'
                                //   ? 'icon active'
                                //   :

                                'icon'
                              }
                              onClick={() => {
                                if (groupSingelId === 'group') {
                                  setShowModel('add_group');
                                }
                              }}>
                              {groupSingelId === 'group' && (
                                <i className="icon-plus" />
                              )}
                            </span>
                          </div>
                        </div>

                        <Dropdown
                          className="select-wrapper customized-select-wrapper"
                          onSelect={(eventKey, event) => {
                            if (eventKey === 'group') {
                              // if (
                              //   allCatalogData?.service?.length +
                              //     allCatalogData?.product?.length >=
                              //   2
                              // ) {
                              setGroupSingelId(eventKey);
                              getGroupDetail();
                              const newData = {
                                ...catalogData,

                                group_type: eventKey,
                              };
                              setCatalogData(newData);
                              // } else {
                              //   errorMessage(
                              //     'Please add some product to add in group',
                              //   );
                              // }
                            } else {
                              setGroupSingelId(eventKey);
                              setShowGroupFields(false);

                              const newData = {
                                ...catalogData,
                                group_ids: [],
                                group_type: eventKey,
                              };
                              setCatalogData(newData);
                              setSelectedGroupIds([]);
                            }
                          }}
                          drop="down">
                          <Dropdown.Toggle variant="primary">
                            {groupSingelId
                              ? groupSingleArray.find(
                                  item => item.id === groupSingelId,
                                ).name
                              : 'Select Role'}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {groupSingleArray.map((item, index) => (
                              <Dropdown.Item
                                key={`sessionCategories-${index}`}
                                eventKey={item.id}
                                active={item.id === groupSingelId}>
                                {item.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      {showGroupFields && groupsDeatil.length > 0 && (
                        <div className="mb_12">
                          <Form.Label controlId="validationCustom01">
                            Groups Name
                          </Form.Label>
                          {/* 
                          <Dropdown
                            className="select-wrapper customized-select-wrapper"
                            onSelect={(eventKey, event) => {
                              if (
                                catalogData.group_ids.length === 1 &&
                                catalogData.group_ids.includes(eventKey)
                              ) {
                                errorMessage('Atleast one Group is required');
                              } else {
                                const groupIds = [...catalogData.group_ids]; // Copy the existing group ids
                                if (!groupIds.includes(eventKey)) {
                                  // Add the selected group id if it's not already selected
                                  groupIds.push(eventKey);
                                } else {
                                  // Remove the selected group id if it's already selected
                                  const index = groupIds.indexOf(eventKey);
                                  groupIds.splice(index, 1);
                                }
                                const newData = {
                                  ...catalogData,
                                  group_ids: groupIds,
                                };
                                setCatalogData(newData);
                              }
                            }}
                            drop="down">
                            <Dropdown.Toggle variant="primary">
                              {catalogData.group_ids.length > 0
                                ? catalogData.group_ids
                                    .map(
                                      groupId =>
                                        groupsDeatil.find(
                                          item => item.id === groupId,
                                        ).group_name,
                                    )
                                    .join(', ')
                                : 'Select Groups'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {groupsDeatil.map((item, index) => (
                                <Dropdown.Item
                                  key={`sessionCategories-${index}`}
                                  eventKey={item.id}
                                  active={catalogData.group_ids.includes(
                                    item.id,
                                  )}>
                                  {item.group_name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown> */}

                          <MultiSelectionDropdown
                            setDropdownIds={setSelectedGroupIds}
                            dropdownIds={selectedGroupIds}
                            DropDownValues={groupsDeatil}
                            itemName={['group_name']}
                            ErrorMessage={labels.groupRequired[selected_lang]}
                            DropdownType={'all'}
                          />
                        </div>
                      )}
                     {/* {addNewGroup && groupsDeatil.length === 0 && (
                        <div className="add_group_button">
                          <Button
                            onClick={() => {
                              setShowModel('add_group');
                            }}>
                            Add Group
                          </Button>
                        </div>
                      )}*/}
                      {catalogName === 'Equipment' && (
                        <div className="input-group">
                          <Form.Label controlid="validationCustom01">
                            Responsible Party
                          </Form.Label>

                          <Form.Control
                            type="text"
                            value={catalogData.responsible_party}
                            pattern="^\S.*$"
                            required
                            placeholder=""
                            onChange={e => {
                              setCatalogData({
                                ...catalogData,
                                responsible_party: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Field required.
                          </Form.Control.Feedback>
                        </div>
                      )}
                      {catalogName !== 'Equipment' &&
                        catalogName !== 'RE Listing' &&
                        catalogName !== 'Membership' && (
                          <div className="mb_12">
                            <Form.Label controlid="validationCustom01">
                              Delievery Method (virtual, in-person)
                            </Form.Label>

                            {/* catalogName !== 'Play Center' &&
                      catalogName !== 'Rewards' && */}
                            {DelieveryTypes &&
                              Object.entries(DelieveryTypes).length > 0 && (
                                <Dropdown
                                  className="select-wrapper customized-select-wrapper"
                                  onSelect={eventKey => {
                                    setTypeForDelievery(eventKey);
                                    setCatalogData({
                                      ...catalogData,
                                      delivery_method: eventKey,
                                    });
                                  }}
                                  drop="down">
                                  <Dropdown.Toggle variant="primary">
                                    {typeForDelievery
                                      ? DelieveryTypes.find(
                                          item =>
                                            item.value === typeForDelievery,
                                        )?.name
                                      : 'Select Role'}
                                  </Dropdown.Toggle>

                                  {catalogName === 'Service' && (
                                    <Dropdown.Menu>
                                      {DelieveryTypes.slice(0, 2).map(
                                        (item, index) => (
                                          <Dropdown.Item
                                            key={`sessionCategories-${index}`}
                                            eventKey={item.value}
                                            active={
                                              item.value === typeForDelievery
                                            }>
                                            {item.name}
                                          </Dropdown.Item>
                                        ),
                                      )}
                                    </Dropdown.Menu>
                                  )}

                                  {catalogName === 'Product' && (
                                    <Dropdown.Menu>
                                      {DelieveryTypes.slice(0, 3).map(
                                        (item, index) => (
                                          <Dropdown.Item
                                            key={`sessionCategories-${index}`}
                                            eventKey={item.value}
                                            active={
                                              item.value === typeForDelievery
                                            }>
                                            {item.name}
                                          </Dropdown.Item>
                                        ),
                                      )}
                                    </Dropdown.Menu>
                                  )}

                                  {(catalogName === 'Rental' ||
                                    catalogName === 'Material' ||
                                    catalogName === 'Fleet') && (
                                    <Dropdown.Menu>
                                      {DelieveryTypes.slice(4, 6).map(
                                        (item, index) => (
                                          <Dropdown.Item
                                            key={`sessionCategories-${index}`}
                                            eventKey={item.value}
                                            active={
                                              item.value === typeForDelievery
                                            }>
                                            {item.name}
                                          </Dropdown.Item>
                                        ),
                                      )}
                                    </Dropdown.Menu>
                                  )}

                                  {catalogName === 'Rewards' && (
                                    <Dropdown.Menu>
                                      {DelieveryTypes.slice(0, 4).map(
                                        (item, index) => (
                                          <Dropdown.Item
                                            key={`sessionCategories-${index}`}
                                            eventKey={item.value}
                                            active={
                                              item.value === typeForDelievery
                                            }>
                                            {item.name}
                                          </Dropdown.Item>
                                        ),
                                      )}
                                    </Dropdown.Menu>
                                  )}

                                  {catalogName === 'Play Center' && (
                                    <Dropdown.Menu>
                                      {DelieveryTypes.slice(0, 4).map(
                                        (item, index) => (
                                          <Dropdown.Item
                                            key={`sessionCategories-${index}`}
                                            eventKey={item.value}
                                            active={
                                              item.value === typeForDelievery
                                            }>
                                            {item.name}
                                          </Dropdown.Item>
                                        ),
                                      )}
                                    </Dropdown.Menu>
                                  )}
                                </Dropdown>
                              )}

                            {(catalogName === 'Play Center' ||
                              catalogName === 'Rewards') &&
                              DelieveryTypes2 &&
                              Object.entries(DelieveryTypes2).length > 0 && (
                                <Dropdown
                                  className="select-wrapper"
                                  onSelect={eventKey => {
                                    setTypeForDelievery(eventKey);
                                    setCatalogData({
                                      ...catalogData,
                                      delivery_method: eventKey,
                                    });
                                  }}
                                  drop="down">
                                  <Dropdown.Toggle variant="primary">
                                    {typeForDelievery
                                      ? DelieveryTypes2.find(
                                          item =>
                                            item.value === typeForDelievery,
                                        )?.name
                                      : 'Select Role'}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {DelieveryTypes2.map((item, index) => (
                                      <Dropdown.Item
                                        key={`sessionCategories-${index}`}
                                        eventKey={item.value}
                                        active={
                                          item.value === typeForDelievery
                                        }>
                                        {item.name}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                          </div>
                        )}

                      {catalogName === 'Membership' && (
                        <div className="membership_level">
                          <h1 className="mb-3 membership_pading">
                            Membership Levels
                          </h1>

                          <div className="d-flex gap-4 membership_pading">
                            <div className="flex-1">
                              <div className="input-group">
                                <Form.Label controlid="validationCustom01">
                                  Level Name
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  value={levelData.level_name}
                                  pattern="^\S.*$"
                                  placeholder=""
                                  onChange={e => {
                                    setlevelData({
                                      ...levelData,
                                      level_name: e.target.value,
                                    });
                                  }}
                                />

                                {levelError && levelData.level_name === '' && (
                                  <div className="text-danger textforerror">
                                    Name is required.
                                  </div>
                                )}
                              </div>
                              <div className="flex-1"></div>
                            </div>

                            <div className="flex-1"></div>
                          </div>

                          <div className="mb-1 mr-1 d-flex justify-content-end">
                            <div className="triger_plus_main">
                              <div
                                className="triger_plus"
                                onClick={() => {
                                  handleFrequency();
                                }}
                                style={plusIconStyle}>
                                <i className="fa-solid fa-plus"></i>
                              </div>
                            </div>
                          </div>

                          <div className="membership_level_fields mb-3">
                            <div className="d-flex gap-4 mb-3">
                              <div className="flex-1">
                                <div className="">
                                  <Form.Label controlid="validationCustom01">
                                    Duration
                                  </Form.Label>
                                  <Dropdown
                                    className="select-wrapper"
                                    onSelect={(eventKey, event) => {
                                      var item = JSON.parse(eventKey);
                                      handlePaymentFrequency(item.name);
                                      setDurationType(item.id);
                                      setDurationItem(item);
                                      setFrequencyData({
                                        ...frequencyData,
                                        duration: item.value,
                                        payment_milestones: [],
                                      });

                                      setFrequencyType('1');
                                    }}
                                    drop="down">
                                    <Dropdown.Toggle variant="primary">
                                      {durationType
                                        ? durationArray.find(
                                            item => item.id === durationType,
                                          ).name
                                        : 'Select Role'}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {durationArray.map((item, index) => (
                                        <Dropdown.Item
                                          key={`st-${index}`}
                                          eventKey={JSON.stringify(item)}
                                          active={item.id === durationType}>
                                          {item.name}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>

                              <div className="flex-1">
                                <div className="">
                                  <Form.Label controlid="validationCustom01">
                                    Payment Frequency
                                  </Form.Label>
                                  <Dropdown
                                    className="select-wrapper"
                                    onSelect={(eventKey, event) => {
                                      var item = JSON.parse(eventKey);
                                      setFrequencyType(item.id);
                                      setFrequencyItem(item);
                                      setFrequencyData({
                                        ...frequencyData,
                                        payment_frequency: item.value,
                                      });
                                    }}
                                    drop="down">
                                    <Dropdown.Toggle variant="primary">
                                      {frequencyType
                                        ? frequencyValues.find(
                                            item => item.id === frequencyType,
                                          ).name
                                        : 'Select Role'}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {frequencyValues.map((item, index) => (
                                        <Dropdown.Item
                                          key={`st-${index}`}
                                          eventKey={JSON.stringify(item)}
                                          active={item.id === frequencyType}>
                                          {item.name}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex gap-4 mb-2">
                              <div className="flex-1">
                                <div className="input-group">
                                  <Form.Label controlid="validationCustom01">
                                    Payment Amount
                                  </Form.Label>

                                  <Form.Control
                                    type="number"
                                    value={frequencyData.payment_amount}
                                    pattern="^\S.*$"
                                    placeholder=""
                                    onKeyDown={blockInvalidChar}
                                    onChange={e => {
                                      const {name, value} = e.target;
                                      const regex =
                                        /^(?!0(?!\.))\d*(\.\d{0,2})?$/;

                                      if (
                                        value === '' ||
                                        regex.test(value) ||
                                        value === '1'
                                      ) {
                                        setFrequencyData({
                                          ...frequencyData,
                                          payment_amount: e.target.value,
                                          payment_milestones: [],
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="catalog-radio-btns flex-1 d-flex  align-items-center">
                                <Form.Group className="fieldset payment-checkbox m-0 pt-4">
                                  <Form.Check
                                    // type="radio"
                                    type={'checkbox'}
                                    // name="radioGroup"
                                    label={'Allow Renewal'}
                                    id={'Points'}
                                    value={frequencyData.renewal}
                                    checked={frequencyData.renewal}
                                    onChange={() => {
                                      setFrequencyData({
                                        ...frequencyData,
                                        renewal: !frequencyData.renewal,
                                      });
                                    }}
                                  />
                                </Form.Group>
                              </div>
                            </div>

                            <div className="mb_12">
                              <Form.Label controlid="validationCustom01">
                                Payment Type
                              </Form.Label>
                              <MultiSelectionDropdown
                                setDropdownIds={setPaymentTypeId}
                                dropdownIds={paymentTypeId}
                                DropDownValues={[
                                  {title: 'All', id: 'all'},
                                  {title: 'One-time', id: 'one_time'},
                                  {title: 'Subscription', id: 'subscription'},
                                  {title: 'Milestone', id: 'milestone'},
                                ]}
                                itemName={['title']}
                                ErrorMessage={
                                  labels.paymentTypeError[selected_lang]
                                }
                                DropdownType={'all'}
                              />
                            </div>

                            {paymentTypeId.includes('subscription') && (
                              <div className="input-group customized-catalog-content-holder">
                                <Form.Label controlid="validationCustom01">
                                  Subscription
                                </Form.Label>

                                <div className="catalog-radio-btns customized-catalog-radio-btns">
                                  {Object.entries(RadioButtonName).length > 0 &&
                                    RadioButtonName.map((item, index) => (
                                      <Form.Check
                                        type="radio"
                                        key={item.id + index}
                                        // name="radioGroup"
                                        label={item.label}
                                        id={item.id}
                                        value={item.id}
                                        checked={subcribtionIdvalue === item.id}
                                        onChange={handleChange}
                                      />
                                    ))}
                                </div>
                              </div>
                            )}

                            {paymentTypeId.includes('milestone') && (
                              <Accordion
                                className="create-catalogue-accord-holder"
                                defaultActiveKey={null}>
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    {' '}
                                    Payment Milestone
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="mb-1 mr-1 d-flex justify-content-end">
                                      <div className="triger_plus_main">
                                        <div
                                          className="triger_plus"
                                          onClick={() => {
                                            handleDurationMileStone('add');
                                          }}
                                          style={plusIconStyle}>
                                          <i className="fa-solid fa-plus"></i>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex gap-4">
                                      <div className="flex-1 payment_milestone_label">
                                        <div className="input-group">
                                          <Form.Label controlid="validationCustom01">
                                            Date
                                          </Form.Label>
                                          {/* 
                                          <Form.Control
                                            type="date"
                                            value={paymentMilestone.date}
                                            pattern="^\S.*$"
                                            min={todayDate}
                                            placeholder=""
                                            onChange={e => {
                                              setPaymentMilestone({
                                                ...paymentMilestone,
                                                date: e.target.value,
                                              });
                                            }}
                                          /> */}

                                          <Calender
                                            minDate={todayDate}
                                            maxDate={getMaxDateFromToday}
                                            setSelectedDate={selectedDate => {
                                              if (selectedDate) {
                                                setPaymentMilestone({
                                                  ...paymentMilestone,
                                                  date: selectedDate,
                                                });
                                                // setDate(selectedDate);
                                              } else {
                                                setPaymentMilestone({
                                                  ...paymentMilestone,
                                                  date: '',
                                                });
                                              }
                                            }}
                                            selectedDate={
                                              paymentMilestone.date
                                                ? new Date(
                                                    paymentMilestone.date,
                                                  )
                                                : null
                                            }
                                            showDate={true}
                                            showTime={false}
                                          />

                                          {/* {levelError && levelData.level_name === '' && (
                                  <div className="text-danger textforerror">
                                    Name is required.
                                  </div>
                                )} */}
                                        </div>
                                      </div>
                                      <div className="flex-1">
                                        <div className="input-group payment_milestone_label">
                                          <Form.Label controlid="validationCustom01">
                                            Amount
                                          </Form.Label>

                                          <Form.Control
                                            type="number"
                                            value={paymentMilestone.amount}
                                            pattern="^\S.*$"
                                            placeholder=""
                                            onKeyDown={blockInvalidChar}
                                            onChange={e => {
                                              const {name, value} = e.target;
                                              const regex =
                                                /^(?!0(?!\.))\d*(\.\d{0,2})?$/;

                                              if (
                                                value === '' ||
                                                regex.test(value) ||
                                                value === '1'
                                              ) {
                                                setPaymentMilestone({
                                                  ...paymentMilestone,
                                                  amount: e.target.value,
                                                });
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    {frequencyData.payment_milestones.length >
                                      0 && (
                                      <div className="mt-3 mb-3">
                                        {Object.entries(
                                          frequencyData.payment_milestones,
                                        ).length > 0 &&
                                          frequencyData.payment_milestones.map(
                                            (item, index) => {
                                              return (
                                                <div
                                                  className="d-flex catalog_product_item payment_milestone_item create-catalog-payment-item mb-2 create-catalog-payment-item-new"
                                                  key={`level${index}`}>
                                                  <p className="input-label m-0">
                                                    {moment(item.date).format(
                                                      'MM/DD/YYYY',
                                                    )}{' '}
                                                    {'  '}
                                                    (${item.amount})
                                                  </p>
                                                  <div className="text-right mb-0">
                                                    <div className="d-flex gap-2 ">
                                                      <span
                                                        className="poc_delete as-link"
                                                        onClick={() => {
                                                          setDleteLevelIndex(
                                                            index,
                                                          );
                                                          setShowModel(
                                                            'deleteDuarationMilestone',
                                                          );
                                                          setDeleteItemName(
                                                            'Milestone Payment',
                                                          );
                                                          //
                                                        }}>
                                                        <i className="fa-solid fa-trash-can" />
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            },
                                          )}
                                      </div>
                                    )}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )}
                          </div>

                          <div className="membership_pading mb-3">
                            {Object.entries(levelData.frequencies).length > 0 &&
                              levelData.frequencies.map((item, index) => {
                                return (
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{marginBottom: '7px'}}>
                                    <p className="input-label m-0">
                                      {getNameOfDuration(item.duration)} {'  '}
                                      {item.payment_amount !== ''
                                        ? '$' + item.payment_amount
                                        : 'Free'}
                                    </p>
                                    <div className="text-right">
                                      <span
                                        className="poc_delete as-link"
                                        onClick={() => {
                                          setDleteLevelIndex(index);
                                          setShowModel('showDeleteDuration');
                                          setDeleteItemName('Duration');
                                        }}>
                                        <i className="fa-solid fa-trash-can" />
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>

                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <Button
                              className="new-btn btn-green"
                              onClick={() => {
                                handleLevels();
                              }}>
                              {levelItemIndex === ''
                                ? 'Add Level'
                                : 'Update Level'}
                            </Button>
                          </div>

                          {catalogData.levels.length > 0 && (
                            <div className="mt-3 mb-3">
                              {Object.entries(catalogData.levels).length > 0 &&
                                catalogData.levels.map((item, index) => {
                                  return (
                                    <div
                                      className="d-flex catalog_product_item mb-2"
                                      key={`level${index}`}>
                                      <p className="input-label m-0">
                                        {item.level_name}
                                      </p>
                                      <div className="text-right mb-2">
                                        <div className="d-flex gap-2 ">
                                          <span
                                            className=" as-link"
                                            onClick={() => {
                                              setLevelItemIndex(index);
                                              setLevelItem(item);
                                              setShowModel('showEditlevel');
                                              // editMembershipLevel(item);
                                            }}>
                                            <EditPencilSvg fill={'#52bd41'} />
                                          </span>

                                          <span
                                            className="poc_delete as-link"
                                            onClick={() => {
                                              setDleteLevelIndex(index);
                                              setShowModel('showDeletelevel');
                                              setDeleteItemName('Level');
                                              //
                                            }}>
                                            <i className="fa-solid fa-trash-can" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      )}

                      <div className="input-group customized-input-group mb_12">
                        <Form.Label controlid="validationCustom01">
                          {' '}
                          Taxes{' '}
                        </Form.Label>

                        <Form.Control
                          type="number"
                          value={catalogData.taxes_fee}
                          className="input-membership-hold"
                          pattern="^\S.*$"
                          placeholder=""
                          onChange={e => {
                            setCatalogData({
                              ...catalogData,
                              taxes_fee: e.target.value,
                            });
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Taxes is required.
                        </Form.Control.Feedback>
                      </div>
                      <div className="input-group customized-input-group mb_12">
                        <Form.Label controlid="validationCustom01">
                          {' '}
                          Discount{' '}
                        </Form.Label>

                        <Form.Control
                          type="number"
                          value={catalogData.discount}
                          className="input-membership-hold"
                          pattern="^\S.*$"
                          placeholder=""
                          onChange={e => {
                            setCatalogData({
                              ...catalogData,
                              discount: e.target.value,
                            });
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Discount is required.
                        </Form.Control.Feedback>
                      </div>
                      {catalogName === 'Product' && (
                        <div className="input-group customized-input-group mb_12">
                          <Form.Label controlid="validationCustom01">
                            {' '}
                            Shipment{' '}
                          </Form.Label>

                          <Form.Control
                            type="number"
                            value={catalogData.shipping_handling}
                            className="input-membership-hold"
                            pattern="^\S.*$"
                            placeholder=""
                            onChange={e => {
                              setCatalogData({
                                ...catalogData,
                                shipping_handling: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Shipment is required.
                          </Form.Control.Feedback>
                        </div>
                      )}
                      {catalogName !== 'Product' && (
                        <div className="input-group customized-input-group mb_12">
                          <Form.Label controlid="validationCustom01">
                            {' '}
                            Late Fee{' '}
                          </Form.Label>

                          <Form.Control
                            type="number"
                            value={catalogData.late_fee}
                            pattern="^\S.*$"
                            placeholder=""
                            onChange={e => {
                              setCatalogData({
                                ...catalogData,
                                late_fee: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Late Fee is required.
                          </Form.Control.Feedback>
                        </div>
                      )}
                      {catalogName !== 'Product' && (
                        // <div className="input-group customized-input-group mb_12">
                        //   <Form.Label controlid="validationCustom01">
                        //     {' '}
                        //     Grace Period{' '}
                        //   </Form.Label>

                        //   <Form.Control
                        //     type="date"
                        //     value={catalogData.grace_period}
                        //     pattern="^\S.*$"
                        //     placeholder=""
                        //     onChange={e => {
                        //       setCatalogData({
                        //         ...catalogData,
                        //         grace_period: e.target.value,
                        //       });
                        //     }}
                        //   />
                        //   <Form.Control.Feedback type="invalid">
                        //     Grace Period is required.
                        //   </Form.Control.Feedback>
                        // </div>
                        <div className="mb-3">
                          <Form.Label controlid="validationCustom01">
                            Grace Period{' '}
                          </Form.Label>
                          <Dropdown
                            className="select-wrapper"
                            onSelect={(eventKey, event) => {
                              setCatalogData({
                                ...catalogData,
                                grace_period: eventKey,
                              });
                              setGracePeriodType(eventKey);
                            }}
                            drop="down">
                            <Dropdown.Toggle variant="primary">
                              {monthDays
                                ? monthDays.find(
                                    item => item === gracePeriodType,
                                  )
                                : 'Select Role'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {monthDays.map((item, index) => (
                                <Dropdown.Item
                                  key={`st-${index}`}
                                  eventKey={item}
                                  active={item === gracePeriodType}>
                                  {item}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}

                      {catalogName === 'Play Center' && (
                        <div className="input-group customized-input-group">
                          <Form.Label controlid="validationCustom01">
                            Media Type
                          </Form.Label>

                          <Form.Control
                            type="text"
                            value={catalogData.media_type}
                            pattern="^\S.*$"
                            required
                            placeholder=""
                            onChange={e => {
                              setCatalogData({
                                ...catalogData,
                                media_type: e.target.value,
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Media is required.
                          </Form.Control.Feedback>
                        </div>
                      )}
                      {catalogName !== 'Service' &&
                        catalogName !== 'RE Listing' &&
                        catalogName !== 'Rewards' &&
                        catalogName !== 'Play Center' &&
                        catalogName !== 'Membership' && (
                          <div className="input-group customized-input-group mb_12">
                            <Form.Label controlid="validationCustom01">
                              {catalogName === 'Equipment'
                                ? 'Quantity'
                                : 'Quantity Available'}
                            </Form.Label>

                            <Form.Control
                              // type="text"
                              type="number"
                              onWheel={event => event.currentTarget.blur()}
                              value={catalogData.quantity_available}
                              className="input-membership-hold"
                              pattern="^\S.*$"
                              required
                              placeholder=""
                              onChange={e => {
                                setCatalogData({
                                  ...catalogData,
                                  quantity_available: e.target.value,
                                });
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Quantity is required.
                            </Form.Control.Feedback>
                          </div>
                        )}

                      {catalogName === 'Service' && (
                        <div className="input-group mb_12">
                          <Form.Label controlid="validationCustom01">
                            Duration (mins)
                          </Form.Label>

                          <Form.Control
                            type="number"
                            onWheel={event => event.currentTarget.blur()}
                            value={catalogData.duration}
                            pattern="^\S.*$"
                            placeholder=""
                            onChange={e => {
                              setCatalogData({
                                ...catalogData,
                                duration: e.target.value,
                              });
                            }}
                          />
                          {/* <Form.Control.Feedback type="invalid">
                            Duration is required.
                          </Form.Control.Feedback> */}
                        </div>
                      )}
                      {catalogName !== 'Play Center' &&
                        catalogName !== 'Membership' && (
                          <div className="input-group customized-input-group mb_12">
                            <Form.Label controlid="validationCustom01">
                              {catalogName === 'Rewards'
                                ? 'Recipient Cost'
                                : catalogName !== 'Rental' &&
                                  catalogName !== 'Material' &&
                                  catalogName !== 'Equipment' &&
                                  catalogName !== 'Fleet'
                                ? 'Cost'
                                : 'Replacement Cost'}
                            </Form.Label>

                            <Form.Control
                              value={catalogData.cost}
                              className="input-membership-hold"
                              type="number"
                              onWheel={event => event.currentTarget.blur()}
                              required
                              placeholder=""
                              onKeyDown={blockInvalidChar}
                              onChange={e => {
                                const {name, value} = e.target;
                                const regex = /^(?!0(?!\.))\d*(\.\d{0,2})?$/;

                                if (
                                  value === '' ||
                                  regex.test(value) ||
                                  value === '1'
                                ) {
                                  catalogName === 'Rewards'
                                    ? setCatalogData({
                                        ...catalogData,
                                        recipient_cost: e.target.value,
                                      })
                                    : catalogName !== 'Rental' &&
                                      catalogName !== 'Material' &&
                                      catalogName !== 'Equipment'
                                    ? setCatalogData({
                                        ...catalogData,
                                        cost: e.target.value,
                                      })
                                    : setCatalogData({
                                        ...catalogData,
                                        replacement_cost: e.target.value,
                                      });
                                }
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Cost is required.
                            </Form.Control.Feedback>
                          </div>
                        )}
                      {catalogName !== 'Membership' && (
                        <div className="mb_12">
                          <Form.Label controlid="validationCustom01">
                            Payment Type
                          </Form.Label>
                          <MultiSelectionDropdown
                            setDropdownIds={setPaymentTypeId}
                            dropdownIds={paymentTypeId}
                            DropDownValues={[
                              {title: 'All', id: 'all'},
                              {title: 'One-time', id: 'one_time'},
                              {title: 'Subscription', id: 'subscription'},
                              {title: 'Milestone', id: 'milestone'},
                            ]}
                            itemName={['title']}
                            DropdownType={'all'}
                            ErrorMessage={
                              labels.paymentTypeError[selected_lang]
                            }
                            // className={'mb-3 recruitment_dropdown'}
                          />
                        </div>
                      )}
                      {catalogName !== 'Membership' &&
                        paymentTypeId.includes('milestone') && (
                          <Accordion
                            className="create-catalogue-accord-holder"
                            defaultActiveKey={null}>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                {' '}
                                Payment Milestone
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="mb-1 mr-1 d-flex justify-content-end">
                                  <div className="triger_plus_main">
                                    <div
                                      className="triger_plus"
                                      onClick={() => {
                                        handlePaymentMileStone('add');
                                      }}
                                      style={plusIconStyle}>
                                      <i className="fa-solid fa-plus"></i>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex gap-4">
                                  <div className="flex-1 payment_milestone_label">
                                    <div className="input-group">
                                      <Form.Label controlid="validationCustom01">
                                        Date
                                      </Form.Label>

                                      {/* <Form.Control
                                        type="date"
                                        value={paymentMilestone.date}
                                        pattern="^\S.*$"
                                        min={todayDate}
                                        placeholder=""
                                        onChange={e => {
                                          setPaymentMilestone({
                                            ...paymentMilestone,
                                            date: e.target.value,
                                          });
                                        }}
                                      /> */}

                                      <Calender
                                        minDate={todayDate}
                                        maxDate={getMaxDateFromToday}
                                        setSelectedDate={selectedDate => {
                                          if (selectedDate) {
                                            setPaymentMilestone({
                                              ...paymentMilestone,
                                              date: selectedDate,
                                            });
                                            // setDate(selectedDate);
                                          } else {
                                            setPaymentMilestone({
                                              ...paymentMilestone,
                                              date: '',
                                            });
                                          }
                                        }}
                                        selectedDate={
                                          paymentMilestone.date
                                            ? new Date(paymentMilestone.date)
                                            : null
                                        }
                                        showDate={true}
                                        showTime={false}
                                      />

                                      {/* {levelError && levelData.level_name === '' && (
                                  <div className="text-danger textforerror">
                                    Name is required.
                                  </div>
                                )} */}
                                    </div>
                                  </div>
                                  <div className="flex-1">
                                    <div className="input-group payment_milestone_label">
                                      <Form.Label controlid="validationCustom01">
                                        Amount
                                      </Form.Label>

                                      <Form.Control
                                        type="number"
                                        value={paymentMilestone.amount}
                                        pattern="^\S.*$"
                                        placeholder=""
                                        onKeyDown={blockInvalidChar}
                                        onChange={e => {
                                          const {name, value} = e.target;
                                          const regex =
                                            /^(?!0(?!\.))\d*(\.\d{0,2})?$/;

                                          if (
                                            value === '' ||
                                            regex.test(value) ||
                                            value === '1'
                                          ) {
                                            setPaymentMilestone({
                                              ...paymentMilestone,
                                              amount: value,
                                            });
                                          }
                                        }}
                                      />

                                      {levelError &&
                                        levelData.level_name === '' && (
                                          <div className="text-danger textforerror">
                                            Amount is required.
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>

                                {catalogData.payment_milestones.length > 0 && (
                                  <div className="mt-3 mb-3">
                                    {Object.entries(
                                      catalogData.payment_milestones,
                                    ).length > 0 &&
                                      catalogData.payment_milestones.map(
                                        (item, index) => {
                                          return (
                                            <div
                                              className="d-flex catalog_product_item payment_milestone_item mb-2 create-catalog-payment-item"
                                              key={`level${index}`}>
                                              <p className="input-label m-0">
                                                {moment(item.date).format(
                                                  'MM/DD/YYYY',
                                                )}{' '}
                                                {'  '}
                                                (${item.amount})
                                              </p>
                                              <div className="text-right mb-0">
                                                <div className="d-flex gap-2 ">
                                                  <span
                                                    className="poc_delete as-link"
                                                    onClick={() => {
                                                      setDleteLevelIndex(index);
                                                      setShowModel(
                                                        'deleteMilestonePayment',
                                                      );
                                                      setDeleteItemName(
                                                        'Milestone Payment',
                                                      );
                                                      //
                                                    }}>
                                                    <i className="fa-solid fa-trash-can" />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        },
                                      )}
                                  </div>
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        )}

                      <Accordion
                        className="create-catalogue-accord-holder"
                        defaultActiveKey={null}>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header> Advanced</Accordion.Header>
                          <Accordion.Body>
                            {catalogName === 'Material' ||
                              (catalogName === 'Fleet' && (
                                <div className="input-group">
                                  <Form.Label controlid="validationCustom01">
                                    Restock Frequency
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    value={catalogData.restock}
                                    pattern="^\S.*$"
                                    required
                                    placeholder=""
                                    onChange={e => {
                                      setCatalogData({
                                        ...catalogData,
                                        restock: e.target.value,
                                      });
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Restock is required.
                                  </Form.Control.Feedback>
                                </div>
                              ))}
                            {paymentTypeId.includes('subscription') &&
                              catalogName !== 'RE Listing' &&
                              catalogName !== 'Rewards' &&
                              catalogName !== 'Play Center' &&
                              catalogName !== 'Membership' && (
                                <div className="input-group customized-catalog-content-holder">
                                  <Form.Label controlid="validationCustom01">
                                    Subscription
                                  </Form.Label>

                                  <div className="catalog-radio-btns customized-catalog-radio-btns">
                                    {Object.entries(RadioButtonName).length >
                                      0 &&
                                      RadioButtonName.map((item, index) => (
                                        <Form.Check
                                          type="radio"
                                          // name="radioGroup"
                                          key={item.id + index}
                                          label={item.label}
                                          id={item.id}
                                          value={item.id}
                                          checked={
                                            subcribtionIdvalue === item.id
                                          }
                                          onChange={handleChange}
                                        />
                                      ))}
                                  </div>
                                </div>
                              )}
                            <div className="input-group incentives-group-holder">
                              <Form.Label controlid="validationCustom01">
                                {catalogName !== 'Play Center'
                                  ? 'Incentives'
                                  : 'Completion Incentives'}
                              </Form.Label>

                              <div className="input-group">
                                <div className="catalog-radio-btns customized-catalog-radio-btns customized-catalog-radio-boxes">
                                  <Form.Group className="fieldset payment-checkbox">
                                    <Form.Check
                                      type={'checkbox'}
                                      // name="radioGroup"
                                      label={'Points'}
                                      id={'Points'}
                                      value={'Points'}
                                      checked={incentiveValues.includes(
                                        'Points',
                                      )}
                                      onChange={handleIncentivesValues}
                                    />
                                  </Form.Group>

                                  <Form.Group className="fieldset payment-checkbox">
                                    <Form.Check
                                      // type="radio"
                                      type={'checkbox'}
                                      // name="radioGroup"
                                      label={'Money'}
                                      id={'Money'}
                                      value={'Money'}
                                      checked={incentiveValues.includes(
                                        'Money',
                                      )}
                                      onChange={handleIncentivesValues}
                                    />
                                  </Form.Group>
                                  <div className="catalog-incentive-dropdown"></div>
                                </div>
                              </div>
                            </div>

                            {incentiveValues.includes('Points') && (
                              <div className="mb-2">
                                <div className="d-flex gap-4">
                                  <div className="input-group roles-group-holder">
                                    <Form.Label controlid="validationCustom01">
                                      Roles
                                    </Form.Label>
                                    {aliasRoles &&
                                      Object.entries(aliasRoles).length > 0 && (
                                        <Dropdown
                                          className="select-wrapper"
                                          onSelect={(eventKey, event) => {
                                            setTypeForAliasRole(eventKey);
                                          }}
                                          drop="down">
                                          <Dropdown.Toggle variant="primary">
                                            {typeForAliasRole
                                              ? capitalizeFirst(
                                                  aliasRoles.find(
                                                    item =>
                                                      item.id ===
                                                      typeForAliasRole,
                                                  )?.role_alias,
                                                )
                                              : 'Select Role'}
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            {aliasRoles.map((item, index) => (
                                              <Dropdown.Item
                                                key={`sessionCategories-${index}`}
                                                eventKey={item.id}
                                                active={
                                                  item.id === typeForAliasRole
                                                }>
                                                {capitalizeFirst(
                                                  item.role_alias,
                                                )}
                                              </Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      )}
                                  </div>

                                  <div className="input-group money-group-holder">
                                    <Form.Label controlid="validationCustom01">
                                      Points
                                    </Form.Label>

                                    <Form.Control
                                      value={pointsValue}
                                      novalidate
                                      type="number"
                                      onWheel={event =>
                                        event.currentTarget.blur()
                                      }
                                      placeholder=""
                                      onKeyDown={blockInvalidChar}
                                      onChange={e => {
                                        const {name, value} = e.target;
                                        const regex =
                                          /^(?!0(?!\.))\d*(\.\d{0,2})?$/;

                                        if (
                                          value === '' ||
                                          regex.test(value) ||
                                          value === '1'
                                        ) {
                                          setPointsValue(value);
                                        }
                                      }}
                                    />
                                  </div>

                                  <div className="triger_plus_main triger_plus_main_extra">
                                    <div
                                      className="triger_plus"
                                      onClick={() => {
                                        if (pointsValue !== '') {
                                          handlePointsMoney(
                                            typeForAliasRole,
                                            pointsValue,
                                          );
                                        } else {
                                          errorMessage('Please Add Points');
                                        }
                                      }}>
                                      <i className="fa-solid fa-plus"></i>
                                    </div>
                                  </div>
                                </div>

                                {rolesPointsMoney.length > 0 && (
                                  <table className="location-table">
                                    <thead>
                                      <tr>
                                        <th>Roles</th>
                                        <th>Points</th>
                                        <th>Delete</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Object.entries(rolesPointsMoney).length >
                                        0 &&
                                        rolesPointsMoney.map((item, index) => (
                                          <tr
                                            key={`label-${index}`}
                                            className="location-item">
                                            <td
                                              onClick={() => {
                                                // removeLocation(item.latitude);
                                                // setShowModel(true);
                                              }}>
                                              {item.alias_name}
                                            </td>
                                            <td
                                              onClick={() => {
                                                // removeLocation(item.latitude);
                                                // setShowModel(true);
                                              }}>
                                              {item.points}
                                            </td>
                                            <td>
                                              <i
                                                className="fas fa-times"
                                                onClick={() => {
                                                  removeAssignPointsRoles(
                                                    item.alias_id,
                                                  );
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                )}
                              </div>
                            )}

                            {incentiveValues.includes('Money') && (
                              <div className="mt-0">
                                <div className="d-flex gap-4">
                                  <div className="input-group roles-group-holder">
                                    <Form.Label controlid="validationCustom01">
                                      Roles
                                    </Form.Label>
                                    {aliasRoles &&
                                      Object.entries(aliasRoles).length > 0 && (
                                        <Dropdown
                                          className="select-wrapper roles-select-wrapper"
                                          onSelect={(eventKey, event) => {
                                            setTypeForAliasRoleM(eventKey);
                                          }}
                                          drop="down"
                                          //  style={{width:'50%'}}
                                        >
                                          <Dropdown.Toggle variant="primary">
                                            {typeForAliasRoleM
                                              ? capitalizeFirst(
                                                  aliasRoles.find(
                                                    item =>
                                                      item.id ===
                                                      typeForAliasRoleM,
                                                  )?.role_alias,
                                                )
                                              : 'Select Role'}
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            {aliasRoles.map((item, index) => (
                                              <Dropdown.Item
                                                key={`sessionCategories-${index}`}
                                                eventKey={item.id}
                                                active={
                                                  item.id === typeForAliasRoleM
                                                }>
                                                {capitalizeFirst(
                                                  item.role_alias,
                                                )}
                                              </Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      )}
                                  </div>

                                  <div className="input-group money-group-holder">
                                    <Form.Label controlid="validationCustom01">
                                      Money
                                    </Form.Label>

                                    <Form.Control
                                      value={moneyValue}
                                      novalidate
                                      type="number"
                                      onWheel={event =>
                                        event.currentTarget.blur()
                                      }
                                      placeholder=""
                                      onKeyDown={blockInvalidChar}
                                      onChange={e => {
                                        const {name, value} = e.target;
                                        const regex =
                                          /^(?!0(?!\.))\d*(\.\d{0,2})?$/;

                                        if (
                                          value === '' ||
                                          regex.test(value) ||
                                          value === '1'
                                        ) {
                                          setMoneyValue(value);
                                        }
                                      }}
                                    />
                                  </div>

                                  <div className="triger_plus_main triger_plus_main_extra">
                                    <div
                                      className="triger_plus"
                                      onClick={() => {
                                        if (moneyValue !== '') {
                                          handleMoney(
                                            typeForAliasRoleM,
                                            moneyValue,
                                          );
                                        } else {
                                          errorMessage('Please Add Money');
                                        }
                                      }}>
                                      <i className="fa-solid fa-plus"></i>
                                    </div>
                                  </div>
                                </div>

                                {rolesAssignMoney.length > 0 && (
                                  <table className="location-table">
                                    <thead>
                                      <tr>
                                        <th>Roles</th>
                                        <th>Money</th>
                                        <th>Delete</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Object.entries(rolesAssignMoney).length >
                                        0 &&
                                        rolesAssignMoney.map((item, index) => (
                                          <tr
                                            key={`label-${index}`}
                                            className="location-item">
                                            <td onClick={() => {}}>
                                              {item.alias_name}
                                            </td>
                                            <td onClick={() => {}}>
                                              {item.money}
                                            </td>
                                            <td>
                                              <i
                                                className="fas fa-times"
                                                onClick={() => {
                                                  removeAssignMoneyRoles(
                                                    item.alias_id,
                                                  );
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                )}
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      <Button
                        className="btn-dark"
                        type="submit"
                        style={{display: 'none'}}
                        ref={buttonRef}>
                        Save
                      </Button>
                    </div>
                  </Form>
                </>
              </div>
            </Scrollbars>

            <div className="filter-btn-wrapper">
              <div
                className="invite-btn text-center set_opacity bg_transparent pb-2"
                style={{bottom: 0}}>
                <Button
                  style={{padding: '13px 35px'}}
                  className="btn-dark mb-0 mt-2"
                  onClick={() => {
                    buttonRef.current.click();
                  }}>
                  {props.CatalogItem ? (
                    <span>Update {catalogName}</span>
                  ) : (
                    <span>Create {catalogName}</span>
                  )}

                  {/* {props.editDealItem ? 'Update' : 'Save'} */}
                </Button>
              </div>
            </div>
          </div>
        </>
      </SlidingPanel>

      {showModel === 'uploadCatalogImg' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}>
          <CropImage
            type="templateCatalog"
            ratio={50 / 50}
            file={file}
            setFile={setFile}
            setCropImg={setShowModel}
            imgUrl={ticketImg}
            setImgUrl={setTicketImg}
            setshowLoader={setShowLoader}
          />
        </CustomModal>
      )}

      {showModel === 'add_group' && (
        <CreateCatalogGroup
          type={'catalog'}
          component={'offering'}
          onClose={type => {
            setShowModel('');
            getGroupDetail();
            getCompanyCatalog();
          }}
        />
      )}
      {showModel === 'showDeletelevel' && (
        <ConfirmationModel
          closeModel={() => {
            setShowModel('');
          }}
          discription={'Are you sure you want to remove this level'}
          buttonOne={'Yes'}
          buttonTwo={'No'}
          ButtonOneAction={() => {
            removeMembershipLevels(dleteLevelIndex);
            handleResetlevel();
            setShowModel('');
          }}
        />
      )}

      {showModel === 'deleteMilestonePayment' && (
        <ConfirmationModel
          closeModel={() => {
            setShowModel('');
          }}
          discription={'Are you sure you want to remove this milestone?'}
          buttonOne={'Yes'}
          buttonTwo={'No'}
          ButtonOneAction={() => {
            handlePaymentMileStone('remove', dleteLevelIndex);
            setShowModel('');
          }}
        />
      )}

      {showModel === 'deleteDuarationMilestone' && (
        <ConfirmationModel
          closeModel={() => {
            setShowModel('');
          }}
          discription={'Are you sure you want to remove this  milestone?'}
          buttonOne={'Yes'}
          buttonTwo={'No'}
          ButtonOneAction={() => {
            handleDurationMileStone('remove', dleteLevelIndex);
            setShowModel('');
          }}
        />
      )}

      {showModel === 'showDeleteDuration' && (
        <ConfirmationModel
          closeModel={() => {
            setShowModel('');
          }}
          discription={'Are you sure you want to remove this duration?'}
          buttonOne={'Yes'}
          buttonTwo={'No'}
          ButtonOneAction={() => {
            removePaymentFrequency(dleteLevelIndex);
            setShowModel('');
          }}
        />
      )}

      {showModel === 'showEditlevel' && (
        <ConfirmationModel
          closeModel={() => {
            setShowModel('');
          }}
          discription={'Are you sure you want to edit this level?'}
          buttonOne={'Yes'}
          buttonTwo={'No'}
          ButtonOneAction={() => {
            editMembershipLevel(levelItem);
            setShowModel('');
          }}
        />
      )}

      {showModel === 'addSupplier' && (
        <SendInvitation
          type={'Catalog'}
          onClose={() => {
            setShowModel('');
          }}
          onCloseHitApi={() => {
            getCompanySupplier('addSupplier');
          }}
        />
      )}

      {showModel === 'image_pre' && ticketImg && (
        <ImagePreview
          modelImg={ticketImg}
          onClose={() => {
            setShowModel('');
          }}
        />
      )}
    </>
  );
};
export default CreateCatalog;

import React, {useState, useEffect, useRef} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import CustomTabs from '../CustomTabs';
import commonApi from '../../Services/CommonService';
import {capitalizeFirst} from '../../Utils/commonUtils';
import {errorMessage} from '../../Utils/ToastMessages';
import {successMessage} from '../../Utils/ToastMessages';
import {useDispatch} from 'react-redux';
import {getPipelineData} from '../../Store/Actions/ProfileAction';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import {checkPhases} from '../../Utils/commonUtils';
import {getUpdateDataOfPipeline} from '../../Store/Actions/ProfileAction';
import {RoiLogo} from '../../Constants/Images';
const CreatePipeLine = props => {
  const [tabType, setTabType] = useState(
    labels.sessions_type_general[selected_lang],
  );
  const [subTabType, setSubTabsType] = useState('team');
  const [showSearchRoles, setShowSearchRoles] = useState('');
  const [aliasRoles, setAliasRoles] = useState([]);

  const [phaseName, setPhaseName] = useState('');
  const [pipeLineId, setPipeLineId] = useState('');
  const [pipeLineName, setPipeLineName] = useState('');
  const [description, setDescription] = useState('');

  const [showDeleteModel, setShowDeleteModel] = useState('');
  const [showDeleteModeUsers, setShowDeleteModeUsers] = useState('');
  const [phaseValue, setPhaseValue] = useState('');
  const [orginalPhaseItem, setOriginalPhaseItem] = useState({});

  const [selectedAliasCustomerRoles, setSelectedAliasCustomerRoles] = useState(
    [],
  );
  const [selectedAliasTeamRoles, setSelectedAliasTeamRoles] = useState([]);
  const [selectedAliasCustomerIds, setSelectedAliasCustomerIds] = useState([]);
  const [selectedAliasTeamIds, setSelectedAliasTeamIds] = useState([]);

  const [phaseError, setPhaseError] = useState(false);

  const [selectedAliasCreateIds, setSelectedAliasCreateIds] = useState([]);
  const [selectedAliasUpdateIds, setSelectedAliasUpdateIds] = useState([]);
  const [selectedAliasReadIds, setSelectedAliasReadIds] = useState([]);

  const [pipeLinePhases, setPipeLinePhases] = useState([]);
  const [originalPhases, setOriginalPhases] = useState([]);
  const [originalPhases2, setOriginalPhases2] = useState([]);
  const [originalPhases3, setOriginalPhases3] = useState([]);

  const [shiftPhaseId, setShiftPhaseId] = useState([]);
  const [shiftPhaseIdList, setShiftPhaseIdList] = useState([]);
  const [newPhases, setNewPhases] = useState([]);
  const [deletePhases, setDeletePhases] = useState([]);
  const [shiftDealsPhases, setShiftDealsPhases] = useState([]);

  const [permissionsIds, setPermissionsIds] = useState([]);
  const [selctedAllias, setSelctedAllias] = useState('');
  const [alliasType, setAlliasType] = useState('');
  const [showSearchBytoggle, setShowSearchBytoggle] = useState(false);

  const buttonRef = useRef(null);
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();

  // const handleAliasPermissions = (type, item) => {
  //   if (type === 'create') {
  //     if (!selectedAliasCreateIds.includes(item.id)) {
  //       setSelectedAliasCreateIds(previous => [...previous, item.id]);
  //     } else {
  //       setSelectedAliasCreateIds(previous =>
  //         previous.filter(MiniItem => MiniItem !== item.id),
  //       );
  //     }
  //   } else if (type === 'update') {
  //     if (!selectedAliasUpdateIds.includes(item.id)) {
  //       setSelectedAliasUpdateIds(previous => [...previous, item.id]);
  //     } else {
  //       setSelectedAliasUpdateIds(previous =>
  //         previous.filter(MiniItem => MiniItem !== item.id),
  //       );
  //     }
  //   } else if (type === 'read') {
  //     if (!selectedAliasReadIds.includes(item.id)) {
  //       setSelectedAliasReadIds(previous => [...previous, item.id]);
  //     } else {
  //       setSelectedAliasReadIds(previous =>
  //         previous.filter(MiniItem => MiniItem !== item.id),
  //       );
  //     }
  //   }
  // };

  const handleAliasPermissions = (type, item) => {
    if (type === 'create') {
      if (
        !selectedAliasCreateIds.includes(item.id) &&
        selectedAliasUpdateIds.includes(item.id) &&
        selectedAliasReadIds.includes(item.id)
      ) {
        setSelectedAliasCreateIds(previous => [...previous, item.id]);
      } else if (
        selectedAliasReadIds.includes(item.id) &&
        !selectedAliasCreateIds.includes(item.id)
      ) {
        setSelectedAliasCreateIds(previous => [...previous, item.id]);
        setSelectedAliasUpdateIds(previous => [...previous, item.id]);
      } else if (
        !selectedAliasCreateIds.includes(item.id) &&
        !selectedAliasUpdateIds.includes(item.id) &&
        !selectedAliasReadIds.includes(item.id)
      ) {
        setSelectedAliasCreateIds(previous => [...previous, item.id]);
        setSelectedAliasUpdateIds(previous => [...previous, item.id]);
        setSelectedAliasReadIds(previous => [...previous, item.id]);
      } else {
        setSelectedAliasCreateIds(previous =>
          previous.filter(MiniItem => MiniItem !== item.id),
        );
        setSelectedAliasUpdateIds(previous =>
          previous.filter(MiniItem => MiniItem !== item.id),
        );
        setSelectedAliasReadIds(previous =>
          previous.filter(MiniItem => MiniItem !== item.id),
        );
      }
    } else if (type === 'update') {
      if (selectedAliasCreateIds.includes(item.id)) {
        setSelectedAliasCreateIds(previous =>
          previous.filter(MiniItem => MiniItem !== item.id),
        );
        setSelectedAliasUpdateIds(previous =>
          previous.filter(MiniItem => MiniItem !== item.id),
        );
        setSelectedAliasReadIds(previous =>
          previous.filter(MiniItem => MiniItem !== item.id),
        );
      } else if (
        selectedAliasReadIds.includes(item.id) &&
        !selectedAliasUpdateIds.includes(item.id)
      ) {
        setSelectedAliasUpdateIds(previous => [...previous, item.id]);
      } else if (
        !selectedAliasUpdateIds.includes(item.id) &&
        !selectedAliasReadIds.includes(item.id)
      ) {
        setSelectedAliasUpdateIds(previous => [...previous, item.id]);
        setSelectedAliasReadIds(previous => [...previous, item.id]);
      } else {
        setSelectedAliasUpdateIds(previous =>
          previous.filter(MiniItem => MiniItem !== item.id),
        );
        setSelectedAliasReadIds(previous =>
          previous.filter(MiniItem => MiniItem !== item.id),
        );
      }
    } else if (type === 'read') {
      if (
        selectedAliasCreateIds.includes(item.id) &&
        selectedAliasUpdateIds.includes(item.id)
      ) {
        setSelectedAliasCreateIds(previous =>
          previous.filter(MiniItem => MiniItem !== item.id),
        );
        setSelectedAliasUpdateIds(previous =>
          previous.filter(MiniItem => MiniItem !== item.id),
        );
        setSelectedAliasReadIds(previous =>
          previous.filter(MiniItem => MiniItem !== item.id),
        );
      } else if (selectedAliasUpdateIds.includes(item.id)) {
        setSelectedAliasUpdateIds(previous =>
          previous.filter(MiniItem => MiniItem !== item.id),
        );
        setSelectedAliasReadIds(previous =>
          previous.filter(MiniItem => MiniItem !== item.id),
        );
      } else if (selectedAliasReadIds.includes(item.id)) {
        setSelectedAliasReadIds(previous =>
          previous.filter(MiniItem => MiniItem !== item.id),
        );
      } else {
        setSelectedAliasReadIds(previous => [...previous, item.id]);
      }
    }
  };

  const checkAllIdsIncluded = () => {
    // Get all the IDs from the states
    const allIds = [
      ...selectedAliasCreateIds,
      ...selectedAliasUpdateIds,
      ...selectedAliasReadIds,
    ];

    // Check if all IDs in selectedAliasTeamIds are included in allIds
    return selectedAliasTeamIds.every(id => allIds.includes(id));
  };


  const handle = e => {
    const form = e.currentTarget;
    const premesionExist = checkAllIdsIncluded();
    if (
      form.checkValidity() === false ||
      pipeLinePhases.length === 0 
      // || selectedAliasTeamIds.length === 0 ||
      // selectedAliasCustomerIds.length === 0 
      // || !premesionExist
    ) {
      e.preventDefault();
      e.stopPropagation();

      if (pipeLinePhases.length == 0) {
        setPhaseError(true);
      } 
      // else if (selectedAliasTeamIds.length === 0) {
      //   errorMessage('Please select one team');
      // } else if (selectedAliasCustomerIds.length === 0) {
      //   errorMessage('Please select one customer');
      // } else

      setValidated(true);
    } else {
      e.preventDefault();
      setPhaseError(false);

      if(selectedAliasTeamIds?.length > 0){
        if (!premesionExist) {
          errorMessage('Please select atleast one permission');
        }else{
          if (props.editPipeline) {
            UpdatePipeLine();
          } else {
            CreatePipeLine();
          }
        }
      }else{
        if (props.editPipeline) {
          UpdatePipeLine();
        } else {
          CreatePipeLine();
        }
      }

    }
  };

  const get_company_users_role = value => {
    commonApi
      .get_company_users_role()
      .then(res => {
        if (res.status === 200) {
          setAliasRoles(res.data);

          // data.filter(item => item.role_type_id === "1");
          // setAliasRoles(res.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const teamValues = item => {
    if (!selectedAliasTeamIds.includes(item.id)) {
      setSelectedAliasTeamIds(prev => [...prev, item.id]);
      // setSelectedAliasCreateIds(prev => [...prev, item.id]);
      // setSelectedAliasUpdateIds(prev => [...prev, item.id]);
      // setSelectedAliasReadIds(prev => [...prev, item.id]);
      setSelectedAliasTeamRoles(prev => [...prev, item]);
      

      setShowSearchRoles('');

      setAliasRoles(previous =>
        previous.filter(items => items.id !== item.id),
      );
    } else {
      errorMessage('You have already added this Alias');
    }
  };

  const customerValues = item => {
    if (!selectedAliasCustomerIds.includes(item.id)) {
      setSelectedAliasCustomerIds(prev => [...prev, item.id]);
      setSelectedAliasCustomerRoles(prev => [...prev, item]);

      setShowSearchRoles('');
      setAliasRoles(previous =>
        previous.filter(items => items.id !== item.id),
      );
    } else {
      errorMessage('You have already added this Alias');
    }
  };

  const removeAliasRole = (deleteItem, type) => {
    if (type === 'team') {
      // setAliasRoles(prev => [...prev, deleteItem]);

      setSelectedAliasTeamIds(previous =>
        previous.filter(item => item !== deleteItem.id),
      );
      setSelectedAliasTeamRoles(previous =>
        previous.filter(item => item.id !== deleteItem.id),
      );
    } else {
      // setAliasRoles(prev => [...prev, deleteItem])

      setSelectedAliasCustomerIds(previous =>
        previous.filter(item => item !== deleteItem.id),
      );
      setSelectedAliasCustomerRoles(previous =>
        previous.filter(item => item.id !== deleteItem.id),
      );
    }

    setSelectedAliasCreateIds(previous =>
      previous.filter(MiniItem => MiniItem !== deleteItem.id),
    );
    setSelectedAliasUpdateIds(previous =>
      previous.filter(MiniItem => MiniItem !== deleteItem.id),
    );
    setSelectedAliasReadIds(previous =>
      previous.filter(MiniItem => MiniItem !== deleteItem.id),
    );
    setShowDeleteModeUsers('');
  };

  // const removeCustomerAlias = deleteItem => {
  //   setSelectedAliasCustomerIds(previous =>
  //     previous.filter(item => item !== deleteItem.id),
  //   );
  //   setSelectedAliasCustomerRoles(previous =>
  //     previous.filter(item => item.id !== deleteItem.id),
  //   );

  // };

  const CreatePipeLine = () => {
    const phases = pipeLinePhases.map(phase => ({phase}));

    var payload = {
      // pipeline_id: '' edit case,

      title: pipeLineName,
      description: description,
      associate_role_ids: selectedAliasUpdateIds.toString(','),
      role_ids: selectedAliasCreateIds.toString(','),
      read_role_ids: selectedAliasReadIds.toString(','),
      customer_role_ids: selectedAliasCustomerIds.toString(','),
      pipeline_phases: phases,
    };

    console.log('payload', payload);

    commonApi
      .create_pipeline(payload)
      .then(res => {
        props.onClose();

        dispatch(getPipelineData());

        successMessage(res.message);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  function filterItemsNotInFirstArray(firstArray, secondArray) {
    return secondArray.filter(item => {
      return !firstArray.some(
        secItem =>
          secItem.id === item.id && secItem.pipeline_id === item.pipeline_id,
      );
    });
  }

  function filterShiftPhasesItems(idsToFilter, dataArray) {
    return dataArray.filter(item => idsToFilter.includes(item.id));
  }

  const UpdatePipeLine = () => {
    const phases = newPhases.map(phase => ({phase}));

    const filteredItems = filterItemsNotInFirstArray(
      originalPhases,
      originalPhases3,
    );

    const shiftdata = filterShiftPhasesItems(shiftPhaseIdList, originalPhases);
    // console.log(filteredItems, 'filteredItems');

    console.log(shiftdata, 'filteredItems');
    var payload = {
      // pipeline_id: '' edit case,
      pipeline_id: pipeLineId,
      title: pipeLineName,
      description: description,
      associate_role_ids: selectedAliasUpdateIds.toString(','),
      role_ids: selectedAliasCreateIds.toString(','),
      read_role_ids: selectedAliasReadIds.toString(','),
      customer_role_ids: selectedAliasCustomerIds.toString(','),
      new_added_phases: phases,
      deleted_phase: filteredItems,
      add_deals_in_phase: shiftdata,
    };

    // console.log('payload', payload);

    commonApi
      .create_pipeline(payload)
      .then(res => {
        if (props.editPipeline) {
          dispatch(getUpdateDataOfPipeline(true));
        }

        dispatch(getPipelineData());
        props.onClose();
        successMessage(res.message);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handlePhase = phase => {
    if (!pipeLinePhases.includes(phase.trim())) {
      setPipeLinePhases(prev => [...prev, phase]);
      setPhaseName('');
    } else {
      errorMessage('You have Already added this Phase');
    }
  };

  const handlePhaseEditCase = phase => {
    if (!pipeLinePhases.includes(phase.trim())) {
      setPipeLinePhases(prev => [...prev, phase]);
      setOriginalPhases(prev => [...prev, {id: phase, phase}]);

      setNewPhases(prev => [...prev, phase]);

      setPhaseName('');
    } else {
      errorMessage('You have Already added this Phase');
    }
  };

  const removePhase = phase => {
    setPipeLinePhases(previous => previous.filter(item => item !== phase));
    setShowDeleteModel('');
  };

  const removePhaseEditCase = (type, phaseItem) => {
    if (type === 'notDeal') {
      setOriginalPhases(previous =>
        previous.filter(item => item.phase !== phaseItem),
      );
      setShowDeleteModel('');
    } else {
      // originalPhases.forEach(phase => {
      //   if (phase.id === shiftPhaseId) {
      //     phase.deals.push(...phaseItem.deals);
      //   }
      // });

      const index = originalPhases.findIndex(
        phase => phase.id === shiftPhaseId,
      );

      if (index !== -1) {
        originalPhases[index].deals.push(...phaseItem.deals);
      }

      setShiftPhaseIdList(prev => [...prev, shiftPhaseId]);

      setOriginalPhases(previous =>
        previous.filter(item => item.phase !== phaseItem.phase),
      );
      setOriginalPhases2(previous =>
        previous.filter(item => item.phase !== phaseItem.phase),
      );

      setNewPhases(previous =>
        previous.filter(item => item !== phaseItem.phase),
      );

      setOriginalPhaseItem({});
    }
  };

  useEffect(() => {
    if (props.editPipeline) {
      setPipeLineId(props.editPipeline.id);
      setPipeLineName(props.editPipeline.title);
      setDescription(props.editPipeline.description);
      setPipeLinePhases(props.editPipeline.phases.map(({phase}) => phase));
      setOriginalPhases(props.editPipeline.phases);
      setOriginalPhases2(props.editPipeline.phases);
      setOriginalPhases3(props.editPipeline.phases);
      getCompanyUserRoles();
    }
  }, [props.editPipeline]);
  useEffect(() => {
    get_company_users_role();
  }, []);
  useEffect(() => {
    setShowSearchBytoggle(false);
  }, [subTabType]);

  const getCompanyUserRoles = () => {
    commonApi
      .get_company_users_role()
      .then(res => {
        if (res.status === 200) {
          const createIds = props.editPipeline.role_ids.split(',');
          const updateIds = props.editPipeline.associate_role_ids.split(',');
          const readIds = props.editPipeline.read_role_ids.split(',');

          const allIds = [...createIds, ...updateIds, ...readIds];

          const uniqueIds = [...new Set(allIds)];

          setSelectedAliasTeamIds(uniqueIds);
          setSelectedAliasTeamRoles(
            res.data.filter(item => uniqueIds.includes(item.id)),
          );

          const customerIDs = props.editPipeline.customer_role_ids.split(',');

          console.log(customerIDs, 'customerIDscustomerIDs');
          setSelectedAliasCustomerIds(customerIDs);
          setSelectedAliasCustomerRoles(
            res.data.filter(item => customerIDs.includes(item.id)),
          );

          setSelectedAliasCreateIds(createIds);
          setSelectedAliasUpdateIds(updateIds);
          setSelectedAliasReadIds(readIds);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // console.log(selectedAliasCreateIds,'selectedAliasCreateIds')

  // console.log(pipeLinePhases, 'pipeLinePhasespipeLinePhases');

  const showDeleteModelUser = (item, type) => {
    setAliasRoles(prev => [...prev, item]);

    setAlliasType(type);
    setSelctedAllias(item);

    setShowDeleteModeUsers('deleteUsers');
  };

  const handleInputChange = event => {
    const value = event.target.value;
    setPhaseName(event.target.value); // Use trimEnd to remove trailing spaces
  };

  const handleBlur = () => {
    // setPhaseName(phaseName.trim()); // Ensure all spaces are trimmed on blur
  };

  const renderHeader = () => {
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };

  function searchByRoleAlias(dataArray, searchQuery, tabType) {
    // Define the role_type_id based on tabType
    const roleTypeId = tabType === 'customer' ? '1' : null; // null indicates all types except 1

    return dataArray.filter(item => {
      // Check if the item matches the role type condition
      const isRoleTypeMatch =
        roleTypeId === null
          ? item.role_type_id !== '1' // If it's not Customer, exclude role_type_id '1'
          : item.role_type_id === roleTypeId; // Otherwise, include only '1' for Customer

      // If there is a search query, also filter by role_alias
      const isSearchMatch =
        !searchQuery ||
        item.role_alias.toLowerCase().includes(searchQuery.toLowerCase());

      // Return items that match both the role type and search query (if provided)
      return isRoleTypeMatch && isSearchMatch;
    });
  }

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}
        // closeIconRight={props.closeIconRight ? props.closeIconRight : false}
        renderData={renderHeader}
        showBlackHeader={true}
        headerPadding={'24px 20px'}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}>
        <div className="serach-filter-wrapper">
          {/* <div className="ticket-icons-options">
            <ul>
              <li
                className={'active'}
                onClick={() => {
                  setTopIcons({
                    ...topIcons,
                    ['personal']: !topIcons.personal,
                  });
                  setShowComponent('persnal-model');
                }}>
                <PersnalSvg fill={'white'} />
              </li>
            </ul>
          </div> */}
          {/* bs */}

          <div className="d-flex align-items-center fixed-width-inputs black-head-form-pad-left fix-width-forms justify-content-center  w-100">
            <div className="create-ticket-tabs">
              <CustomTabs
                darkmode
                active={tabType}
                onSelect={item => {
                  setShowSearchRoles('');
                  setTabType(item);
                }}
                tabs={[
                  labels.sessions_type_general[selected_lang],
                  labels.pipeline_roles[selected_lang],
                ]}
              />
            </div>
          </div>
          <Scrollbars className="custom-scroll" autoHide>
            <div className="fix-width-forms fixed-width-inputs black-head-form-pad-left">
              <div className="messages-users-list">
                <div className="create-ticket-form mb-4 ">
                  <Form noValidate validated={validated} onSubmit={handle}>
                    {tabType ===
                      labels.sessions_type_general[selected_lang] && (
                      <div style={{marginBottom: '72px'}}>
                        <div className="fieldset">
                          <Form.Label controlid="validationCustom01">
                            Pipeline Name
                          </Form.Label>
                          <Form.Control
                            name="title"
                            value={pipeLineName}
                            pattern="^\S.*$"
                            required
                            placeholder="Add pipeline name"
                            onChange={e => {
                              setPipeLineName(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Pipeline name is required.
                          </Form.Control.Feedback>
                        </div>
                        <div className="fieldset">
                          <Form.Label controlid="validationCustom01">
                            Description (optional)
                          </Form.Label>
                          <Form.Control
                            // required
                            value={description}
                            as="textarea"
                            rows={6}
                            type="text"
                            placeholder="Let others know what to expect"
                            onChange={e => {
                              setDescription(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Description is required.
                          </Form.Control.Feedback>
                        </div>
                        <div className="d-flex">
                          <div
                            className="fieldset flex-1"
                            style={{margin: '0px'}}>
                            <Form.Label controlid="validationCustom01">
                              Phases
                            </Form.Label>
                            <div className="input-plus-icon-holder">
                              <Form.Control
                                name="phase"
                                value={phaseName}
                                pattern="^\S.*$"
                                placeholder="Add phase"
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                              />
                              <span
                                className={'icon search-tabs-icons as-link'}
                                style={{marginRight: '2px'}}>
                                <i
                                  className="icon-plus"
                                  onClick={() => {
                                    if (
                                      phaseName !== '' &&
                                      !props.editPipeline
                                    ) {
                                      handlePhase(phaseName);
                                    } else if (
                                      phaseName !== '' &&
                                      props.editPipeline
                                    ) {
                                      handlePhaseEditCase(phaseName);
                                    } else {
                                      errorMessage('Please add phase first');
                                    }
                                  }}
                                />
                              </span>
                            </div>
                            <Form.Control.Feedback type="invalid">
                              Pipeline name is required.
                            </Form.Control.Feedback>
                          </div>
                          {/* <div className="search-tabs-icons">
                          
                          </div> */}
                        </div>

                        {pipeLinePhases.length == 0 && phaseError === true && (
                          <div className="text-danger textforerror">
                            {pipeLinePhases.length > 0
                              ? 'At least 3 Phases are required'
                              : 'Phases are required'}
                          </div>
                        )}

                        {Object.entries(pipeLinePhases).length > 0 &&
                          !props.editPipeline &&
                          pipeLinePhases.map((item, index) => (
                            <div
                              class="d-flex align-items-center phases_item mt-3"
                              key={index}>
                              <div className="pipeline_phase flex-1">
                                <div className="alias_name">{item}</div>
                              </div>

                              <span
                                class="as-link  cross-icon-field"
                                onClick={() => {
                                  setShowDeleteModel(item);

                                  // removePhase(item);
                                }}>
                                <i
                                  class="fas fa-times text-secondary"
                                  style={{fontSize: '1.5rem'}}></i>
                              </span>
                            </div>
                          ))}

                        {Object.entries(originalPhases).length > 0 &&
                          props.editPipeline &&
                          originalPhases.map((item, index) => (
                            <div
                              class="d-flex align-items-center mt-3"
                              key={index}>
                              <div className="pipeline_phase flex-1">
                                <div className="alias_name">{item.phase}</div>
                              </div>

                              <span
                                class="as-link ms-2"
                                onClick={() => {
                                  const result = checkPhases(originalPhases);
                                  console.log(result, 'result');

                                  if (
                                    item.deals &&
                                    item.deals.length > 0 &&
                                    result &&
                                    item.pipeline_id
                                  ) {
                                    setPhaseValue(item.phase);
                                    setOriginalPhaseItem(item);
                                  } else if (!result && item.pipeline_id) {
                                    errorMessage(
                                      'You cannot delete this Phase',
                                    );
                                  } else {
                                    setShowDeleteModel(item.phase);
                                  }

                                  // removePhase(item);
                                }}>
                                <i
                                  class="fas fa-times text-secondary"
                                  style={{fontSize: '1.5rem'}}></i>
                              </span>
                            </div>
                          ))}

                        <Button
                          className="btn-dark"
                          type="submit"
                          style={{display: 'none'}}
                          ref={buttonRef}>
                          {props.editAction ? 'Update Task' : 'Create Task'}
                        </Button>
                      </div>
                    )}
                    {tabType === labels.pipeline_roles[selected_lang] && (
                      <>
                        <div className="event-tabs tabs-style-2 mb-0">
                          <ul>
                            <li>
                              <Button
                                className={
                                  subTabType === 'team' ? 'active' : ''
                                }
                                onClick={() => {
                                  if (subTabType !== 'team') {
                                    // setAliasRoles([]);
                                    setSubTabsType('team');
                                    setShowSearchRoles('');
                                  }
                                }}>
                                Team
                              </Button>
                            </li>

                            <li>
                              <Button
                                className={
                                  subTabType === 'customer' ? 'active' : ''
                                }
                                onClick={() => {
                                  if (subTabType !== 'customer') {
                                    // setAliasRoles([]);   //due to issue of reset state
                                    setSubTabsType('customer');
                                    setShowSearchRoles('');
                                  }
                                }}>
                                Customer
                              </Button>
                            </li>
                          </ul>
                        </div>
                        <div className="invite-page-search">
                          <div className="search-form mt-4">
                            <Form.Label
                              controlid="validationCustom01"
                              // style={{
                              //   fontSize: '15px',
                              //   color: '#000000',
                              //   fontWeight: 500,
                              // }}
                            >
                              Roles
                            </Form.Label>
                            <Form.Group className="search-input search-pipeline-input position-relative">
                              <Form.Control
                                type="text"
                                value={showSearchRoles}
                                onChange={e => {
                                  setShowSearchRoles(e.target.value);
                                  // if (  subTabType === 'team') {
                                  //   searchTeam(e.target.value);
                                  // } else {
                                  //   searchCustomer(e.target.value)
                                  // }
                                }}
                                placeholder={'Type Role'}
                              />
                              <i
                                className={`fa-solid fa-chevron-${
                                  showSearchBytoggle ? 'up' : 'down'
                                }`}
                                onClick={() => {
                                  setShowSearchBytoggle(!showSearchBytoggle);
                                }}></i>
                            </Form.Group>
                          </div>
                        </div>

                        {(showSearchRoles || showSearchBytoggle) && (
                          <ul className="custom-suggestions invoices_suggestion">
                            {searchByRoleAlias(
                              aliasRoles,
                              showSearchRoles,
                              subTabType,
                            ).length > 0 ? (
                              searchByRoleAlias(
                                aliasRoles,
                                showSearchRoles,
                                subTabType,
                              ).map(suggestion => (
                                <li
                                  className="as-link"
                                  key={`suggestion${suggestion.id}`}
                                  onClick={() =>
                                    subTabType === 'team'
                                      ? teamValues(suggestion)
                                      : customerValues(suggestion)
                                  }>
                                  {capitalizeFirst(suggestion.role_alias)}
                                </li>
                              ))
                            ) : (
                              <li>No {subTabType} found</li>
                            )}
                          </ul>
                        )}

                        {subTabType === 'team' && (
                          <>
                            {Object.entries(selectedAliasTeamRoles).length >
                            0 ? (
                              selectedAliasTeamRoles.map((item, index) => (
                                <div
                                  class="d-flex align-items-center mt-3"
                                  key={index}>
                                  <div className="pipeline_alias_role">
                                    <div className="alias_name">
                                      {capitalizeFirst(item.role_alias)}
                                    </div>

                                    <div className="alias_permission_flex">
                                      <Form.Group className="fieldset payment-checkbox m-0">
                                        <Form.Check
                                          type={'checkbox'}
                                          label={'Read'}
                                          id={`Read_${index}`}
                                          // value={'read'}
                                          checked={selectedAliasReadIds.includes(
                                            item.id,
                                          )}
                                          onChange={() => {
                                            handleAliasPermissions(
                                              'read',
                                              item,
                                            );
                                          }}
                                        />
                                      </Form.Group>

                                      <Form.Group className="fieldset payment-checkbox m-0">
                                        <Form.Check
                                          type={'checkbox'}
                                          label={'Update'}
                                          id={`Update_${index}`}
                                          // value={'update'}
                                          checked={selectedAliasUpdateIds.includes(
                                            item.id,
                                          )}
                                          onChange={() => {
                                            handleAliasPermissions(
                                              'update',
                                              item,
                                            );
                                          }}
                                        />
                                      </Form.Group>

                                      <Form.Group className=" form-horizontal fieldset payment-checkbox m-0">
                                        <Form.Check
                                          type={'checkbox'}
                                          label={'Create'}
                                          id={`Create_${index}`}
                                          // value={'create'}
                                          checked={selectedAliasCreateIds.includes(
                                            item.id,
                                          )}
                                          onChange={() => {
                                            handleAliasPermissions(
                                              'create',
                                              item,
                                            );
                                          }}
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>

                                  <span
                                    class="as-link ms-2"
                                    onClick={() => {
                                      showDeleteModelUser(item, 'team');
                                    }}>
                                    <i
                                      class="fas fa-times text-secondary"
                                      style={{fontSize: '1.5rem'}}></i>
                                  </span>
                                </div>
                              ))
                            ) : (
                              <></>
                              // <> No Permissions Found </>
                            )}
                          </>
                        )}

                        {subTabType === 'customer' && (
                          <>
                            {Object.entries(selectedAliasCustomerRoles).length >
                            0 ? (
                              selectedAliasCustomerRoles.map((item, index) => (
                                <div
                                  class="d-flex align-items-center mt-3"
                                  key={index}>
                                  <div className="pipeline_customer_alias">
                                    <div className="alias_name">
                                      {capitalizeFirst(item.role_alias)}
                                    </div>
                                  </div>

                                  <span
                                    class="as-link ms-2"
                                    onClick={() => {
                                      showDeleteModelUser(item, 'customer');
                                    }}>
                                    <i
                                      class="fas fa-times text-secondary"
                                      style={{fontSize: '1.5rem'}}></i>
                                  </span>
                                </div>
                              ))
                            ) : (
                              // <> No Permissions Found </>
                              <></>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </Scrollbars>
          {tabType === labels.sessions_type_general[selected_lang] && (
            <div className="filter-btn-wrapper  mt-2">
              <div
                className="invite-btn text-center fixed-width-inputs black-head-form-pad-left fix-width-forms bg_transparent pb-2 pt-2"
                style={{bottom: 0}}>
                <Button
                  className="btn-dark mb-0"
                  type="submit"
                  onClick={() => {
                    buttonRef.current.click();
                  }}>
                  {/* {props.editAction ? 'Update Task' : } */}
                  {props.editPipeline ? 'Update Pipeline' : 'Create Pipeline'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </SlidingPanel>

      {showDeleteModel && !props.editPipeline && (
        <ConfirmationModel
          closeModel={() => {
            setShowDeleteModel('');
            setOriginalPhaseItem({});
          }}
          discription={'Are you sure you want to delete this phase ?'}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            removePhase(showDeleteModel);
          }}
        />
      )}
      {showDeleteModeUsers === 'deleteUsers' && (
        <ConfirmationModel
          closeModel={() => {
            setShowDeleteModeUsers('');
          }}
          discription={
            alliasType === 'team'
              ? 'Are you sure you want to delete this Role?'
              : 'Are you sure you want to delete this Customer?'
          }
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            removeAliasRole(selctedAllias, alliasType);
          }}
        />
      )}

      {showDeleteModel &&
        props.editPipeline &&
        Object.keys(orginalPhaseItem).length === 0 && (
          <ConfirmationModel
            closeModel={() => {
              setShowDeleteModel('');
              setOriginalPhaseItem({});
            }}
            discription={'Are you sure you want to delete this phase ?'}
            buttonOne={'Yes'}
            buttonTwo={'No Thanks'}
            ButtonOneAction={() => {
              removePhaseEditCase('notDeal', showDeleteModel);
            }}
          />
        )}

      {Object.keys(orginalPhaseItem).length > 0 && phaseValue && (
        <ConfirmationModel
          closeModel={() => {
            setShowDeleteModel('');
            setOriginalPhaseItem({});
            setShiftPhaseId('');
          }}
          dropdownMenu={originalPhases2.filter(
            item => item.phase !== phaseValue,
          )}
          setShiftPhaseId={setShiftPhaseId}
          discription={'Are you sure you want to delete this phase?'}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            removePhaseEditCase('deal', orginalPhaseItem);
          }}
        />
      )}
    </>
  );
};
export default CreatePipeLine;

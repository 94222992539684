import React from 'react';
import {Button, Form, Row, Dropdown} from 'react-bootstrap';
import Sidebar from '../../Pages/Sidebar';

import {useState, useEffect} from 'react';
import {project_management, rewardStar} from '../../Constants/Images';

import moment from 'moment';

import {CustomModal, Pannels} from '../../Components';
import Scrollbars from 'react-custom-scrollbars';

import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import {FireIcon, MachineIcon, layers} from '../../Constants/Images';

import ProgressBar from 'react-bootstrap/ProgressBar';
import Slider from 'react-slick';
import CreateProject from '../../Components/Project/CreateProject';
import commonApi from '../../Services/CommonService';
import NorecordFound from '../../Components/NoRecord/NorecordFound';
import {NoProjectsFound} from '../../Utils/NoRecordConstant';
import EditProject from '../../Components/Project/EditProject';
import {
  calculateMilestoneProgress,
  checkPermissions,
  fixName,
  truncateTitle,
} from '../../Utils/commonUtils';
import CompanyFilterModal from '../../Components/Modals/CompanyFilterModal';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import MultiSelectionDropdown from '../../Components/MultiSelectionDropdown';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import NewMultiDropdown from '../../Components/NewMultiDropdown';
import DateGraph from './DateGraph';
import {errorMessage} from '../../Utils/ToastMessages';
import Calender from '../../Components/FeedBack/Calender';
import {useSelector} from 'react-redux';
import ActionDetail from '../../Components/Actions/ActionDetail';
import CreateAction from '../../Components/Snapshot/CreateAction';
const Projects = props => {
  const [type, setType] = useState('');
  const [selectMileStoneId, setSelectMileStoneId] = useState([]);
  const [showComponent, setShowComponent] = useState('');
  const [secondaryComponent, setSecondaryComponent] = useState('');
  const [newTaskData, setNewTaskData] = useState({});
  console.log(newTaskData, 'newTaskDatanewTaskData')

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [currentComponent, setCurrentComponent] = useState('');
  const [tabType, setTabType] = useState('list');

  const [workspaceData, setWorkspaceData] = useState([]);
  const [threeDots, setThreeDots] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [showSelectedEvent, setShowSelectedEvent] = useState(false);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const [projectListing, setProjectListing] = useState([]);
  const [projectListingBoard, setProjectListingBoard] = useState([]);
  const [projectListingBoardData, setProjectListingBoardData] = useState([]);
  const [tasksData, setTasksData] = useState([]);
  const [actions, setActions] = useState([]);
  console.log(tasksData, '=======>>>>>>tasksDatatasksDatatasksData');

  const [companies, setCompanies] = useState([]);
  const [companyIds, setCompanyIds] = useState([]);
  const [searchQry, setSearchQry] = useState('');

  const [selected, setSelected] = useState('');
  const [selectedProjectData, setSelectedProjectData] = useState(null);
  const [isCheckAll, setIsCheckAll] = useState(true);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [projectType, setProjectType] = useState('');
  const [showLoader, setShowLoader] = useState(true);

  const today = moment().format('YYYY-MM-DD');
  const thirtyOneDaysLater = moment().add(31, 'days').format('YYYY-MM-DD');

  const [filterFrom, setFilterFrom] = useState(today); // Start date initialized to today
  const [filterTo, setFilterTo] = useState(thirtyOneDaysLater);
  const [fromCalendar, setFromCalendar] = useState(false);
  const [toCalendar, setToCalendar] = useState(false);
  const [showBoardList, setShowBoardList] = useState('');
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [scrollLock, setScrollLock] = useState(false); // To prevent scroll trigger on tab switch

  const menuPermesions = useSelector(state => state.menuPermesionList);

  const events = [
    {
      id: '1',
      start: '2023-12-7 00:00:00',
      end: '2023-12-14 02:00:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },
    {
      id: '2',
      start: '2023-12-14 00:00:00',
      end: '2023-12-21 02:00:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },
    {
      id: '3',
      start: '2023-12-21 00:00:00',
      end: '2023-12-28 02:00:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },
    {
      id: '4',
      start: '2023-12-01 00:00:00',
      end: '2023-12-07 02:00:00',
      title: 'Samantha Ellington',
      summary: 'Service',
      location: 'McLean, VA',
    },
  ];

  useEffect(() => {
    get_projects(1);
    setScrollLock(false);
    // get_projects_for_board();
  }, [searchQry, companyIds]);
  useEffect(() => {
    get_projects_name_for_board();
  }, [companyIds, showComponent]);
  useEffect(() => {
    get_projects_for_board();
  }, [type, searchQry, showBoardList]);

  useEffect(() => {
    setShowComponent('');
  }, [tabType]);

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  const get_projects = page => {
    const payload = {
      page: page ? page : 1,
      search_query: searchQry,
      company_ids: companyIds,
    };

    commonApi
      .get_projects(payload)
      .then(res => {
        if (res.status === 200) {
          // setProjectListing(res?.projects);
          setShowLoader(false);
          const fetchedData = res?.projects || [];

          // Handling scenarios based on filtered data
          if (fetchedData.length > 0) {
            if (page > 1) {
              // Case: length > 0 and page > 1 (Append to array)
              if (fetchedData.length > 0) {
                setProjectListing(prevData => [...prevData, ...fetchedData]);
                setPageNumber(page); // Increment page for next fetch
              }
            } else if (page === 1) {
              // Case: length > 0 and page === 1 (Replace array)
              setProjectListing(fetchedData);
              setPageNumber(page); // Reset page number for next page fetch
            }
          } else if (fetchedData.length === 0 && page === 1) {
            // Case: length === 0 and page === 1 (Clear the array)
            setProjectListing([]);
          } else if (fetchedData.length === 0 && page > 1) {
            setScrollLock(true);
            // Case: length === 0 and page > 1 (No action needed for pagination)
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (tabType === 'board') {
      setThreeDots(true);
    } else {
      setThreeDots(false);
    }
    setSearchQry('');
  }, [tabType]);

  const NoRecord = [
    {
      id: 'No milestone available',
      name: 'No milestone available',
    },
  ];

  // useEffect(()=>{
  //   if(projectListingBoardData){
  //   setTasksData(projectListingBoardData?.tasks.length > 0 ? projectListingBoardData?.tasks : [])
  //   }
  // },[projectListingBoardData])

  const get_projects_for_board = () => {
    const payload = {
      // page: 1,
      search_query: searchQry,
      project_id: type,
      // company_ids: companyIds,
    };

    commonApi
      .get_projects_for_board(payload)
      .then(res => {
        if (res.status === 200) {
          setProjectListingBoardData(res?.project);
          setTasksData(res?.project?.tasks);
          if (res?.project.milestones.length > 0) {
          }

          // setShowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const get_projects_name_for_board = () => {
    const payload = {
      company_ids: companyIds,
    };

    commonApi
      .get_projects_name_for_board(payload)
      .then(res => {
        if (res.status === 200 && res.projects.length > 0) {
          setProjectListingBoard(res?.projects);
          setType(res?.projects[0].id);
          // setSelectMileStoneId([])
          // setShowLoader(false);
        } else {
          setProjectListingBoard([]);
          // setSelectMileStoneId([])
          setType(NoRecord[0].id);
          // setShowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const dropdownMenu = [
    {id: '1', name: 'Project'},
    {
      id: '2',
      name: 'Project2',
    },
    {
      id: '3',
      name: 'Projec3',
    },
    {
      id: '4',
      name: 'Project4',
    },
    {
      id: '5',
      name: 'Project5',
    },
  ];

  // const generateDateSlots = () => {
  //   const startDate = new Date('2023-07-01'); // Start date
  //   const endDate = new Date('2024-01-01');
  //   const dateSlots = [];
  //   const currentDate = new Date(startDate);

  //   while (currentDate <= endDate) {
  //     const formattedDate = new Intl.DateTimeFormat('en-US', {
  //       day: 'numeric',
  //       month: 'short',
  //     }).format(currentDate);

  //     dateSlots.push(
  //       <div className="tasks-listing">
  //         <div className="date">{formattedDate}</div>
  //         <div className="activity-line"></div>
  //       </div>,
  //     );

  //     // Move to the next week
  //     currentDate.setDate(currentDate.getDate() + 7);
  //   }

  //   return dateSlots;
  // };
  const calculateTotalDays = (startDate, endDate) => {
    // Check if the end date is after the start date
    if (endDate < startDate) {
      return 'End date must be after start date';
    }

    // Calculate the total number of milliseconds between the two dates
    const totalMilliseconds = endDate - startDate;

    // Convert milliseconds to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    const totalDays = Math.floor(totalMilliseconds / (1000 * 60 * 60 * 24));

    return totalDays; // Return the total number of days
  };

  const rewardSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "20px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };
  const rewardSliderData = [
    {
      name: 'On-Time',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Reviews',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },
  ];

  const handleProjects = (item, type) => {
    if (type === 'add') {
      setProjectType(type);
      setSelectedProjectData(item);
      setProjectListing(prevProjects => [item, ...prevProjects]);
      setProjectListingBoardData(item);
    }
    if (type === 'edit') {
      setSelectedProjectData(item);
      setProjectListingBoardData(item);

      setProjectListing(prevProjects => {
        const projectIndex = prevProjects.findIndex(
          project => project.id === item.id,
        );

        if (projectIndex !== -1) {
          // If an item with the same id exists, replace it
          const updatedProjects = [...prevProjects];
          updatedProjects[projectIndex] = item;
          return updatedProjects;
        } else {
          // If no matching id, add the new item
          return [item, ...prevProjects];
        }
      });
    }
  };

  const handleCheckDate = () => {
    const validRange = moment(filterFrom).isSameOrBefore(filterTo);

    if (
      (filterTo !== '' && filterFrom !== '' && validRange) ||
      (filterTo === '' && filterFrom === '')
    ) {
      // Both dates are valid and in range OR both are empty -> Call the API
      setShowComponent('');
    } else if (filterTo !== '' && filterFrom !== '' && validRange === false) {
      // Both dates are present but not valid -> Show error message
      errorMessage('Please select a valid range');
    } else if (filterTo === '' || filterFrom === '') {
      // One of the dates is missing -> Show error message
      errorMessage('Please select both dates');
    }
  };

  let debounceTimer = null;

  const handleScroll = e => {
    if (scrollLock) return;

    const target = e.target;

    const tolerance = 5;
    const bottom =
      target.scrollHeight - target.scrollTop <= target.clientHeight + tolerance;

    if (bottom) {
      clearTimeout(debounceTimer);

      debounceTimer = setTimeout(() => {
        setPageNumber(prevPage => prevPage + 1);
        get_projects(pageNumber + 1);
      }, 300);
    }
  };

  console.log(selectMileStoneId, '==============>>>>>>>>>>>>selectMileStoneId');
  console.log(
    projectListingBoardData,
    '=============>>>>>>>>>>>>projectListingBoardData',
  );
  const ProjectPermessions =
    menuPermesions && checkPermissions(menuPermesions, 'Project Management');

  const handleTask = () => {
    setSecondaryComponent('create_Task');
  };

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          setCompanies={setCompanies}
          workspaceData={workspaceData}
          showComponent={showComponent}
        />
        {/* {showComponent === 'Event' && <SessionEvents eventButton={eventButton} />} */}

        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            {!showBoardList &&
              ProjectPermessions &&
              (ProjectPermessions === 'allow' ||
                ProjectPermessions?.analytics_row === true) && (
                <>
                  <div className="Reward_main analytics_slider_bg">
                    <span
                      className="analytics_slider_heading "
                      style={{color: analycticsSlider ? '' : '#999999'}}
                      onClick={() => {
                        setAnalycticsSlider(!analycticsSlider);
                      }}>
                      Analytics Row
                    </span>

                    {analycticsSlider && (
                      <div className="reward-top mt-3 mb-2">
                        <Slider {...rewardSlider}>
                          {rewardSliderData.length > 0 &&
                            rewardSliderData.map((item, index) => (
                              <div className="reward-main">
                                <div className="reward-card">
                                  <span
                                    style={{fontWeight: 700, fontSize: '13px'}}>
                                    {item.name}
                                  </span>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}>
                                    <span style={{fontSize: '17px'}}>
                                      {item.money}
                                    </span>
                                    {item.name === 'Ratings' && (
                                      <img src={item.svgIcon} alt="" />
                                    )}
                                    {item.name === 'Conversion' ? (
                                      <i
                                        className="fa-solid fa-arrow-down"
                                        style={{color: '#BD4141'}}></i>
                                    ) : (
                                      <i
                                        class="fa-solid fa-arrow-up"
                                        style={{color: '#52bd41'}}></i>
                                    )}
                                  </span>
                                  <div class="message-date-info">
                                    <span
                                      style={{color: '#333'}}
                                      class="message-date">
                                      {item.time}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Slider>
                      </div>
                    )}
                  </div>

                  <div className="add_border"></div>
                </>
              )}
            {tabType === 'list' && (
              <Pannels
                isFloated={isFloated}
                toggleFloatedPanel={toggleFloatedPanel}
                showHiddenPanel={showHiddenPanel}
                updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
                primary={
                  <>
                    <div className=" primary_panel_content">
                      <div className="messages-header">
                        <div className="event-tabs tabs-style-2 mb-0">
                          {' '}
                          {/* Merging */}
                          <ul className="LabelTabs">
                            <li className="ml-20 d-flex align-items-center">
                              <Button
                                className={tabType === 'list' ? 'active' : ''}
                                onClick={() => {
                                  setTabType('list');
                                }}>
                                List
                              </Button>
                            </li>

                            <li className="ml-20 d-flex align-items-center">
                              <Button
                                className={tabType === 'board' ? 'active' : ''}
                                onClick={() => {
                                  setTabType('board');
                                }}>
                                Board
                              </Button>
                            </li>
                          </ul>
                        </div>

                        <div className="search-tabs-icons">
                          {ProjectPermessions &&
                            (ProjectPermessions === 'allow' ||
                              ProjectPermessions?.create === true) && (
                              <span
                                className={
                                  showComponent === 'createProject'
                                    ? 'icon active'
                                    : 'icon'
                                }>
                                <i
                                  className="icon-plus"
                                  onClick={() => {
                                    if (showComponent === 'createProject') {
                                      setShowComponent('');
                                    } else {
                                      setShowComponent('createProject');
                                    }
                                  }}
                                />
                              </span>
                            )}

                          <span
                            onClick={() => {
                              setThreeDots(!threeDots);
                            }}
                            className={threeDots ? 'icon active' : 'icon'}>
                            <i className="fas fa-ellipsis-vertical" />
                          </span>
                        </div>
                      </div>
                      {threeDots && (
                        <div className="person-search-wrapper mb-0 messages-users-wrapper">
                          <div className="search-form d-flex">
                            <Form.Group className="search-input">
                              <Form.Control
                                type="text"
                                placeholder="Who are you looking for?"
                                value={searchQry}
                                onChange={e => {
                                  setSearchQry(e.target.value);
                                }}
                              />
                            </Form.Group>

                            <Button>
                              <i
                                className="icon-filter-icon"
                                onClick={() => setShowComponent('filterModal')}
                              />
                            </Button>
                          </div>
                        </div>
                      )}
                      <div className="refferal-list-wrapper">
                        {showLoader && <LoaderSpinner />}
                        <Scrollbars
                          className="custom-refferal-vertical-scrollbar"
                          autoHide
                          onScroll={handleScroll}
                          renderTrackHorizontal={props => (
                            <div {...props} style={{display: 'none'}} />
                          )}
                          // Hide the vertical scrollbar
                          renderTrackVertical={props => (
                            <div {...props} style={{display: 'none'}} />
                          )}>
                          <div className="project_list_view mt-3">
                            {projectListing && projectListing?.length > 0 ? (
                              projectListing.map((item, index) => {
                                const ProgressBarCount =
                                  calculateMilestoneProgress(item.milestones);
                                console.log(
                                  ProgressBarCount,
                                  'ProgressBarCount',
                                );
                                return (
                                  <div
                                    onClick={() => {
                                      setShowComponent('detail_Project');
                                      setSelectedProjectData(item);
                                    }}
                                    className="project_list_card as-link"
                                    index={item.id + index}>
                                    <div className="list_card_main">
                                      {/* <div className="project_img">
                                        <img src="" alt="" />
                                      </div> */}

                                      <div className="company_project_img">
                                        <img
                                          src={
                                            item?.company_detail?.company_logo
                                              ? item?.company_detail
                                                  ?.company_logo
                                              : 'https://s3.amazonaws.com/build-dev/no_image_2.jpg'
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="list_card_content">
                                        <div>
                                          <h2>{fixName(item.name)}</h2>
                                        </div>
                                        <p
                                          style={{
                                            fontSize: '14px',
                                            color: '#7E7B70',
                                          }}>
                                          {fixName(
                                            item?.company_detail?.company_name,
                                          )}
                                        </p>

                                        <div className="roi-service-icons">
                                          {Object.entries(item.tag_titles)
                                            .length > 0 &&
                                            item.tag_titles
                                              .slice(0, 3)
                                              .map((MiniItem, index) => (
                                                <div
                                                  className="service-icon-time"
                                                  key={index}>
                                                  {MiniItem && (
                                                    <span className="tags_title">
                                                      {truncateTitle(
                                                        MiniItem,
                                                        4,
                                                      )}
                                                    </span>
                                                  )}
                                                </div>
                                              ))}
                                          {item.tag_titles.length > 3 && (
                                            <div className="w-29">
                                              <i
                                                className="fa-solid fa-ellipsis"
                                                style={{
                                                  color: 'rgb(10, 10, 10)',
                                                  paddingTop: '15px',
                                                }}></i>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="list_card_bottom">
                                      <div className="d-flex justify-content-between">
                                        <span>Next Delivery</span>

                                        <span>
                                          {ProgressBarCount?.upcomingDate
                                            ? ProgressBarCount?.upcomingDate
                                            : ''}
                                        </span>
                                      </div>
                                      <div className="project_bar">
                                        <ProgressBar
                                          className="task-bar"
                                          variant="success"
                                          now={
                                            ProgressBarCount?.percentagePassed &&
                                            ProgressBarCount?.percentagePassed
                                              ? ProgressBarCount?.percentagePassed
                                              : 0
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <NorecordFound
                                noRecordData={NoProjectsFound}
                                buttonPermesion={true}
                                ButtonAction={() => {
                                  setShowComponent('createProject');
                                }}
                              />
                            )}
                          </div>
                        </Scrollbars>
                      </div>
                    </div>

                    {showComponent === 'filterModal' && (
                      <CompanyFilterModal
                        onClose={() => setShowComponent('')}
                        companies={companies}
                        isCheckAll={isCheckAll}
                        setIsCheckAll={setIsCheckAll}
                        isCheck={isCheck}
                        setIsCheck={setIsCheck}
                        list={companies}
                        setList={setList}
                        handleFilter={() => {
                          setCompanyIds(isCheck);
                        }}
                      />
                    )}
                  </>
                }
                secondary={
                  <>
                    {showComponent === 'createProject' && (
                      <CreateProject
                        handleProjectAddEdit={(item, type) => {
                          handleProjects(item, type);
                        }}
                        onClose={() => {
                          setShowComponent('');
                          setNewTaskData({})
                        }}
                        data={false}
                        onCloseApiHit={() => {
                          setShowComponent('');
                          setNewTaskData({})
                          // setShowComponent('detail_Project');
                        }}
                        type="create_project"
                        handleTask={handleTask}
                        newTaskData={newTaskData}
                      />
                    )}

                    {showComponent === 'detail_Project' && (
                      //   <DetailProject
                      //     onClose={() => {
                      //       setShowComponent('');
                      //     }}
                      //     selectedProjectData={selectedProjectData}
                      //     selectedDropdown={selectedDropdown}
                      //     setOpenEdit={setOpenEdit}
                      //     setSelectedProjectData={setSelectedProjectData}
                      //   />
                      // )}

                      <CreateProject
                        handleProjectAddEdit={(item, type) => {
                          handleProjects(item, type);
                        }}
                        onClose={() => {
                          setShowComponent('');
                          setNewTaskData({})
                        }}
                        data={false}
                        onCloseApiHit={() => {
                          setShowComponent('');
                          setNewTaskData({})
                          // setShowComponent('detail_Project');
                        }}
                        projectListing={selectedProjectData}
                        type="editProject"
                        handleTask={handleTask}
                        newTaskData={newTaskData}
                      />
                    )}
                    {secondaryComponent === 'create_Task' && (
                      <CreateAction
                        onClose={() => {
                          setSecondaryComponent('');
                        }}
                        // type={true}
                       
                        setNewTaskData={setNewTaskData}
                      />
                    )}
                  </>
                }
              />
            )}

            {tabType === 'board' &&
              currentComponent !== 'createProjectBoard' &&
              showComponent !== 'filterModalBord' &&
              showComponent !== 'dateFilter' && (
                <div style={{display: 'flex', flex: 1}}>
                  <div
                    className="projects-graph-page primary_panel_content"
                    style={{padding: '25px 20px 15px'}}>
                    <div
                      className="messages-header "
                      style={{maxWidth: '369px'}}>
                      <div className="event-tabs tabs-style-2 mb-0">
                        {' '}
                        <ul>
                          <li>
                            <Button
                              className={tabType === 'list' ? 'active' : ''}
                              onClick={() => {
                                setTabType('list');
                              }}>
                              List
                            </Button>
                          </li>
                          <li>
                            <Button
                              className={tabType === 'board' ? 'active' : ''}
                              onClick={() => {
                                setTabType('board');
                              }}>
                              Board
                            </Button>
                          </li>
                        </ul>
                      </div>

                      <div className="search-tabs-icons">
                        {ProjectPermessions &&
                          (ProjectPermessions === 'allow' ||
                            ProjectPermessions?.create === true) && (
                            <span
                              className={
                                currentComponent === 'createProjectBoard'
                                  ? 'icon active'
                                  : 'icon'
                              }>
                              <i
                                className="icon-plus"
                                onClick={() =>
                                  setCurrentComponent('createProjectBoard')
                                }
                              />
                            </span>
                          )}
                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={
                            threeDots
                              ? 'icon active threeDots'
                              : 'icon threeDots'
                          }>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>

                    {threeDots && (
                      <div
                        className="mt-4"
                        style={{display: 'flex', width: '50%'}}>
                        <div className="dropdown-search">
                          <div className="roi-template-dropdown">
                            <div className="select-wrapper">
                              <Dropdown
                                onSelect={(eventKey, event) => {
                                  setType(eventKey);
                                }}
                                drop="down">
                                <Dropdown.Toggle variant="primary">
                                  {type && projectListingBoard?.length > 0
                                    ? fixName(
                                        projectListingBoard?.find(
                                          item => item.id === type,
                                        ).name,
                                      )
                                    : NoRecord?.find(item => item.id === type)
                                        .name}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {projectListingBoard &&
                                  projectListingBoard?.length > 0
                                    ? projectListingBoard?.map(
                                        (item, index) => (
                                          <Dropdown.Item
                                            key={`st-${index}`}
                                            eventKey={item.id.toString()}
                                            active={item.id === type}>
                                            {fixName(item.name)}
                                          </Dropdown.Item>
                                        ),
                                      )
                                    : NoRecord.map((item, index) => (
                                        <Dropdown.Item
                                          key={`st-${index}`}
                                          eventKey={item.id.toString()}
                                          active={item.id === type}>
                                          {item.name}
                                        </Dropdown.Item>
                                      ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>

                          <div className="roi-template-dropdown">
                            <NewMultiDropdown
                              setDropdownIds={setSelectMileStoneId}
                              dropdownIds={selectMileStoneId}
                              DropDownValues={
                                projectListingBoardData?.milestones
                              }
                              itemName={['title']}
                              ErrorMessage={
                                'Atleast one milestone is required.'
                              }
                              DropdownType={'all'}
                            />
                          </div>
                        </div>

                        {/* <div style={{flex: 2}}>
                        <div className="input-group">
                          <Form.Control
                            type="text"
                            pattern="^\S.*$"
                            required
                            placeholder="Search"
                            value={searchQry}
                            onChange={e => {
                              setSearchQry(e.target.value);
                            }}
                          />
                        </div>
                      </div> */}

                        <div className="person-search-wrapper m-0 messages-users-wrapper project-persons-search-wrapper">
                          <div className="search-form d-flex">
                            <div className="search-tabs-icons">
                              <span
                                onClick={() => {
                                  setShowComponent('dateFilter');
                                }}
                                className={
                                  showComponent === 'dateFilter'
                                    ? 'icon active'
                                    : 'icon mt-0'
                                }>
                                <i className="fa-solid fa-calendar-days"></i>
                              </span>
                            </div>
                            <Button>
                              <i
                                className="icon-filter-icon"
                                onClick={() =>
                                  setShowComponent('filterModalBord')
                                }
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    {projectListing && projectListing.length > 0 ? (
                      <DateGraph
                        projectListingBoardData={projectListingBoardData}
                        selectMileStoneId={selectMileStoneId}
                        startFrom={filterFrom}
                        startEnd={filterTo}
                        type={type}
                        showDetail={showBoardList}
                        setShowDetail={setShowBoardList}
                        showTagsModal={showTagsModal}
                        setShowTagsModal={setShowTagsModal}
                        setActions={setActions}
                      />
                    ) : (
                      <div
                        className="no-records-middle-screen-holder"
                        style={{marginTop: '9%'}}>
                        <NorecordFound
                          noRecordData={{
                            img: project_management,
                            title: null,
                            description: 'No record found.',
                            button: null,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            {currentComponent === 'createProjectBoard' && (
              <CreateProject
                handleProjectAddEdit={(item, type) => {
                  handleProjects(item, type);
                }}
                onClose={() => {
                  setCurrentComponent('');
                }}
                onCloseApiHit={() => {
                  setCurrentComponent('');
                  setShowComponent('detail_Project');
                }}
                type="create_project"
              />
            )}

            {showComponent === 'filterModalBord' && (
              <CompanyFilterModal
                onClose={() => setShowComponent('')}
                companies={companies}
                isCheckAll={isCheckAll}
                setIsCheckAll={setIsCheckAll}
                isCheck={isCheck}
                setIsCheck={setIsCheck}
                list={companies}
                setList={setList}
                handleFilter={() => {
                  setCompanyIds(isCheck);
                }}
              />
            )}

            {showBoardList === 'open_project_detail' && (
              <CreateProject
                handleProjectAddEdit={(item, type) => {
                  handleProjects(item, type);
                }}
                onClose={() => {
                  setShowBoardList('');
                }}
                data={false}
                onCloseApiHit={() => {
                  // setShowComponent('');
                  setShowBoardList('');
                  // setShowComponent('detail_Project');
                }}
                projectListing={projectListingBoardData}
                type="editProject"
              />

              // <EditProject
              //   handleProjectAddEdit={(item, type) => {
              //     handleProjects(item, type);
              //   }}
              //   onClose={() => {
              //     setShowBoardList(false);
              //     // setSelectedProjectData(null);
              //     setProjectType('');
              //   }}
              //   projectType={projectType}
              //   selectedProjectData={projectListingBoardData}
              // />
            )}

            {showBoardList === 'open_task_detail' && (
              <ActionDetail
                dataOfAction={actions}
                setDataOfAction={setActions}
                actions={tasksData.length > 0 ? tasksData : []}
                onClose={() => {
                  setShowBoardList('');
                }}
                onCloseHitApi={() => {
                  //  getActions(1);
                }}
              />
            )}

            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
      {showComponent === 'dateFilter' && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowComponent('');
          }}>
          <div
            style={{overflowY: fromCalendar || toCalendar ? 'unset' : 'auto'}}
            className="filter-wrapper">
            <div className="headings m-0">
              <h2 className="m-0 text-center">Select Date</h2>
            </div>
            <div className="filter-category-list">
              <ul style={{listStyleType: 'none', padding: 0}}>
                <li style={{marginBottom: '5px'}}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div style={{paddingRight: '15px'}}>
                      <Form.Label>From</Form.Label>
                    </div>
                    <div className="parent-container w-100">
                      <Calender
                        setSelectedDate={selectedDate => {
                          if (selectedDate) {
                            setFilterFrom(selectedDate);
                          } else {
                            setFilterFrom('');
                          }
                        }}
                        selectedDate={
                          filterFrom &&
                          moment(filterFrom, 'YYYY-MM-DD', true).isValid()
                            ? new Date(filterFrom)
                            : ''
                        }
                        showDate={true}
                        showTime={false}
                        typeOfModelShow="something"
                        setFromCalendar={setFromCalendar}
                        fromCalendar={fromCalendar}
                        setToCalendar={setToCalendar}
                        showCalendar={'fromCalendar'}
                        typeOfClearButtonsShow={true}
                      />
                      {/* <Form.Control
                              type="date"
                              value={filterFrom}
                              onChange={e => setFilterFrom(e.target.value)}
                              placeholder="Select start date"
                            /> */}
                    </div>
                  </div>
                </li>
                <li style={{marginBottom: '5px'}}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div style={{paddingRight: '31px'}}>
                      <Form.Label>To</Form.Label>
                    </div>
                    <div className="parent-container w-100">
                      {/* Date input for "To" */}
                      <Calender
                        showDate={true}
                        showTime={false}
                        setSelectedDate={selectedDate => {
                          if (selectedDate) {
                            setFilterTo(selectedDate);
                          } else {
                            setFilterTo('');
                          }
                        }}
                        selectedDate={
                          filterTo &&
                          moment(filterTo, 'YYYY-MM-DD', true).isValid()
                            ? new Date(filterTo)
                            : ''
                        }
                        typeOfModelShow="something"
                        setToCalendar={setToCalendar}
                        toCalendar={toCalendar}
                        setFromCalendar={setFromCalendar}
                        showCalendar={'toCalendar'}
                        typeOfClearButtonsShow={true}
                      />
                      {/* <Form.Control
                              type="date"
                              value={filterTo}
                              onChange={e => setFilterTo(e.target.value)}
                              placeholder="Select end date"
                            /> */}
                    </div>
                  </div>
                </li>
              </ul>

              <div className="filter-btn-wrapper text-center">
                <Button
                  className="btn-dark"
                  onClick={() => {
                    handleCheckDate();
                  }}>
                  Filter date
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      )}
      {showTagsModal && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowTagsModal('');
          }}>
          <div className="filter-wrapper">
            <div className="headings m-0">
              <h2 className="m-0 text-center">Tags</h2>
            </div>
            <div>
              <div className="filter-category-list">
                {projectListingBoardData &&
                  projectListingBoardData.tag_titles?.length > 0 &&
                  projectListingBoardData.tag_titles.map((item, index) => {
                    return (
                      <ul style={{marginBottom: '0px', paddingBottom: '0px'}}>
                        <li>{item}</li>
                      </ul>
                    );
                  })}
              </div>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default Projects;
